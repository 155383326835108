import * as React from "react";
import {Link} from "react-router-dom";
import {observer} from "mobx-react";
import {LoginHelper} from "util/LoginHelper";
import {str} from "util/TextHelper";
import {VerticalBox} from "ui/VerticalBox";
import {HorizontalBox} from "ui/HorizontalBox";

@observer
export class MainToolbarLoginSection extends React.Component {
	render() {
		const loginHelper = LoginHelper.singleton;

		return (<div className="Main-Toolbar-Login-Section">
			{loginHelper.loggedIn != null && (loginHelper.loggedIn
				? this.renderLoggedIn()
				: this.renderLoggedOut())}
		</div>);
	}

	renderLoggedOut(): React.ReactNode
	{
		return <div className="Main-Toolbar-Logged-Out-Section">
			<button className="Main-Toolbar-Login-Button" type="button">
			<Link to="/login">{str("LOGIN_BUTTON")}</Link>
			</button>
		</div>;
	}

	renderLoggedIn(): React.ReactNode
	{
		return (
			<VerticalBox className="Main-Toolbar-Logged-In-Section" spaced>
				<HorizontalBox className="Main-Toolbar-Login-Welcome" spaced>
					<div>
						{str("WELCOME_USER_MESSAGE_START")}
						<Link to="/user">
							{LoginHelper.singleton.displayName}
						</Link>
						{str("WELCOME_USER_MESSAGE_END")}
					</div>
				</HorizontalBox>
				<button className="Main-Toolbar-Logout-Button" type="button" onClick={() => LoginHelper.singleton.logOut()}>
					{str("LOG_OUT_BUTTON")}
				</button>
			</VerticalBox>
		);
	}
}