import * as React from "react";
import {IReactionDisposer, ObservableMap, reaction, when} from "mobx";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {APICaller} from "util/APICaller";
import {APIModel} from "util/APIModel";
import {TextHelper, str} from "util/TextHelper";
import {StateMachine, PlayerStatsSortF, TeamStatsSortF} from "util/StateMachine";
import {PlayerStatsWrapper, TeamStatsWrapper} from "util/StatsRowWrapper";
import {SeasonHelper} from "util/SeasonHelper";
import {SeasonToggle} from "ui/SeasonToggle";
import {VerticalBox} from "ui/VerticalBox";
import {HorizontalBox} from "ui/HorizontalBox";
import {EmptyPane} from "ui/EmptyPane";
import {ToggleButtons} from "ui/ToggleButtons";
import {TableHeader} from "ui/TableHeader";
import {LoadingPane} from "ui/LoadingPane";

@observer
export class StatsPane extends React.Component {
	disposers: IReactionDisposer[] = [];
	componentDidMount()
	{
		this.disposers.push(reaction(() => [SeasonHelper.currentSeason > 0, SeasonHelper.currentWeek > 0],
			async () => {
				await when(() => SeasonHelper.initialized);
				APIModel.singleton.refreshTeams();
				APIModel.singleton.refreshPlayers();
				APIModel.singleton.refreshTeamStatSummaries();
				APIModel.singleton.refreshPlayerStatSummaries();
		}, {fireImmediately: true}));
	}

	componentWillUnmount()
	{
		this.disposers.map((disposer: IReactionDisposer) => disposer());
	}

	get teamColumnHeaders(): string[]
	{
		return ["N", "Division", "Team", "G", "W", "L", "T", "15", "10", "-5", "PPG", "PPB"];
	}
	get playerColumnHeaders(): string[]
	{
		return ["N", "Division", "Player", "Team", "G", "15", "10", "-5", "PPG"];
	}

	get filterText(): string
	{
		return StateMachine.singleton.statsFilterText;
	}
	get teamIndividualToggle(): number
	{
		return StateMachine.singleton.statsTeamIndividualToggle;
	}
	get seasonToggle(): number
	{
		return StateMachine.singleton.statsCurrentAllSeasonsToggle;
	}
	get isLegendCollapsed(): boolean
	{
		return StateMachine.singleton.statsLegendCollapsed;
	}

	render() {
		return (
			<VerticalBox className="container-fluid Stats-Pane" spaced>
				<h2 className="">{str("STATS_TITLE")}</h2>
				<div className="Stats-Pane-Header row">
					<div className="Stats-Pane-Filter-Box col-lg-3 col-sm-5 col-xs-6">
						<label className="Stats-Pane-Filter-Box-Label " htmlFor="statsFilter">
							{str("FILTER_BY_TEXT_LABEL")}
						</label>
						<input className="col-sm-6" type="text" id="statsFilter" defaultValue={this.filterText} onChange={this.onChangeFilter}/>
					</div>
					<ToggleButtons className="col-lg-3 col-md-3 col-sm-4"
						labels={[
							str("TEAM_TOGGLE_BUTTON"),
							str("INDIVIDUAL_TOGGLE_BUTTON")
						]}
						selectedIndex={this.teamIndividualToggle}
						onToggleIndex={this.onToggleTeamIndividual}/>
					<SeasonToggle
						className="col-lg-3 col-md-3 col-sm-4"
						selectedIndex={this.seasonToggle}
						onToggleIndex={this.onToggleCurrentAllSeasons}/>
					<HorizontalBox spaced className="col-lg-5 col-md-6 col-sm-7 col-xs-10">
						<div>{str("DIVISION_COLON")}</div>
						<ToggleButtons
							labels={this.seasonToggle === 1
								? [str("ALL_LABEL"), 1, "2a", "2b", "3a", "3b"]
								: [str("ALL_LABEL"),
								...[...new Array(SeasonHelper.seasonDivisions[this.seasonToggle])].map((_, i) => `${i+1}`)]}
							selectedIndex={StateMachine.singleton.statsDivisionToggle}
							onToggleIndex={StateMachine.singleton.createToggleStatsToggleCallback}/>
					</HorizontalBox>
				</div>
				{this.renderLegend()}
				<div className="Fit-Content">
					<Table
						teamHeaders={this.teamColumnHeaders}
						playerHeaders={this.playerColumnHeaders}
						season={this.seasonToggle+1}/>
				</div>
			</VerticalBox>
		);
	}

	renderLegend(): React.ReactNode
	{
		//only two options: 0 and 1
		const rows = this.teamIndividualToggle === 0 ? this.teamColumnHeaders : this.playerColumnHeaders;

		const renderLegendRow = (header: string, index: number) =>
			<li title={TextHelper.headerTooltips.get(header)} className="Stats-Pane-Header-Legend-Item" key={index}>
				<strong>{`${header}`}</strong>{`: ${TextHelper.legendText.get(header)}`}
			</li>;

		return <VerticalBox className="Stats-Pane-Header-Legend">
			<HorizontalBox spaced>
				<div className="Stats-Pane-Header-Legend-Title">{str("LEGEND_TITLE")}</div>
				<div className="Stats-Pane-Header-Button" onClick={this.onToggleLegendCollapsed}>
					{`(${this.isLegendCollapsed
						? str("EXPAND_TOGGLE_BUTTON")
						: str("COLLAPSE_TOGGLE_BUTTON")})`
					}
				</div>
			</HorizontalBox>
			<ul className="Stats-Pane-Header-Legend-Box">
				{this.isLegendCollapsed
						? null
						: rows
							.filter((row) => TextHelper.legendText.get(row))
							.map(renderLegendRow)
				}
			</ul>
		</VerticalBox>
	}

    readonly onChangeFilter = StateMachine.singleton.onChangeStatsFilterText;

    readonly onToggleTeamIndividual = StateMachine.singleton.createToggleStatsTeamIndividualCallback;
    readonly onToggleCurrentAllSeasons = StateMachine.singleton.createToggleCurrentAllSeasonsCallback;
    readonly onToggleLegendCollapsed = StateMachine.singleton.onToggleStatsLegendCollapsed;
}

interface TableProps {
	teamHeaders: string[];
	playerHeaders: string[];
	season: number;
}

@observer
class Table extends React.Component<TableProps> {
	get isTeam(): boolean
	{
		return StateMachine.singleton.statsTeamIndividualToggle === 0;
	}
	get filterText(): string
	{
		return StateMachine.singleton.statsFilterText;
	}
	get teamRows(): TeamStatsWrapper[]
	{
		return StateMachine.singleton.statsTeamSummaries(this.props.season)
			.filter((row) => {
				const seasonIndex = row.team?.seasons?.indexOf(this.props.season);
				return seasonIndex != undefined && seasonIndex >= 0;
			});
	}
	get playerRows(): PlayerStatsWrapper[]
	{
		return StateMachine.singleton.statsPlayerSummaries(this.props.season);
	}

	get teamColumnModes(): ObservableMap<number, number>
	{
		return StateMachine.singleton.statsTeamColumnModes;
	}
	get playerColumnModes(): ObservableMap<number, number>
	{
		return StateMachine.singleton.statsPlayerColumnModes;
	}

	render() {
		return (this.isTeam ? this.teamRows.length : this.playerRows.length)
			?
			<table className="Stats-Pane-Row-Container table">
				{this.isTeam
					? <TableHeader
						headers={this.props.teamHeaders}
						columnModes={this.teamColumnModes}
						sortFuncs={StateMachine.teamStatsColumnSortFuncs}
						setSortFunc={this.setSortF}
						season={this.props.season}/>
					: <TableHeader
						headers={this.props.playerHeaders}
						columnModes={this.playerColumnModes}
						sortFuncs={StateMachine.playerStatsColumnSortFuncs}
						setSortFunc={this.setSortF}
						season={this.props.season}/>
				}
				<tbody>
					{this.isTeam
						? this.teamRows.map(this.renderTeamStatsRow)
						: this.playerRows.map(this.renderPlayerStatsRow)}
				</tbody>
			</table>
			: <EmptyPane/>;
	}

	readonly renderTeamStatsRow = (row: TeamStatsWrapper, index: number) => {
		const {teamHeaders}: TableProps = this.props;

		//render interior of cells manually to allow custom rendering
		const interior: React.ReactNode[] = [
			index+1,
			row.division(this.props.season),
			row.team_id
				?
				<Link to={`/teams/id=${row.team_id}`}>
					{row.nameFilter(this.filterText)}
				</Link>
				: row.nameFilter(this.filterText),
			row.gamesFilter(this.filterText),
			row.winsFilter(this.filterText),
			row.lossesFilter(this.filterText),
			row.tiesFilter(this.filterText),
			row.powersFilter(this.filterText),
			row.tensFilter(this.filterText),
			row.negsFilter(this.filterText),
			row.ppgFilter(this.filterText),
			row.ppbFilter(this.filterText)
		];
		return <tr key={index} className="Stats-Pane-Row">
			{teamHeaders.map((header, i) => 
				<td className={`Table-Cell Table-Cell-${header}-Column Table-Cell-Row-${index.toString()}`} key={i}>
					{interior[i]}
				</td>
			)}
		</tr>;
	}
	readonly renderPlayerStatsRow = (row: PlayerStatsWrapper, index: number) => {
		const {playerHeaders}: TableProps = this.props;

		//render interior of cells manually to allow custom rendering
		const interior: React.ReactNode[] = [
			index+1,
			row.division(this.props.season),
			row.player_id
				? <Link to={`/players/id=${row.player_id}`}>
					{row.playernameFilter(this.filterText)}
				</Link>
				: row.playernameFilter(this.filterText),
			row.team_id
				? <Link to={`/teams/id=${row.team_id}`}>
					{row.teamnameFilter(this.filterText)}
				</Link>
				: row.teamnameFilter(this.filterText),
			row.gamesFilter(this.filterText),
			row.powersFilter(this.filterText),
			row.tensFilter(this.filterText),
			row.negsFilter(this.filterText),
			row.ppgFilter(this.filterText)
		];
		return <tr key={index} className="Stats-Pane-Row">
			{playerHeaders.map((header, i) => 
				<td className={`Table-Cell Table-Cell-${header}-Column Table-Cell-Row-${index.toString()}`} key={i}>
					{interior[i]}
				</td>
			)}
		</tr>;
	}

	setSortF: (sortF: TeamStatsSortF | PlayerStatsSortF) => void = StateMachine.singleton.setStatsSortFunc;
}