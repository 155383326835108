import React from "react";

export class RegexHelper {
	static escape(str: string): string
	{
		return str
			.replaceAll("\\", "\\\\")
			.replaceAll(".", "\\.")
			.replaceAll("$", "\\$")
			.replaceAll("^", "\\^")
			.replaceAll("[", "\\[")
			.replaceAll("]", "\\]")
			.replaceAll("|", "\\|")
			.replaceAll("?", "\\?")
			.replaceAll("*", "\\*")
			.replaceAll("+", "\\+")
			.replaceAll("(", "\\(")
			.replaceAll(")", "\\)");
	}

	static niceRegExp(str: string): RegExp
	{
		return new RegExp(RegexHelper.escape(str.trim()), "i");
	}

	static markText(raw: string | number, filterText: string): string
	{
		const rawString = raw.toString();
		if (!filterText.length) return rawString;
		const regExp = RegexHelper.niceRegExp(filterText);
		return rawString.replace(regExp, "<mark className='Filtered-Text'>$&</mark>");
	}
	
	static markFiltered(raw: string | number, filterText: string): React.ReactNode
	{
		//as its name suggests this is dangerous - theoretically someone could inject js code or something
		return <div dangerouslySetInnerHTML={{__html: RegexHelper.markText(raw, filterText)}}/>;
	}
}