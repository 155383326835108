import * as React from "react";
import {observer} from "mobx-react";
import Collapsible from "react-collapsible";
import {str} from "util/TextHelper";
import {VerticalBox} from "ui/VerticalBox";

@observer
export class FAQPane extends React.Component {
	render() {
        //helper function that wraps rows
        //the use of dangerouslySetInnerHTML means that HTML tags can be embedded in the content
        const renderQuestion = (index: number) =>
            <Collapsible className="FAQ-Question" trigger={str(`FAQ_QUESTION_${index+1}`)} key={index}>
                <div className="FAQ-Answer" dangerouslySetInnerHTML={{__html: str(`FAQ_ANSWER_${index+1}`)}}/>
            </Collapsible>;

        //mind your p's and q's - each section has to have the right offset (equal to the number of preceding rows)
		return (
            <VerticalBox className="container-fluid Text-Wrap">
                <h2>{str("FAQ_TITLE")}</h2>
                <p dangerouslySetInnerHTML={{__html: str("FAQ_INTRO")}}/>
                <VerticalBox className="FAQ-Section">
                    <h5 className="Left-Pad">Intro</h5>
                    {Array.from(new Array(8)).map((_, i) => renderQuestion(i))}
                </VerticalBox>
                <VerticalBox className="FAQ-Section">
                    <h5 className="Left-Pad">Rules</h5>
                    <p dangerouslySetInnerHTML={{__html: str("FAQ_RULES")}}/>
                    {Array.from(new Array(5)).map((_, i) => renderQuestion(i+8))}
                </VerticalBox>
                <VerticalBox className="FAQ-Section">
                    <h5 className="Left-Pad">Distribution</h5>
                    {Array.from(new Array(1)).map((_, i) => renderQuestion(i+13))}
                </VerticalBox>
            </VerticalBox>
        );
	}

    
}