import {ObservableMap, runInAction} from "mobx";
import {APIModel} from "util/APIModel";
import {UserModel} from "util/UserModel";
import {SeasonHelper} from "util/SeasonHelper";
import {TeamStatsInfo, PlayerStatsInfo} from "dto/StatsInfo";
import {TeamInfo} from "dto/TeamInfo";
import {PlayerInfo} from "dto/PlayerInfo";
import {GameInfo, ScheduleRow} from "dto/ScheduleRow";
import {AccountInfo} from "dto/AccountInfo";
import {QBJ} from "dto/QBJ";
import {TossupConversionInfo, BonusConversionInfo} from "dto/ConversionInfo";

export class APICaller {
	private static readonly _baseUrl: string = "https://flask-api-ls2r2jt34a-uw.a.run.app";
	private static firebase_access_key: string = "9f1b002e-3ab1-4342-a180-d336a2709e33";

	static getTeams(): Promise<TeamInfo[]>
	{
		return fetch(APICaller._baseUrl + "/teams/", {method: "GET"})
			.then(res => res.json())
			.catch((e) => console.log("Error in fetchTeams:", e)) as Promise<TeamInfo[]>;
	}

	static getTeamsBySeason(season: number): Promise<TeamInfo[]>
	{
		return Promise.resolve([]);
	}

	static getTeam(id: number): Promise<TeamInfo>
	{
		return fetch(APICaller._baseUrl + `/team/${id}`, {method: "GET"})
			.then(res => res.json())
			.then((json) => {
				//could explicitly transfer fields over, to avoid potentially snafus
				const team: TeamInfo = json as TeamInfo;

				runInAction(() => {
					APIModel.singleton.teamIdsToNames.set(id, team.name);
					APIModel.singleton.teamsById.set(id, team);
				});
				return team;
			}).catch((e) => console.log("Error in getTeam:", e)) as Promise<TeamInfo>;
	}

	static updateTeam(id: number, team: TeamInfo): Promise<boolean>
	{
		return fetch(APICaller._baseUrl + `/team/${id}?key=` + APICaller.firebase_access_key, {
			method: "PUT",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify(team)
		}).then(res => res.ok)
		.catch((e) => console.log("Error in updateTeam:", e)) as Promise<boolean>;
	}

	static getPlayers(): Promise<PlayerInfo[]>
	{
		return fetch(APICaller._baseUrl + "/players/", {method: "GET"})
			.then(res => res.json())
			.catch((e) => console.log("Error in fetchPlayers:", e)) as Promise<PlayerInfo[]>;
	}

	static getPlayer(id: number): Promise<PlayerInfo>
	{
		return fetch(APICaller._baseUrl + `/player/${id}`, {method: "GET"})
			.then(res => res.json())
			.then(json => {
				//could explicitly transfer fields over, to avoid potentially snafus
				const player: PlayerInfo = json as PlayerInfo;

				runInAction(() => {
					APIModel.singleton.playersById.set(id, player);
				});
				return player;
			})
			.catch((e) => console.log("Error in getPlayer:", e)) as Promise<PlayerInfo>;
	}

	static updatePlayer(id: number, player: PlayerInfo): Promise<boolean>
	{
		return fetch(APICaller._baseUrl + `/player/${id}?key=` + APICaller.firebase_access_key, {
			method: "PUT",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify(player)
		}).then(res => res.ok)
		.catch((e) => console.log("Error in updatePlayer:", e)) as Promise<boolean>;
	}

	static createPlayer(player: PlayerInfo): Promise<PlayerInfo>
	{
		return fetch(APICaller._baseUrl + "/players?key=" + APICaller.firebase_access_key, {
			method: "POST",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify(player)
		}).then(res => res.json())
		.catch((e) => console.log("Error in createPlayer:", e)) as Promise<PlayerInfo>;
	}

	static getTeamStatSummaries(season: number = SeasonHelper.currentSeason ?? 0): Promise<TeamStatsInfo[]>
	{
		if (season)
		{
			return fetch(APICaller._baseUrl + `/stats/all_teams/${season}`, {method: "GET"})
				.then(res => res.json())
				.catch((e) => console.log("Error in getTeamStatSummaries:", e)) as Promise<TeamStatsInfo[]>;
		}
		return Promise.resolve([]);
	}

	static getTeamStats(team: number): Promise<TeamStatsInfo[]>
	{
		return fetch(APICaller._baseUrl + `/stats/teams/${team}`, {method: "GET"})
			.then(res => res.json())
			.catch((e) => console.log("Error in getTeamStats:", e)) as Promise<TeamStatsInfo[]>;
	}

	static getTeamPlayerStats(team: number, season: number): Promise<PlayerStatsInfo[]>
	{
		return fetch(APICaller._baseUrl + `/stats/teams/${season}/${team}/players`, {method: "GET"})
			.then(res => res.json() as Promise<PlayerStatsInfo[]>)
			.catch((e) => console.log("Error in getTeamPlayerStats:", e)) as Promise<PlayerStatsInfo[]>;
	}

	static getPlayerStatSummaries(season: number = SeasonHelper.currentSeason ?? 0): Promise<PlayerStatsInfo[]>
	{
		if (season)
		{
			return fetch(APICaller._baseUrl + `/stats/all_players/${season}`, {method: "GET"})
				.then(res => res.json())
				.catch((e) => console.log("Error in getPlayerStatSummaries:", e)) as Promise<PlayerStatsInfo[]>;
		}
		return Promise.resolve([]);
	}

	static getPlayerStats(player: number): Promise<PlayerStatsInfo[]>
	{
		return fetch(APICaller._baseUrl + `/stats/players/${player}`, {method: "GET"})
			.then(res => res.json())
			.catch((e) => console.log("Error in getPlayerStats:", e)) as Promise<PlayerStatsInfo[]>;
	}

	static getFullSchedule(season: number = SeasonHelper.currentSeason ?? 0): Promise<Map<number, ScheduleRow[]>>
	{
		//${season ? season : SeasonHelper.currentSeason}
		return fetch(APICaller._baseUrl + `/schedule/`, {
			method: "GET"
		}).then(res => res.json())
		.then(json => new Map<number, ScheduleRow[]>(Object.entries(json).map(([key, value]) =>
			[Number.parseInt(key), value] as [number, ScheduleRow[]])))
		.catch((e) => console.log("Error in getFullSchedule:", e)) as Promise<Map<number, ScheduleRow[]>>;
	}

	static getSchedule(team_id: number, season?: number): Promise<ScheduleRow[]>
	{
		//temporarily avoid problems with debug team
		//@ts-ignore
		if (team_id === "0")
			return Promise.resolve([]);

		//${season ? season : SeasonHelper.currentSeason}
		return fetch(APICaller._baseUrl + `/schedule/${team_id}`, {
			method: "GET"
		}).then(res => res.json())
		.catch((e) => console.log("Error in getSchedule:", e)) as Promise<ScheduleRow[]>;
	}

	static getGames(): Promise<{[game_id: string]: GameInfo}>
	{
		return fetch(APICaller._baseUrl + `/games/`, {
			method: "GET"
		}).then(res => res.json())
		.catch((e) => console.log("Error in getGames:", e)) as Promise<{[game_id: string]: GameInfo}>;
	}

	static registerTeam(props: TeamInfo): Promise<boolean>
	{
		return fetch(APICaller._baseUrl + "/teams?key=" + APICaller.firebase_access_key, {
			method: "POST",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify(props)
		}).then(res => res.ok)
		.catch((e) => console.log("Error in registerTeam:", e)) as Promise<boolean>;
	}

	static reupTeam(team: number, season: number): Promise<boolean>
	{
		return fetch(APICaller._baseUrl + `/teams/reup/${team}/${season}?key=` + APICaller.firebase_access_key, {
			method: "POST"
		}).then(res => res.ok)
		.catch((e) => console.log("Error in getTeamsBySeason:", e)) as Promise<boolean>;
	}

	static makeClaim(props: AccountInfo): Promise<boolean>
	{
		return fetch(APICaller._baseUrl + "/user/request?key=" + APICaller.firebase_access_key, {
			method: "POST",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify(props)
		}).then(res => res.ok)
		.catch((e) => console.log("Error in makeClaim:", e)) as Promise<boolean>;
	}

	static validatePacketPassword(season: number, week: number, password: string): Promise<boolean>
	{
		return fetch(APICaller._baseUrl + `/packet/${season}/${week}?key=` + APICaller.firebase_access_key, {
			method: "POST",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify({"password": password})
		}).then(async (res) => (await res.json() as {"result": boolean}).result)
		.catch((e) => console.log("Error in validatePacketPassword:", e)) as Promise<boolean>;
	}

	static submitStats(qbj: QBJ): Promise<boolean>
	{
		return fetch(APICaller._baseUrl + "/results?key=" + APICaller.firebase_access_key, {
			method: "POST",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify(qbj)
		}).then((res) => res.ok)
		.catch((e) => console.log("Error in submitStats:", e)) as Promise<boolean>;
	}

	static getTossupConversionStats(week: number, season: number = SeasonHelper.currentSeason ?? 0): Promise<TossupConversionInfo[]>
	{
		if (season)
		{
			return fetch(APICaller._baseUrl + `/questions/${season}/${week}/tossups/summary`, {method: "GET"})
				.then((res) => res.json())
				.catch((e) => console.log("Error in getTossupConversionStats:", e)) as Promise<TossupConversionInfo[]>;
		}
		return Promise.resolve([]);
	}

	static getBonusConversionStats(week: number, season: number = SeasonHelper.currentSeason ?? 0): Promise<BonusConversionInfo[]>
	{
		if (season)
		{
			return fetch(APICaller._baseUrl + `/questions/${season}/${week}/bonuses/summary`, {method: "GET"})
				.then((res) => res.json())
				.catch((e) => console.log("Error in getBonusConversionStats:", e)) as Promise<BonusConversionInfo[]>;
		}
		return Promise.resolve([]);
	}

	static getCurrentSeason(): Promise<number>
	{
		return fetch(APICaller._baseUrl + "/meta", {method: "GET"})
			.then((res) => res.json())
			.then((json: {season: number; week: number}) => json.season)
			.catch((e) => console.log("Error in getCurrentSeason:", e)) as Promise<number>;
	}

	static getCurrentWeek(): Promise<number>
	{
		return fetch(APICaller._baseUrl + "/meta", {method: "GET"})
			.then((res) => res.json())
			.then((json: {season: number; week: number}) => json.week)
			.catch((e) => console.log("Error in getCurrentWeek:", e)) as Promise<number>;
	}

	static getPlayerCareerStats(player: number): Promise<PlayerStatsInfo[]>
	{
		return fetch(APICaller._baseUrl + `/stats/players/career/${player}`, {method: "GET"})
			.then(res => res.json())
			.catch(e => console.log("Error in getPlayerCareerStats:", e)) as Promise<PlayerStatsInfo[]>;
	}
}