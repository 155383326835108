import * as React from "react";
import {str} from "util/TextHelper";
import {VerticalBox} from "ui/VerticalBox";

export class AnnouncementsPane extends React.Component {
    private static CURRENT_ANNOUNCEMENTS: string[] = ["6", "7", "8"];

    render() {
        return(
            <VerticalBox className='Announcements-Section col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                <h3 className='Announcements-Section-Title'>{str("ANNOUNCEMENTS_TITLE")}</h3>
                <div className="Announcements-Section-Content">
                    <h4 className='Announcements-Section-Content-Header'>{str("ANNOUNCEMENTS_DATE_HEADER")}</h4>
                    <ul className='Announcements-Section-Content-List'>
                        {AnnouncementsPane.CURRENT_ANNOUNCEMENTS.map(i =>
                            <li key={i} dangerouslySetInnerHTML={{__html: str(`ANNOUNCEMENTS_DATE_${i}`)}}/>)}
                    </ul>
                </div>
            </VerticalBox>
        )
    }
}