import {action, makeAutoObservable, when} from "mobx";
import {QBJ} from "dto/QBJ";
import {PlayerInfo} from "dto/PlayerInfo";
import {APIModel} from "util/APIModel";

export enum QBJStatus {
	NEW, LOADING, DONE
}

export class QBJHelper {
	qbj?: QBJ;
	constructor(file: File)
	{
		makeAutoObservable(this);
		file.text().then(action((json) => {
			this.qbj = JSON.parse(json as string);
		}));
	}

	get teamNames(): string[]
	{
		if (this.qbj?.match_teams)
		{
			return this.qbj.match_teams.map((teamInfo) => teamInfo.team.name);
		}
		return [];
	}

	get teamPlayerNames(): {[teamname: string]: string[]}
	{
		const playerNames: {[teamname: string]: string[]} = {};
		if (this.qbj)
		{
			this.qbj.match_teams.forEach((teamInfo) =>
				playerNames[teamInfo.team.name] = teamInfo.match_players.map((playerInfo) => playerInfo.player.name)
			);
		}
		return playerNames;
	}

	get teamScores(): {[teamname: string]: number}
	{
		const scores: {[teamname: string] : number} = {};
		if (this.qbj)
		{
			this.qbj.match_teams.forEach((teamInfo) => {
				const bonusPoints = teamInfo.bonus_points;
				const tossupPoints = teamInfo.match_players
					.flatMap((playerInfo) => playerInfo.answer_counts
						.map((question) => question.answer.value * question.number))
					.reduce((x, y) => x + y, 0);
				scores[teamInfo.team.name] = bonusPoints + tossupPoints;
			});
		}
		return scores;
	}

	get teamPlayerStats(): {[teamname: string]: {[playername: string]: {powers: number; tens: number; negs: number}}}
	{
		const stats: {[teamname: string]: {[playername: string]: {powers: number; tens: number; negs: number}}} = {};
		if (this.qbj)
		{
			this.qbj.match_teams.forEach((teamInfo) => {
				const teamname = teamInfo.team.name;
				const teamstats: {[playername: string]: {powers: number; tens: number; negs: number}} = {};
				teamInfo.match_players.map((playerInfo) => {
					const playerName = playerInfo.player.name;
					const stats = {powers: 0, tens: 0, negs: 0};
					playerInfo.answer_counts.forEach((answerCount) => {
						switch (answerCount.answer.value) {
							case 15:
								stats.powers = answerCount.number;
								break;
							case 10:
								stats.tens = answerCount.number;
								break;
							case -5:
								stats.negs = answerCount.number;
								break;
						}
					});
					teamstats[playerName] = stats;
				});
				stats[teamname] = teamstats;
			});
		}
		return stats;
	}

	get teamStats(): {[teamname: string]: {powers: number; tens: number; negs: number}}
	{
		const stats: {[teamname: string]: {powers: number; tens: number; negs: number}} = {};
		if (this.qbj)
		{
			Object.entries(this.teamPlayerStats).forEach(([teamName, teamStats]) => {
				const aggregatedStats = {powers: 0, tens: 0, negs: 0};
				Object.entries(teamStats).forEach(([_, playerStats]) => {
					aggregatedStats.powers += playerStats.powers;
					aggregatedStats.tens += playerStats.tens;
					aggregatedStats.negs += playerStats.negs;
				});
				stats[teamName] = aggregatedStats;
			});
		}
		return stats;
	}

	get teamBonuses(): {[teamname: string]: number}
	{
		const bonuses: {[teamname: string]: number} = {};
		if (this.qbj)
		{
			this.qbj.match_teams.forEach((teamInfo) =>
				bonuses[teamInfo.team.name] = teamInfo.bonus_points);
		}
		return bonuses;
	}

	get teamConversions(): {[teamname: string]: number}
	{
		const conversions: {[teamname: string]: number} = {};
		if (this.qbj)
		{
			Object.entries(this.teamStats).forEach(([teamName, teamStats]) =>
				conversions[teamName] = teamStats.powers + teamStats.tens);
		}
		return conversions;
	}

	get teamPPB(): {[teamname: string]: number}
	{
		const ppb: {[teamname: string]: number} = {};
		if (this.qbj)
		{
			this.teamNames.forEach((name) =>
				ppb[name] = this.teamBonuses[name] !== 0 && this.teamConversions[name] !== 0
					? this.teamBonuses[name]/this.teamConversions[name]
					: 0
			);
		}
		return ppb;
	}

	formatForSaving(selectedTeams: number[], selectedPlayers: number[][]): QBJ | undefined
	{
		if (this.qbj)
		{
			//@ts-ignore
			const teams: TeamInfo[] = selectedTeams.map((teamId) => APIModel.singleton.teamsById.get(teamId.toString()));
			//@ts-ignore
			const players: PlayerInfo[][] = selectedPlayers.map((players) => players.map((playerId) => APIModel.singleton.playersById.get(playerId.toString())));

			const name = (player: PlayerInfo) => player.fname + " " + player.lname;

			const teamnameMap: Map<string, string> = new Map();
			const playernameMap: Map<string, string> = new Map();
			this.teamNames.forEach((teamName, i) => {
				teamnameMap.set(teamName, teams[i].name);
				this.teamPlayerNames[teamName].forEach((playerName, ii) => {
					playernameMap.set(playerName, name(players[i][ii]));
				});
			});

			return {
				...this.qbj,
				match_teams: this.qbj.match_teams.map((teamInfo, i) => {
					return {
						...teamInfo,
						lineups: teamInfo.lineups.map((lineup) => {
							return {
								...lineup,
								players: players[i].map((player) => {
									return {name: name(player)};
								})
							}
						}),
						match_players: teamInfo.match_players.map((matchPlayer) => {
							return {
								...matchPlayer,
								player: {name: playernameMap.get(matchPlayer.player.name)}
							};
						}),
						team: {
							name: teamnameMap.get(teamInfo.team.name),
							players: players[i].map((player) => {
								return {name: name(player)};
							})
						}
					};
				}),
				match_questions: this.qbj.match_questions.map((questionInfo) => {
					return {
						...questionInfo,
						buzzes: questionInfo.buzzes.map((buzz) => {
							return {
								...buzz,
								player: {name: playernameMap.get(buzz.player.name)},
								team: {
									name: teamnameMap.get(buzz.team.name),
									players: buzz.team.players.map((player) => {
										return {name: playernameMap.get(player.name)};
									})
								}
							};
						})
					};
				})
			} as QBJ;
		}
		return undefined;
	}
}