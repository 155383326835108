import React from "react";
import ReactDOM from "react-dom";
import {runInAction} from "mobx";
import firebase from "firebase/app";
import "firebase/auth";
import {BrowserRouter as Router} from "react-router-dom";
import "./index.css";
import {App} from "./App";
import {LoginHelper} from "util/LoginHelper";

LoginHelper.singleton.init();

const onLogInOrOut = () => firebase.auth().onAuthStateChanged((user) => {
	const helper = LoginHelper.singleton;
	runInAction(() => {
		helper._userSet = true;
		if (user)
		{
			//User is signed on
			// console.log("logged on: ", user);
			helper._user = user;
		} else {
			//User is signed out
		}
	});
});
window.addEventListener("load", onLogInOrOut);

ReactDOM.render(
	<React.StrictMode>
	    <Router>
	       	<App />
	    </Router>
  	</React.StrictMode>,
  document.getElementById("root")
);