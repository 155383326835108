import * as React from "react";
import {computed, makeObservable, ObservableMap} from "mobx";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {APICaller} from "util/APICaller";
import {APIModel} from "util/APIModel";
import {StateMachine, ScheduleSortF} from "util/StateMachine";
import {TextHelper as txt, str} from "util/TextHelper";
import {TeamScheduleRowWrapper} from "util/TeamScheduleRowWrapper";
import {SeasonHelper} from "util/SeasonHelper";
import {VerticalBox} from "ui/VerticalBox";
import {EmptyPane} from "ui/EmptyPane";
import {ToggleButtons} from "ui/ToggleButtons";
import {TableHeader} from "ui/TableHeader";
import {GameLocation} from "dto/ScheduleRow";

export interface ScheduleTableProps {
	sortFunc: ScheduleSortF;
	columnModes: ObservableMap<number, number>;
	sortFuncs: Map<string, ScheduleSortF>;
	setSortFunc: (sortF: ScheduleSortF) => void;
	rows: TeamScheduleRowWrapper[];
	hideTitle?: boolean;
	suppressHomeLink?: boolean;
	season?: number;
}

@observer
export class ScheduleTable extends React.Component<ScheduleTableProps> {
	static get headers(): string[]
	{
		return ["Week", "Division", "Home", "Away", "Result"];
	}

	get rows(): TeamScheduleRowWrapper[]
	{
		return this.props.rows;
	}
	get filterText(): string
	{
		return StateMachine.singleton.scheduleFilterText;
	}

	render() {
		return <VerticalBox className="Fit-Content">
			{!this.props.hideTitle && <h5 className="Schedule-Table-Title">{str("SCHEDULE_LABEL")}</h5>}
			{this.rows.length
				?
				<table className="Schedule-Pane-Row-Container table">
					<TableHeader {...this.props} season={this.props.season} headers={ScheduleTable.headers}/>
					<tbody>
						{this.rows.map(this.renderScheduleRow)}
					</tbody>
				</table>
				: <EmptyPane/>
			}
		</VerticalBox>;
	}

	readonly renderScheduleRow = (row: TeamScheduleRowWrapper, index: number) => {
		const interior: React.ReactNode[] = [
			row.weekFilter(this.filterText),
			row.prettyDivisionFilter(this.filterText, this.props.season != null
				? this.props.season+1
				: SeasonHelper.currentSeason),
			<div key={2}>
				{row.location === GameLocation.HOME
					? row.team && !this.props.suppressHomeLink
						? <Link to={`/teams/id=${row.team_id}`}>
							{row.teamnameFilter(this.filterText)}
						</Link>
						: row.teamnameFilter(this.filterText)
					: row.otherTeam
						? <Link to={`/teams/id=${row.opponent.id}`}>
							{row.opponentNameFilter(this.filterText)}
						</Link>
						: row.opponentNameFilter(this.filterText)}
			</div>,
			<div key={3}>
				{row.location === GameLocation.HOME
					? row.otherTeam
						? <Link to={`/teams/id=${row.opponent.id}`}>
							{row.opponentNameFilter(this.filterText)}
						</Link>
						: row.opponentNameFilter(this.filterText)
					: row.team && !this.props.suppressHomeLink
						? <Link to={`/teams/id=${row.team_id}`}>
							{row.teamnameFilter(this.filterText)}
						</Link>
						: row.teamnameFilter(this.filterText)}
			</div>,
			row.prettyResultFilter(this.filterText)
		];

		return <tr key={index} className="Schedule-Pane-Row">
			{ScheduleTable.headers.map((header, i) => 
				<td className={`Table-Cell Table-Cell-${header}-Column Table-Cell-Row-${index.toString()}`} key={i}>
					{interior[i]}
				</td>
			)}
		</tr>;
	}
}