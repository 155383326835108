import * as React from "react";
import {action, makeObservable, observable, runInAction} from "mobx";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import Collapsible from "react-collapsible";
import {TextHelper, str} from "util/TextHelper";
import {SeasonHelper} from "util/SeasonHelper";
import {APICaller} from "util/APICaller";
import {HorizontalBox} from "ui/HorizontalBox";
import {VerticalBox} from "ui/VerticalBox";
import {LoadingPane} from "ui/LoadingPane";

@observer
export class QuestionsPane extends React.Component {
	constructor(props: {}) {
		super(props);		
		makeObservable(this, {
			tossupAnswerVisible: observable,
			bonusAnswerVisible: observable,
			_packetPassword: observable,
			_valid: observable
		});
	}

	render() {
		return (<VerticalBox spaced className="container-fluid Text-Wrap">
			<h2>{str("QUESTIONS_TITLE")}</h2>
			<VerticalBox className="Text-Left">
				<h4>{str("LEAGUE_LABEL")}</h4>
				{SeasonHelper.initialized
					? SeasonHelper.getSeasons(true).map((season) => this.renderSeason(season))
					: <LoadingPane/>}
				{this.renderExamples()}
			</VerticalBox>
		</VerticalBox>);
	}

	readonly renderSeason = (season: number) => {
		return <Collapsible trigger={str("SEASON_PREFIX")+season} key={season} open={season === SeasonHelper.currentSeason}>
			{season === SeasonHelper.currentSeason
				? <VerticalBox spaced>
					{SeasonHelper.currentWeek == null
						? <LoadingPane/>
						: <React.Fragment>
							{SeasonHelper.currentWeek > 0 ? this.renderCurrentWeek(season) : str("PACKETS_NOTHING_YET")}
							{SeasonHelper.currentWeek > 1 && <VerticalBox>
								<h5>{str("PAST_WEEKS_LABEL")}</h5>
								<div className={"Left-Pad"}>
									<table><tbody>
										{SeasonHelper.getCurrentSeasonWeeks(true).slice(1).map((week, i) =>
											this.renderPastQuestionRow(season, week, i))}
									</tbody></table>
								</div>
							</VerticalBox>}
						</React.Fragment>}
				</VerticalBox>
				: <div className={"Left-Pad"}>
					<table><tbody>
						{SeasonHelper.getSeasonWeeks(season, true).map((week, i) =>
							this.renderPastQuestionRow(season, week, i))}
					</tbody></table>
				</div>
			}
		</Collapsible>;
	}

	renderPastQuestionRow(season: number, week: number, i: number)
	{
		return <tr key={i}>
			<td className="Packet-Table-Cell-Week-Column">
				<Link to={`/conversion/season=${season}/week=${week}`}>
					{str("WEEK_PREFIX")}{week}
				</Link></td>
			<td><button
				title={str("DOWNLOAD_FILE_PDF_TOOLTIP")}
				onClick={() => SeasonHelper.downloadPacket(season, week)}>
				{str("DOWNLOAD_FILE_PDF_BUTTON")}
			</button></td>
			<td><button
				title={str("DOWNLOAD_FILE_DOCX_TOOLTIP")}
				onClick={() => SeasonHelper.downloadPacket(season, week, false, "DOCX")}>
				{str("DOWNLOAD_FILE_DOCX_BUTTON")}
			</button></td>
			<td><Link to={`/conversion/season=${season}/week=${week}`}></Link></td>
		</tr>;
	}

	_valid: boolean = false;
	_clicked: boolean = false;
	_packetPassword: string = "";
	readonly renderCurrentWeek = (season: number) => {
		return <VerticalBox>
			<h5>Current week:</h5>
			<VerticalBox spaced className="Left-Pad">
				<HorizontalBox spaced>
					<label htmlFor="password">{str("ENTER_PASSWORD_LABEL")}</label>
					<input type="password" id="password" value={this._packetPassword} onChange={this.onChangePassword}/>
					<button disabled={!this._packetPassword.length || this._valid} onClick={this.onValidatePassword}>Submit</button>
				</HorizontalBox>
				{this._valid && <VerticalBox>
					<HorizontalBox spaced>
						<div className="Packet-Table-Cell-Week-Column">{str("WEEK_PREFIX")}{SeasonHelper.currentWeek}</div>
						<button title={str("DOWNLOAD_FILE_JSON_TOOLTIP")}
								onClick={() => SeasonHelper.currentSeason && SeasonHelper.currentWeek
									&& SeasonHelper.downloadPacket(SeasonHelper.currentSeason, SeasonHelper.currentWeek, true)}>
							{str("DOWNLOAD_FILE_JSON_BUTTON")}
						</button>
					</HorizontalBox>
					<div>{str("DOWNLOAD_FILE_HELP")}</div>
				</VerticalBox>}
			</VerticalBox>
		</VerticalBox>;
	};

	readonly onChangePassword = action((e: React.ChangeEvent<HTMLInputElement>) => this._packetPassword = e.target.value);

	readonly onValidatePassword = async () => {
		if (this._packetPassword.length && !this._clicked)
		{
			this._clicked = true;
			const valid = SeasonHelper.currentSeason && SeasonHelper.currentWeek
				? await APICaller.validatePacketPassword(SeasonHelper.currentSeason, SeasonHelper.currentWeek, this._packetPassword)
				: false;
			if (valid)
			{
				runInAction(() => this._valid = valid);
			}
			this._clicked = false;
		}
		return undefined;
	};

	readonly tossups = [1,2,3].map(v => `TU${v}`);
	readonly bonuses = [1,2,3].map(v => `B${v}`);

	readonly tossupAnswerVisible = this.tossups.map(() => false);
	readonly bonusAnswerVisible = this.bonuses.map(() => [false, false, false]);

	readonly renderExamples = () => {
		return <React.Fragment>
			<h4>Samples</h4>
			<Collapsible trigger={str("QS_ANATOMY_TITLE")}>
				<p dangerouslySetInnerHTML={{__html: str("QS_P1")}}/>
				<p dangerouslySetInnerHTML={{__html: str("QS_P2")}}/>
				<p dangerouslySetInnerHTML={{__html: str("QS_P3")}}/>
				<p dangerouslySetInnerHTML={{__html: str("QS_P4")}}/>
				<p dangerouslySetInnerHTML={{__html: str("QS_P5")}}/>
			</Collapsible>
			<Collapsible trigger={str("QS_TOSSUPS_TITLE")}>
				<VerticalBox spaced className="Question-Section">
					{this.tossups.map(this.renderTossup)}
				</VerticalBox>
			</Collapsible>
			<Collapsible trigger={str("QS_BONUSES_TITLE")}>
				<VerticalBox spaced className="Question-Section">
					{this.bonuses.map(this.renderBonus)}
				</VerticalBox>
			</Collapsible>
		</React.Fragment>;
	};

	readonly renderTossup = (key: string, i: number) => {
		const showAnswer = action(() => this.tossupAnswerVisible[i] = true);
		const hideAnswer = action(() => this.tossupAnswerVisible[i] = false);

		return <VerticalBox key={i} className="Question">
			<p dangerouslySetInnerHTML={{__html: TextHelper.getQuestionText(key)}}/>
			<div className={"Horizontal-Box Horizontal-Box-Spaced Question-Answerline"}
				onMouseEnter={showAnswer} onMouseLeave={hideAnswer}
				onTouchStart={showAnswer} onTouchEnd={hideAnswer}>
					<strong>{str("QS_ANSWER")}</strong>
					<div style={{display: this.tossupAnswerVisible[i] ? "none" : ""}}>
						{str("QS_HOVER")}
					</div>
					<div style={{display: this.tossupAnswerVisible[i] ? "" : "none"}} className={`${key}-Answerline`} dangerouslySetInnerHTML={{__html: TextHelper.getQuestionAnswerline(key)}}/>
			</div>
		</VerticalBox>
	};

	readonly renderBonus = (key: string, i: number) => {
		const showAnswer = (n: number) => action(() => this.bonusAnswerVisible[i][n] = true);
		const hideAnswer = (n: number) => action(() => this.bonusAnswerVisible[i][n] = false);

		const renderBonusPart = (n: number, ii: number) => {
			const partKey = key + "_" + (n+1);
			return <VerticalBox key={ii}>
				<p dangerouslySetInnerHTML={{__html: TextHelper.getQuestionText(partKey)}}/>
				<div className={"Horizontal-Box Horizontal-Box-Spaced Question-Answerline"}
					onMouseEnter={showAnswer(n)} onMouseLeave={hideAnswer(n)}
					onTouchStart={showAnswer(n)} onTouchEnd={hideAnswer(n)}>
					<strong>{str("QS_ANSWER")}</strong>
					<div style={{display: this.bonusAnswerVisible[i][n] ? "none" : ""}}>
						{str("QS_HOVER")}
					</div>
					<div style={{display: this.bonusAnswerVisible[i][n] ? "" : "none"}} className={`${partKey}-Answerline`} dangerouslySetInnerHTML={{__html: TextHelper.getQuestionAnswerline(partKey)}}/>
				</div>
			</VerticalBox>;
		};

		return <VerticalBox spaced key={i} className="Question">
			{[0,1,2].map(renderBonusPart)}
		</VerticalBox>
	};
}