import * as React from "react";
import {makeAutoObservable} from "mobx";
import {RegexHelper} from "util/RegexHelper";
import {APIModel} from "util/APIModel";
import {str} from "util/TextHelper";
import {SeasonHelper} from "util/SeasonHelper";
import {ScheduleRow, TeamScheduleRow, GameLocation, GameResult} from "dto/ScheduleRow";
import {TeamInfo} from "dto/TeamInfo";

export class TeamScheduleRowWrapper {
	private _teamScheduleRow: TeamScheduleRow;

	constructor(row: TeamScheduleRow)
	{
		this._teamScheduleRow = row;
		makeAutoObservable(this);
	}
	get row(): TeamScheduleRow {return this._teamScheduleRow}
	get team_id(): number {return this.row.team_id}
	get teamname(): string {return this.isBye ? "BYE" : this.team?.name ?? "";}
	//@ts-ignore
	get team(): TeamInfo | undefined {return APIModel.singleton.teamsById.get(""+this.team_id)}
	get scheduleRow(): ScheduleRow {return this.row.row}
	get game_id(): string {return this.scheduleRow.game_id}
	get week(): number {return this.scheduleRow.week}
	division(season: number = SeasonHelper.currentSeason): string {
		const seasonIndex = this.team?.seasons?.indexOf(season);
		if (seasonIndex != undefined && seasonIndex >= 0)
			return this.team?.divisions?.[seasonIndex].toString() ?? ""
		return this.isBye ? "-" : "";
	}
	prettyDivision(season: number = SeasonHelper.currentSeason): string {return this.division(season) ?? "TDB"}
	get location(): GameLocation {return this.scheduleRow.location}
	get prettyLocation(): string {
		return this.location === GameLocation.HOME
			? "Home"
			: "Away";
	}
	get opponent(): {id: number, name: string} {return this.scheduleRow.opponent}
	get opponentName(): string {return this.scheduleRow.opponent.name}
	//@ts-ignore
	get otherTeam(): TeamInfo | undefined {return APIModel.singleton.teamsById.get(this.opponent.id.toString())}
	get result(): string {return this.scheduleRow.result ?? ""}
	get prettyResult(): string {
		return this.result === ""
			? "TBD"
			: this.result;
	}
	get isBye(): boolean {return this.team_id === 0} //if home team is BYE

	teamnameFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.teamname, filterText)}
	game_idFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.game_id, filterText)}
	weekFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.week, filterText)}
	prettyDivisionFilter(filterText: string, season: number = SeasonHelper.currentSeason): React.ReactNode {return RegexHelper.markFiltered(this.prettyDivision(season), filterText)}
	prettyLocationFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.prettyLocation, filterText)}
	opponentNameFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.opponentName, filterText)}
	prettyResultFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.prettyResult, filterText)}

	static filterF(filterText: string, season: number = SeasonHelper.currentSeason): (wrapper: TeamScheduleRowWrapper) => boolean
	{
		const regExp = RegexHelper.niceRegExp(filterText);
		return (wrapper: TeamScheduleRowWrapper) => {
			for (let field in wrapper.row.row)
			{
				if (field === "team_id" || field === "game_id")
					continue;

				if (wrapper[field as keyof ScheduleRow]
					&& regExp.test(wrapper[field as keyof ScheduleRow]!.toString()))
				{
					return true;
				}
			}
			if (regExp.test(wrapper.division(season))) return true;
			if (regExp.test(wrapper.prettyResult)) return true;
			if (regExp.test(wrapper.teamname)) return true;
			if (regExp.test(wrapper.opponentName)) return true;

			return false;
		}
	}

	static invertResult(result?: GameResult): GameResult | undefined
	{
		if (result == null || result === GameResult.UNPLAYED)
			return result;
		return result === GameResult.WIN
			? GameResult.LOSS
			: GameResult.WIN;
	}
}