import * as React from "react";
import {action, computed, makeObservable, ObservableMap} from "mobx";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {str} from "util/TextHelper";
import {TeamStatsWrapper} from "util/StatsRowWrapper"
import {APIModel} from "util/APIModel";
import {APICaller} from "util/APICaller";
import {TeamStatsSortF} from "util/StateMachine";
import {VerticalBox} from "ui/VerticalBox";
import {EmptyPane} from "ui/EmptyPane";
import {LoadingPane} from "ui/LoadingPane";
import {TableHeader} from "ui/TableHeader";
import {TeamStatsInfo} from "dto/StatsInfo";

export interface TeamTableProps {
	id: number;
	season?: number;
	sortFunc: TeamStatsSortF;
	columnModes: ObservableMap<number, number>;
	sortFuncs: Map<string, TeamStatsSortF>;
	setSortFunc: (sortF: TeamStatsSortF) => void;
}

@observer
export class TeamTable extends React.Component<TeamTableProps> {
	constructor(props: TeamTableProps)
	{
		super(props);
		makeObservable(this, {
			teamStats: computed
		});
	}

	componentDidMount() {
		// APIModel.singleton.refreshSchedule(this.props.id);
		APIModel.singleton.refreshTeamStats(this.props.id);
	}

	componentDidUpdate(prevProps: TeamTableProps)
	{
		if (prevProps.id !== this.props.id)
		{
			APIModel.singleton.refreshTeamStats(this.props.id);
		}
	}

	get teamStats(): TeamStatsWrapper[]
	{
		const statsBySeason = APIModel.singleton.teamStatsById.get(this.props.id);
		if (this.props.season != null && statsBySeason)
		{
			const stats = statsBySeason.get(this.props.season);
			if (stats)
			{
				const baseStats = stats.map((row) => new TeamStatsWrapper(row))
					.sort((r1,r2) => r1.week-r2.week)
					.sort(this.props.sortFunc);
				if (baseStats.length)
				{
					const dot = (x:number[], y:number[]) => {
						//assume x.length === y.length
						const len = x.length;
						const output = new Array(len);
						for (let i = 0; i < len; i++)
						{
							output[i] = x[i]+y[i];
						}
						return output;
					}
					const [powers, tens, negs, tossups_heard, bonus_points, bonuses_heard, points, opponent_points] = baseStats
						.map((row): number[] => [row.powers, row.tens, row.negs, row.tuh, row.row.bonus_points ?? 0, row.bonuses_heard, row.points, row.opponent_points])
						.reduce(dot);

					const summary = new TeamStatsWrapper({
						...baseStats[0].row,
						powers, tens, negs, tossups_heard, points, opponent_points,
						ppb: bonuses_heard > 0
							? bonus_points/bonuses_heard
							: powers+tens > 0
								? bonus_points/(powers+tens)
								: 0
					} as TeamStatsInfo);
					baseStats.push(summary);
				}
				return baseStats;
			}
		}
		return [];
	}

	static get headers(): string[]
	{
		return ["Week", "Division", "Opponent", "Result", "PF", "PA", "TUH", "15", "10", "-5", "PPB"];
	}

	render() {
		return <VerticalBox>
			<h5 className="Teams-Table-Title">{str("TEAMS_PAGE_MATCH_HISTORY_TITLE")}</h5>
			{this.teamStats.length
				? <table className="table">
					<TableHeader {...this.props} headers={TeamTable.headers}/>
					<tbody>
						{this.teamStats.map(this.renderTeamRow)}
					</tbody>
				</table>
				: <EmptyPane/>
			}
		</VerticalBox>;
	}

	readonly renderTeamRow = (row: TeamStatsWrapper, index: number): React.ReactNode => {
		//render interior of cells manually to allow custom rendering
		const interior: React.ReactNode[] = index === this.teamStats.length - 1
		? [
			"Total",
			"",
			"",
			"",
			row.points,
			row.opponent_points,
			row.tuh,
			row.powers,
			row.tens,
			row.negs,
			row.prettyPpb,
		]
		: [
			row.week,
			row.division(this.props.season),
			row.opponent_id
				? <Link to={`/teams/id=${row.opponent_id}`}>
					{row.opponentname}
				</Link>
				: row.opponentname,
			row.result,
			row.points,
			row.opponent_points,
			row.tuh,
			row.powers,
			row.tens,
			row.negs,
			row.prettyPpb,
		];
		return <tr key={index} className="Team-Table-Row">
			{TeamTable.headers.map((header, i) => 
				<td className={`Table-Cell Table-Cell-${header}-Column Table-Cell-Row-${index.toString()}`} key={i}>
					{index === this.teamStats.length - 1 ? <strong>{interior[i]}</strong> : interior[i]}
				</td>
			)}
		</tr>;
	}
}