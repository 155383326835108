import * as React from "react";
import {computed, makeObservable} from "mobx";
import {observer} from "mobx-react";
import {ToggleButtons} from "ui/ToggleButtons";
import {HorizontalBox} from "ui/HorizontalBox";
import {str} from "util/TextHelper";
import {SeasonHelper} from "util/SeasonHelper";

export interface SeasonToggleProps {
	selectedIndex: number;
	onToggleIndex: (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => void;
	overallEnabled?: boolean;
	className?: string;
	//all uses of season related to a specific team will eventually have to be passed through their seasons array;
	// most relevantly, this means that index of the toggle cannot be directly used
	subsetOfSeasons?: number[];

}

@observer
export class SeasonToggle extends React.Component<SeasonToggleProps> {
	constructor(props: SeasonToggleProps) {
		super(props);
		makeObservable(this, {
			labels: computed
		})
	}

	get labels(): React.ReactNode[] {
		let seasons: React.ReactNode[] = [];
		if (this.props.overallEnabled)
			seasons.push(str("ALL_SEASONS"));
		if (this.props.subsetOfSeasons)
		{
			seasons.push(...this.props.subsetOfSeasons);
		} else {
			for (let i = 0; i < (SeasonHelper.currentSeason ?? 0); i++) seasons.push(i+1);
		}
		return seasons;
	}

	render() {
		return <HorizontalBox spaced className={this.props.className}>
			<div>{str("SEASON_LABEL")}</div>
			<ToggleButtons className="Season-Toggle"
				labels={this.labels}
				selectedIndex={this.props.selectedIndex}
				onToggleIndex={this.props.onToggleIndex}
			/>
		</HorizontalBox>
	}
}