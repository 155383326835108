import * as React from "react";

export interface HorizontalBoxProps {
	className?: string;
	spaced?: boolean;
}

export class HorizontalBox extends React.Component<HorizontalBoxProps> {
	render() {
		return <div className={`Horizontal-Box ${this.props.className ?? ""} ${this.props.spaced ? "Horizontal-Box-Spaced" : ""}`}>
			{this.props.children}
		</div>;
	}
}