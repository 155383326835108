import * as React from "react";
import {makeAutoObservable} from "mobx";
import {RegexHelper} from "util/RegexHelper";
import {APIModel} from "util/APIModel";
import {SeasonHelper} from "util/SeasonHelper";
import {PlayerStatsInfo, TeamStatsInfo} from "dto/StatsInfo";
import {PlayerInfo} from "dto/PlayerInfo";
import {TeamInfo} from "dto/TeamInfo";
import {GameResult, TeamScheduleRow} from "dto/ScheduleRow";

export class TeamStatsWrapper {
	private _statsRow: TeamStatsInfo;

	constructor(row: TeamStatsInfo)
	{
		this._statsRow = row;
		makeAutoObservable(this);
	}
	get name(): string {return this.row.team}
	get team_id(): number | undefined {return this.row.team_id}
	get team(): TeamInfo | undefined {return this.team_id
		//@ts-ignore
		? APIModel.singleton.teamsById.get(""+this.team_id)
		: undefined}
	division(season: number = SeasonHelper.currentSeason): string {
		const seasonIndex = this.team?.seasons?.indexOf(season);
		if (seasonIndex != undefined && seasonIndex >= 0)
			return this.team?.divisions?.[seasonIndex].toString() ?? ""
		return "";
	}
	get row(): TeamStatsInfo {return this._statsRow}
	get game_id(): string {
		// return "20210127-01"
		return this.row.game_id ?? ""
	}
	get result(): GameResult {return this.row.result}
	get season(): number {return this.row.season ?? -1}
	get games(): number {return this.row.games_played ?? 0}
	get wins(): number {return this.row.wins ?? 0}
	get losses(): number {return this.row.losses ?? 0}
	get ties(): number {return this.row.ties ?? 0}
	get powers(): number {return this.row.powers}
	get tens(): number {return this.row.tens}
	get negs(): number {return this.row.negs}
	get ppg(): number {return this.row.ppg != null
		? this.row.ppg
		: this.games === 0 ? 0 : this.points/this.games}
	get prettyPpg(): string {return this.ppg.toString().slice(0,5)}
	get tuh(): number {return this.row.tossups_heard ?? 0}
	get bonuses_heard(): number {return this.row.bonuses_heard ?? 0}
	get points(): number {return this.row.points != null
		? this.row.points
		: this.row.bonus_points != null
			? this.bonuses + (15*this.powers + 10*this.tens - 5*this.negs)
			: 0}
	get bonuses(): number {return this.row.bonus_points ?? 0}
	get ppb(): number {return this.row.ppb != null
		? this.row.ppb
		: this.row.bonuses_heard != null
			? this.bonuses === 0 ? 0 : (this.bonuses/this.bonuses_heard)
			: (this.powers + this.tens) === 0 ? 0 : (this.bonuses/(this.powers + this.tens))
	}
	get prettyPpb(): string {return this.ppb.toPrecision(4)}
	get opponent_id(): number | undefined {return this.row.opponent_id}
	get opponentname(): string {return this.row.opponent ?? ""}
	get opponent(): TeamInfo | undefined {return this.opponent_id
		? APIModel.singleton.teamsById.get(this.opponent_id)
		: undefined}
	get opponent_points(): number {return this.row.opponent_points ?? 0}
	get week(): number {return this.row.week ?? 0}

	nameFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.name, filterText)}
	resultFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.result, filterText)}
	seasonFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.season, filterText)}
	gamesFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.games, filterText)}
	winsFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.wins, filterText)}
	lossesFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.losses, filterText)}
	tiesFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.ties, filterText)}
	powersFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.powers, filterText)}
	tensFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.tens, filterText)}
	negsFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.negs, filterText)}
	ppgFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.ppg, filterText)}
	tuhFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.tuh, filterText)}
	ppbFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.ppb, filterText)}

	// opponentnameFilter(filterText: string): React.ReactNode {return this.markFiltered(this.opponentname, filterText)}

	static filterF(filterText: string): (wrapper: TeamStatsWrapper) => boolean
	{
		const regExp = RegexHelper.niceRegExp(filterText);
		return (wrapper: TeamStatsWrapper) => {
			for (let field in wrapper.row)
			{
				if (wrapper.row[field as keyof TeamStatsInfo]
					&& regExp.test(wrapper.row[field as keyof TeamStatsInfo]!.toString()))
				{
					return true;
				}
			}
			return false;
		};
	}
}

export class PlayerStatsWrapper {
	private _statsRow: PlayerStatsInfo;

	constructor(row: PlayerStatsInfo)
	{
		this._statsRow = row;
		makeAutoObservable(this);
	}
	get row(): PlayerStatsInfo {return this._statsRow}
	get player_id(): number | undefined {return this.row.player_id}
	get player(): PlayerInfo | undefined {return this.player_id
		? APIModel.singleton.playersById.get(this.player_id)
		: undefined}
	get playername(): string {return this.row.player}
	get teamname(): string {return this.row.team}
	get team(): TeamInfo | undefined {return this.player_id
		//@ts-ignore
		? APIModel.singleton.teamsByPlayerId.get(""+this.player_id)
		: undefined
	}
	get team_id(): number | undefined {return this.team?.id}
	division(season: number = SeasonHelper.currentSeason): string {
		const seasonIndex = this.team?.seasons?.indexOf(season);
		if (seasonIndex != undefined && seasonIndex >= 0)
			return this.team?.divisions?.[seasonIndex].toString() ?? ""
		return "";
	}
	get season(): number {return this.row?.season ?? -1}
	get week(): number {return this.row?.week ?? 0}
	get games(): number {return this.row?.games_played ?? 0}
	get powers(): number {return this.row?.powers ?? 0}
	get tens(): number {return this.row?.tens ?? 0}
	get negs(): number {return this.row?.negs ?? 0}
	get ppg(): number {return this.row?.ppg != null
		? this.row.ppg
		: this.week
			? this.points
			: this.games === 0 ? 0 : this.points/this.games}
	get prettyPpg(): string {return this.ppg.toString().slice(0,5)}
	get points(): number {return 15*this.powers + 10*this.tens - 5*this.negs}
	get tuh(): number {return this.row.tossups_heard ?? 0}
	get opponentname(): string {return this.row.opponent ?? ""}
	get opponent_id(): number | undefined {return this.row.opponent_id}
	get opponent(): TeamInfo | undefined {return this.opponent_id
		? APIModel.singleton.teamsById.get(this.opponent_id)
		: undefined}

	playernameFilter(filterText: string): React.ReactNode {return this.playername ? RegexHelper.markFiltered(this.playername, filterText) : ""}
	teamnameFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.teamname, filterText)}
	seasonFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.season, filterText)}
	gamesFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.games, filterText)}
	powersFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.powers, filterText)}
	tensFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.tens, filterText)}
	negsFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.negs, filterText)}
	ppgFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.ppg, filterText)}
	tuhFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.tuh, filterText)}

	static filterF(filterText: string): (wrapper: PlayerStatsWrapper) => boolean
	{
		const regExp = RegexHelper.niceRegExp(filterText);
		return (wrapper: PlayerStatsWrapper) => {
			for (let field in wrapper.row)
			{
				if (wrapper.row[field as keyof PlayerStatsInfo]
					&& regExp.test(wrapper.row[field as keyof PlayerStatsInfo]!.toString()))
				{
					return true;
				}
			}
			return false;
		};
	}
}