import * as React from "react";
import {observer} from "mobx-react";
import {StyledFirebaseAuth} from "react-firebaseui";
import {Redirect} from "react-router-dom";
import {LoginHelper} from "util/LoginHelper";
import {str} from "util/TextHelper";
import {VerticalBox} from "ui/VerticalBox";
import {LoadingPane} from "ui/LoadingPane";

@observer
export class LoginPane extends React.Component {
	render() 
	{
		const loginHelper = LoginHelper.singleton;

		return <div className="container-fluid">
			<VerticalBox spaced>
				<h2>{str("LOGIN_TITLE")}</h2>
				{loginHelper.loggedIn != null
					? loginHelper.loggedIn === true
						? <Redirect to="/user"/>
						: loginHelper.auth && <VerticalBox spaced>
							<StyledFirebaseAuth className="Text-Wrap"
								uiConfig={loginHelper.uiConfig}
								firebaseAuth={loginHelper.auth}/>
							<div className="Text-Wrap">{str("LOGIN_P1")}</div>
						</VerticalBox>
					: <LoadingPane/>
				}
			</VerticalBox>
		</div>;
	}
}