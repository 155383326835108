import React from "react";
import {slide as Menu} from "react-burger-menu";
import {Link} from "react-router-dom";
import {NavigationHelper, ButtonProps} from "util/NavigationHelper";

export class BurgerMenu extends React.Component {
  render() {
    return (
        // Pass on our props
        <Menu {...this.props}>
          {NavigationHelper.buttons.map(this.renderButton)}
        </Menu>
    )
  }

  readonly renderButton = (button: ButtonProps, i: number) => <Link className="menu-item" to={button.href} key={i}>
    {button.label}
  </Link>;
};