import {action, makeAutoObservable, ObservableMap} from "mobx";
import {PlayerStatsWrapper, TeamStatsWrapper} from "util/StatsRowWrapper";
import {PlayerWrapper} from "util/PlayerWrapper";
import {TeamWrapper} from "util/TeamWrapper";
import {TeamScheduleRowWrapper} from "util/TeamScheduleRowWrapper";
import {APIModel} from "util/APIModel";
import {SeasonHelper} from "util/SeasonHelper";
import {UserModel} from "util/UserModel";
import {TeamStatsInfo, PlayerStatsInfo} from "dto/StatsInfo";
import {TeamScheduleRow} from "dto/ScheduleRow";
import {TossupConversionWrapper, BonusConversionWrapper, SummaryConversionWrapper} from "util/ConversionInfoWrapper"

type sortF<T> = (row1: T, row2: T) => number;

export type TeamStatsSortF = sortF<TeamStatsWrapper>;
export type PlayerStatsSortF = sortF<PlayerStatsWrapper>;

export type TossupConversionSortF = sortF<TossupConversionWrapper>;
export type BonusConversionSortF = sortF<BonusConversionWrapper>;
export type SummaryConversionSortF = sortF<SummaryConversionWrapper>;

export type TeamSortF = sortF<TeamWrapper>;
export type PlayerSortF = sortF<PlayerWrapper>;

export type ScheduleSortF = sortF<TeamScheduleRowWrapper>;

export class StateMachine {
	constructor() {
		makeAutoObservable(this);
	}

	private static _singleton?: StateMachine;
	static get singleton(): StateMachine
	{
		if (!StateMachine._singleton)
		{
			StateMachine._singleton = new StateMachine();
		}
		return StateMachine._singleton;
	}

	//schedule page state
	_scheduleFilterText: string = "";
	get scheduleFilterText(): string
	{
		return this._scheduleFilterText;
	}
	private scheduleFilterDelayTimer?: NodeJS.Timeout = undefined;
	readonly onChangeScheduleFilterText = StateMachine.createDelayedOnChange(
		this.scheduleFilterDelayTimer, (e) => this._scheduleFilterText = e.target.value);

	_scheduleWeekToggle: number = 0;
	get scheduleWeekToggle(): number {return this._scheduleWeekToggle}
	readonly createToggleScheduleWeekCallback = (index: number) =>
		action((e: React.MouseEvent<HTMLButtonElement>) => this._scheduleWeekToggle = index);

	_scheduleDivisionToggle: number = 0;
	get scheduleDivisionToggle(): number {return this._scheduleDivisionToggle}
	readonly createToggleScheduleToggleCallback = (index: number) =>
		action((e: React.MouseEvent<HTMLButtonElement>) => this._scheduleDivisionToggle = index);

	_scheduleColumnModes: ObservableMap<number, number> = new ObservableMap();
	get scheduleColumnModes(): ObservableMap<number, number>
	{
		return this._scheduleColumnModes;
	}

	get scheduleRows(): TeamScheduleRowWrapper[]
	{
		const baseRows = (StateMachine.singleton.scheduleWeekToggle === 0
			//@ts-ignore
			? APIModel.singleton.weekToSchedule.get(SeasonHelper.currentWeek)
			: APIModel.singleton.allScheduleRows) ?? [];

		return baseRows
			?.map((row) => new TeamScheduleRowWrapper(row))
			.filter((row) => this._scheduleDivisionToggle === 0
				//manually do the division filter to deal with the lettering scheme
				|| [0, "1", "2a", "2b", "3a", "3b"][this.scheduleDivisionToggle] === row.division())
				// || ""+this.scheduleDivisionToggle === row.division())
			.filter(TeamScheduleRowWrapper.filterF(StateMachine.singleton.scheduleFilterText))
			.sort((a, b) => a.week - b.week)
			.sort(this._scheduleSortFunc) ?? [];
	}

	_scheduleSortFunc: ScheduleSortF = () => 1;
	readonly setScheduleSortFunc = action((sortF: ScheduleSortF) => this._scheduleSortFunc = sortF)

	//stats page state
	_statsFilterText: string = "";
	get statsFilterText(): string
	{
		return this._statsFilterText;
	}
	private statsFilterDelayTimer?: NodeJS.Timeout = undefined;
	readonly onChangeStatsFilterText = StateMachine.createDelayedOnChange(
		this.statsFilterDelayTimer, (e) => this._statsFilterText = e.target.value);

	_statsTeamIndividualToggle: number = 0;
	get statsTeamIndividualToggle(): number
	{
		return this._statsTeamIndividualToggle;
	}
	readonly createToggleStatsTeamIndividualCallback = (index: number) =>
		action((e: React.MouseEvent<HTMLButtonElement>) => this._statsTeamIndividualToggle = index);

	_statsCurrentAllSeasonsToggle: number = 0;
	get statsCurrentAllSeasonsToggle(): number
	{
		return this._statsCurrentAllSeasonsToggle;
	}
	readonly createToggleCurrentAllSeasonsCallback = (index: number) =>
		action((e: React.MouseEvent<HTMLButtonElement>) => this._statsCurrentAllSeasonsToggle = index);

	_statsDivisionToggle: number = 0;
	get statsDivisionToggle(): number {return this._statsDivisionToggle}
	readonly createToggleStatsToggleCallback = (index: number) =>
		action((e: React.MouseEvent<HTMLButtonElement>) => this._statsDivisionToggle = index);

	_statsTeamColumnModes: ObservableMap<number, number> = new ObservableMap();
	get statsTeamColumnModes(): ObservableMap<number, number>
	{
		return this._statsTeamColumnModes;
	}

	_statsPlayerColumnModes: ObservableMap<number, number> = new ObservableMap();
	get statsPlayerColumnModes(): ObservableMap<number, number>
	{
		return this._statsPlayerColumnModes;
	}

	_statsLegendCollapsed: boolean = false;
	get statsLegendCollapsed(): boolean
	{
		return this._statsLegendCollapsed;
	}
	readonly onToggleStatsLegendCollapsed = () => this._statsLegendCollapsed = !this._statsLegendCollapsed;

	statsTeamSummaries(season: number = SeasonHelper.currentSeason): TeamStatsWrapper[]
	{
		const baseRows: TeamStatsInfo[] = APIModel.singleton.teamStatSummaries(season);
		return baseRows
			?.map((row) => new TeamStatsWrapper(row))
			.filter(TeamStatsWrapper.filterF(this.statsFilterText))
			.filter((row) => this.statsDivisionToggle === 0
				//manually do the division filter to deal with the lettering scheme
				|| (season > 1
					? [0, "1", "2a", "2b", "3a", "3b"][this.statsDivisionToggle] === row.division(season)
					: row.division(season) === ""+this.statsDivisionToggle))
			.sort((a,b)=>b.ppg - a.ppg)
			.sort(this._statsSortFunc as TeamStatsSortF) ?? [];
	}
	statsPlayerSummaries(season: number = SeasonHelper.currentSeason): PlayerStatsWrapper[]
	{
		const baseRows: PlayerStatsInfo[] =  APIModel.singleton.allPlayerStatSummaries(season);
		return baseRows
			?.map((row) => new PlayerStatsWrapper(row))
			.filter(PlayerStatsWrapper.filterF(this.statsFilterText))
			.filter((row) => this.statsDivisionToggle === 0
				//manually do the division filter to deal with the lettering scheme
				|| (season > 1
					? [0, "1", "2a", "2b", "3a", "3b"][this.statsDivisionToggle] === row.division(season)
					: row.division(season) === ""+this.statsDivisionToggle))
			.sort(StateMachine.playerStatsColumnSortFuncs.get("PPG"))
			.sort(this._statsSortFunc as PlayerStatsSortF) ?? [];
	}

	_statsSortFunc: TeamStatsSortF | PlayerStatsSortF = () => 1;
	readonly setStatsSortFunc = action((sortF: TeamStatsSortF | PlayerStatsSortF) => this._statsSortFunc = sortF);

	//conversion
	_tossupConversionColumnModes: ObservableMap<number, number> = new ObservableMap();
	get tossupConversionColumnModes() {return this._tossupConversionColumnModes}
	_bonusConversionColumnModes: ObservableMap<number, number> = new ObservableMap();
	get bonusConversionColumnModes() {return this._bonusConversionColumnModes}
	_weeklyConversionColumnModes: ObservableMap<number, number> = new ObservableMap();
	get weeklyConversionColumnModes() {return this._weeklyConversionColumnModes}

	_tossupSortFunc: TossupConversionSortF = () => 1;
	readonly setTossupSortFunc = action((sortF: TossupConversionSortF) => this._tossupSortFunc = sortF);
	_bonusSortFunc: BonusConversionSortF = () => 1;
	readonly setBonusSortFunc = action((sortF: BonusConversionSortF) => this._bonusSortFunc = sortF);
	_summarySortFunc: SummaryConversionSortF = () => 1;
	readonly setSummarySortFunc = action((sortF: SummaryConversionSortF) => this._summarySortFunc = sortF);	

	_tossupBonus: number = 0;
	get tossupBonus(): number {return this._tossupBonus}
	createToggleTossupBonusCallback = (index: number) =>
		action((e: React.MouseEvent<HTMLButtonElement>) => this._tossupBonus = index);

	//teams overiew page state
	_teamsFilterText: string = "";
	get teamsFilterText(): string
	{
		return this._teamsFilterText;
	}
	private teamsFilterDelayTimer?: NodeJS.Timeout = undefined;
	readonly onChangeTeamFilterText = StateMachine.createDelayedOnChange(
		this.teamsFilterDelayTimer, (e) => this._teamsFilterText = e.target.value);

	_teamsSeasonIndex: number = 0;
	get teamsSeasonIndex(): number
	{
		return this._teamsSeasonIndex;
	}
	createToggleTeamsSeasonIndexCallback = (season: number) => 
		action((e: React.MouseEvent<HTMLButtonElement>) => this._teamsSeasonIndex = season);

	_individualTeamsSeason: ObservableMap<number, number> = new ObservableMap();
	individualTeamsSeason(team: number): number
	{
		return this._individualTeamsSeason.get(team) ?? SeasonHelper.currentSeason ?? 0;
	}
	createToggleIndividualTeamsSeasonCallback(team: number)
	{
		return (season: number) =>
			action((e: React.MouseEvent<HTMLButtonElement>) => this._individualTeamsSeason.set(team, season));
	}

	_teamsPaneColumnModes: ObservableMap<number, number> = new ObservableMap();
	get teamsPaneColumnModes(): ObservableMap<number, number>
	{
		return this._teamsPaneColumnModes;
	}

	_teamsPaneSortFunc: TeamSortF = () => 1;
	readonly setTeamsPaneSortFunc = action((sortF: TeamSortF) => this._teamsPaneSortFunc = sortF);

	//teams details page state
	_teamsLegendCollapsed: boolean = false;
	get teamsLegendCollapsed(): boolean
	{
		return this._teamsLegendCollapsed;
	}
	readonly onToggleTeamsLegendCollapsed = () => this._teamsLegendCollapsed = !this.teamsLegendCollapsed;

	_teamsPanePlayerColumnModes: ObservableMap<number, number> = new ObservableMap();
	get teamsPanePlayerColumnModes(): ObservableMap<number, number>
	{
		return this._teamsPanePlayerColumnModes;
	}

	_teamsPaneTeamColumnModes: ObservableMap<number, number> = new ObservableMap();
	get teamsPaneTeamColumnModes(): ObservableMap<number, number>
	{
		return this._teamsPaneTeamColumnModes;
	}

	_teamsPanePlayerSortFunc: PlayerStatsSortF = () => 1;
	readonly setTeamsPanePlayerSortFunc = action((sortF: PlayerStatsSortF) => this._teamsPanePlayerSortFunc = sortF);

	_teamsPaneTeamSortFunc: TeamStatsSortF = () => 1;
	readonly setTeamsPaneTeamSortFunc = action((sortF: TeamStatsSortF) => this._teamsPaneTeamSortFunc = sortF);

	_teamsPaneScheduleColumnModes: ObservableMap<number, number> = new ObservableMap();
	get teamsPaneScheduleColumnModes(): ObservableMap<number, number>
	{
		return this._teamsPaneScheduleColumnModes;
	}

	_teamsPaneScheduleSortFunc: ScheduleSortF = () => 1;
	readonly setTeamsPaneScheduleSortFunc = action((sortF: ScheduleSortF) => this._teamsPaneScheduleSortFunc = sortF);

	//players overview page state
	_playersFilterText: string = "";
	get playersFilterText(): string
	{
		return this._playersFilterText;
	}
	private playersFilterDelayTimer?: NodeJS.Timeout = undefined;
	readonly onChangePlayerFilterText = StateMachine.createDelayedOnChange(
		this.playersFilterDelayTimer, (e) => this._playersFilterText = e.target.value);

	_playersSeasonIndex: number = 0;
	get playersSeasonIndex(): number
	{
		return this._playersSeasonIndex;
	}
	createTogglePlayersSeasonIndexCallback = (season: number) => 
		action((e: React.MouseEvent<HTMLButtonElement>) => this._playersSeasonIndex = season);

	_playersPaneColumnModes: ObservableMap<number, number> = new ObservableMap();
	get playersPaneColumnModes(): ObservableMap<number, number>
	{
		return this._playersPaneColumnModes;
	}

	get playersPaneRows(): PlayerWrapper[]
	{
		return APIModel.singleton.teams
			?.map((team) => team.players).flat()
			.map((row) => new PlayerWrapper(row))
			.filter(PlayerWrapper.filterF(this.playersFilterText))
			.sort((a,b) => b.ppg - a.ppg)
			.sort(this._playersPaneSortFunc) ?? [];
	}

	_playersPaneSortFunc: PlayerSortF = () => 1;
	readonly setPlayersPaneSortFunc = action((sortF: PlayerSortF) => this._playersPaneSortFunc = sortF)

	//individual player page state
	_individualPlayerSeason: number = 0;
	get individualPlayerSeason(): number
	{
		return this._individualPlayerSeason;
	}
	createToggleIndividualPlayerSeasonCallback = (season: number) => 
		action((e: React.MouseEvent<HTMLButtonElement>) => this._individualPlayerSeason = season);

	_individualPlayerPaneColumnModes: ObservableMap<number, number> = new ObservableMap();
	get individualPlayerPaneColumnModes(): ObservableMap<number, number>
	{
		return this._individualPlayerPaneColumnModes;
	}

	_individualPlayerPaneSortFunc: PlayerStatsSortF = () => 1;
	readonly setIndividualPlayerPaneSortFunc = action((sortF: PlayerStatsSortF) => this._individualPlayerPaneSortFunc = sortF)

	_individualPlayerSeasonColumnModes: ObservableMap<number, number> = new ObservableMap();
	get individualPlayerSeasonColumnModes(): ObservableMap<number, number>
	{
		return this._individualPlayerSeasonColumnModes;
	}

	_individualPlayerSeasonSortFunc: PlayerStatsSortF = () => 1;
	readonly setindividualPlayerSeasonSortFunc = action((sortF: PlayerStatsSortF) => this._individualPlayerSeasonSortFunc = sortF)

	//user pane
	_userPaneColumnModes: ObservableMap<number, number> = new ObservableMap();
	get userPaneColumnModes(): ObservableMap<number, number>
	{
		return this._userPaneColumnModes;
	}

	_userPaneSortFunc: PlayerStatsSortF = () => 1;
	readonly setUserPaneSortFunc = action((sortF: PlayerStatsSortF) => this._userPaneSortFunc = sortF)

	_teamScheduleColumnModes: ObservableMap<number, number> = new ObservableMap();
	get teamScheduleColumnModes(): ObservableMap<number, number>
	{
		return this._teamScheduleColumnModes;
	}

	get teamScheduleRows(): TeamScheduleRowWrapper[]
	{
		if (!UserModel.singleton.userInfo?.player_id)
			return [];

		//@ts-ignore
		const team = APIModel.singleton.teamsByPlayerId.get(""+UserModel.singleton.userInfo.player_id);

		if (!team?.id)
			return [];

		//@ts-ignore
		const baseRows = APIModel.singleton.teamIdsToSchedule.get(""+team.id) ?? [];

		return baseRows
			.map((row) => new TeamScheduleRowWrapper(row))
			.sort(this._teamScheduleSortFunc);
	}

	_teamScheduleSortFunc: ScheduleSortF = () => 1;
	readonly setTeamScheduleSortFunc = action((sortF: ScheduleSortF) => this._teamScheduleSortFunc = sortF);

	//general use
	static createDelayedOnChange(
		timer: NodeJS.Timeout | undefined,
		onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
		delay: number = 150): (e: React.ChangeEvent<HTMLInputElement>) => void
	{
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			timer != null && clearTimeout(timer);
			timer = setTimeout(action(() => onChange(e)), delay);
		}
	}

	static readonly scheduleColumnSortFuncs: Map<string, ScheduleSortF> = new Map([
		["Week", (r1, r2) => r2.week - r1.week],
		// ["Division", (r1, r2) => r1.division.localeCompare(r2.division)],
		["Home", (r1, r2) => r1.teamname.localeCompare(r2.teamname)],
		["Away", (r1, r2) => r1.opponentName.localeCompare(r2.opponentName)],
		["Result", (r1, r2) => r1.result.localeCompare(r2.result)]
	]);

	static readonly teamStatsColumnSortFuncs: Map<string, TeamStatsSortF> = new Map([
		["Team", (r1, r2) => r1.name.localeCompare(r2.name)],
		["Opponent", (r1, r2) => r1.name.localeCompare(r2.name)],
		// ["Division", (r1, r2) => r1.division.localeCompare(r2.division)],
		["Week", (r1, r2) => r2.week - r1.week],
		["Result", () => 1], //implement
		["PF", () => 1], //implement
		["PA", () => 1], //implement
		["G", (r1, r2) => r2.games - r1.games],
		["TUH", (r1, r2) => r2.tuh - r1.tuh],
		["W", (r1, r2) => r2.wins - r1.wins],
		["L", (r1, r2) => r2.losses - r1.losses],
		["T", (r1, r2) => r2.ties - r1.ties],
		["15", (r1, r2) => r2.powers - r1.powers],
		["10", (r1, r2) => r2.tens - r1.tens],
		["-5", (r1, r2) => r2.negs - r1.negs],
		["PPG", (r1, r2) => r2.ppg - r1.ppg],
		["PPB", (r1, r2) => r2.ppb - r1.ppb]
	]);

	static readonly playerStatsColumnSortFuncs: Map<string, PlayerStatsSortF> = new Map([
		["Player", (r1, r2) => r1.playername
			? r2.playername
				? r1.playername.localeCompare(r2.playername)
				: -1
			: r2.playername ? 1 : 0
		],
		["Team", (r1, r2) => r1.teamname.localeCompare(r2.teamname)],
		["G", (r1, r2) => r2.games - r1.games],
		["TUH", (r1, r2) => r2.tuh - r1.tuh],
		["15", (r1, r2) => r2.powers - r1.powers],
		["10", (r1, r2) => r2.tens - r1.tens],
		["-5", (r1, r2) => r2.negs - r1.negs],
		["PPG", (r1, r2) => r2.ppg - r1.ppg]
	]);

	static readonly tossupColumnSortFuncs: Map<string, TossupConversionSortF> = new Map([
		["TU", (r1, r2) => r2.tossup - r1.tossup],
		["Answer", (r1, r2) => r1.answer.localeCompare(r2.answer)],
		["%15", (r1, r2) => r2.power_pct - r1.power_pct],
		["%(15+10)", (r1, r2) => r2.conv_pct - r1.conv_pct],
		["%(-5)", (r1, r2) => r2.neg_pct - r1.neg_pct],
		["15", (r1, r2) => r2.powers - r1.powers],
		["10", (r1, r2) => r2.tens - r1.tens],
		["-5", (r1, r2) => r2.negs - r1.negs],
	]);

	static readonly bonusColumnSortFuncs: Map<string, BonusConversionSortF> = new Map([
		["B", (r1, r2) => r2.bonus - r1.bonus],
		["Answers", (r1, r2) => r1.answers.localeCompare(r2.answers)],
		["%(Part 1)", (r1, r2) => r2.part1_conv_pct - r1.part1_conv_pct],
		["%(Part 2)", (r1, r2) => r2.part2_conv_pct - r1.part2_conv_pct],
		["%(Part 3)", (r1, r2) => r2.part3_conv_pct - r1.part3_conv_pct],
		["Average PPB", (r1, r2) => r2.overall_conv_pct - r1.overall_conv_pct],
	]);

	static readonly summaryColumnSortFuncs: Map<string, SummaryConversionSortF> = new Map([
		["%15", (r1, r2) => r2.power_pct - r1.power_pct],
		["%(15+10)", (r1, r2) => r2.conv_pct - r1.conv_pct],
		["%(-5)", (r1, r2) => r2.neg_pct - r1.neg_pct],
		["15", (r1, r2) => r2.powers - r1.powers],
		["10", (r1, r2) => r2.tens - r1.tens],
		["-5", (r1, r2) => r2.negs - r1.negs],
		["Average PPB", (r1, r2) => r2.overall_conv_pct - r1.overall_conv_pct],
	]);

	static readonly teamColumnSortFuncs: Map<string, TeamSortF> = new Map([
		// ["Division", (r1, r2) => r1.division.localeCompare(r2.division)],
		["Team", (r1, r2) => r1.name.localeCompare(r2.name)],
	]);

	static readonly playerColumnSortFuncs: Map<string, PlayerSortF> = new Map([
		["Player", (r1, r2) => r1.full_name.localeCompare(r2.full_name)],
		["Team", (r1, r2) => r1.teamname.localeCompare(r2.teamname)],
		// ["Division", (r1, r2) => r1.division.localeCompare(r2.division)],
		["G", (r1, r2) => r2.games - r1.games],
		["TUH", (r1, r2) => r2.tuh - r1.tuh],
		["15", (r1, r2) => r2.powers - r1.powers],
		["10", (r1, r2) => r2.tens - r1.tens],
		["-5", (r1, r2) => r2.negs - r1.negs],
		["PPG", (r1, r2) => r2.ppg - r1.ppg]
	]);
}