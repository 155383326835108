import * as React from "react";
import {computed, makeObservable, ObservableMap, runInAction} from "mobx";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {PlayerStatsWrapper} from "util/StatsRowWrapper";
import {APIModel} from "util/APIModel";
import {PlayerStatsSortF} from "util/StateMachine";
import {SeasonHelper} from "util/SeasonHelper";
import {VerticalBox} from "ui/VerticalBox";
import {EmptyPane} from "ui/EmptyPane";
import {TableHeader} from "ui/TableHeader";

export interface PlayerTableProps {
	id: number;
	season?: number;
	sortFunc: PlayerStatsSortF;
	columnModes: ObservableMap<number, number>;
	sortFuncs: Map<string, PlayerStatsSortF>;
	setSortFunc: (sortF: PlayerStatsSortF) => void;
}

@observer
export class PlayerTable extends React.Component<PlayerTableProps> {
	constructor(props: PlayerTableProps)
	{
		super(props);
		makeObservable(this, {
			playerStats: computed
		})
	}

	get playerStats(): PlayerStatsWrapper[]
	{
		const statsBySeason = APIModel.singleton.playerStatsById.get(this.props.id);
		if (this.props.season != null && statsBySeason)
		{
			const stats = statsBySeason.get(this.props.season);
			if (stats)
			{
				const baseRows = stats
					.map((row) => new PlayerStatsWrapper(row))
					.sort((a,b) => a.week-b.week)
					.sort(this.props.sortFunc);
				const add = (x: number, y: number) => x+y;
				
				const total = APIModel.singleton.playerSummariesById.get(this.props.id)?.get(this.props.season);
				if (total)
				{
					const wrappedTotal = new PlayerStatsWrapper(total);
					runInAction(() => wrappedTotal.row.tossups_heard = 20*wrappedTotal.games);
					baseRows.push(wrappedTotal);
				}
				return baseRows;
			}
		}
		return [];
	}

	static get headers(): string[]
	{
		return ["Week", "Division", "Opponent", "TUH", "15", "10", "-5", "PPG"];
	}

	render() {
		return <VerticalBox>
			<h5 className="Player-Table-Title">Match History</h5>
			{this.playerStats.length
				?
				<table className="table">
					<TableHeader {...this.props} headers={PlayerTable.headers}/>
					<tbody>
						{this.playerStats.map(this.renderPlayerRow)}
					</tbody>
				</table>
				: <EmptyPane/>
			}
		</VerticalBox>;
	}

	readonly renderPlayerRow = (row: PlayerStatsWrapper, index: number): React.ReactNode => {
		//render interior of cells manually to allow custom rendering
		const interior: React.ReactNode[] = index === this.playerStats.length -1
			? [
				"Total",
				"",
				"",
				row.tuh,
				row.powers,
				row.tens,
				row.negs,
				row.ppg,
			].map((contents, i) => <strong key={i}>{contents}</strong>)
			: [
				row.week,
				row.division(this.props.season),
				row.opponent_id
					? <Link to={`/teams/id=${row.opponent_id}`}>
						{row.opponentname}
					</Link>
					: row.opponentname,
				row.tuh,
				row.powers,
				row.tens,
				row.negs,
				row.ppg.toString().slice(0,5),
			];
		return <tr key={index} className="Player-Table-Row">
			{PlayerTable.headers.map((header, i) => 
				<td className={`Table-Cell Table-Cell-${header}-Column Table-Cell-Row-${index.toString()}`} key={i}>
					{interior[i]}
				</td>
			)}
		</tr>;
	}
}