export enum GameLocation {
	HOME = "home",
	AWAY = "away"
}

export enum GameResult {
	WIN = "W",
	LOSS = "L",
	UNPLAYED = ""
}

export interface ScheduleRow {
	game_id: string;
	week: number;
	location: GameLocation;
	opponent: {id: number, name: string};
	result?: GameResult
}

export interface TeamScheduleRow {
	team_id: number;
	teamname?: string;
	row: ScheduleRow;
}

export interface GameInfo {
	home: {name: string; id: number};
	away: {name: string; id: number};
	week: number;
	result: GameResult;
}