import {LoginHelper} from "util/LoginHelper";
import {str} from "util/TextHelper";
import {SeasonHelper} from "util/SeasonHelper";

export interface ButtonProps {
	label: React.ReactNode;
	href: string;
}

export class NavigationHelper {
	//navigation to these panes still exists, but the main buttons do not
	private static unusedButtons: ButtonProps[] = [{
		label: str("PLAYERS_PAGE_BUTTON"),
		href: "/players"
	}, {
		label: "Conversion",
		href: "/conversion"
	}];

	static get buttons(): ButtonProps[]
	{
		const buttons = [{
			label: str("HOME_PAGE_BUTTON"),
			href: "/"
		}, {
			label: str("QUESTIONS_PAGE_BUTTON"),
			href: "/questions"
		}, {
			label: str("SCORER_PAGE_BUTTON"),
			href: "/scorer"
		}, {
			label: str("SCHEDULE_PAGE_BUTTON"),
			href: "/schedule"
		}, {
			label: str("TEAMS_PAGE_BUTTON"),
			href: "/teams"
		}, {
			label: str("STATS_PAGE_BUTTON"),
			href: "/stats"
		}, {
			label: str("FAQ_PAGE_BUTTON"),
			href: "/faq"
		}];
		if (SeasonHelper.registrationOpen)
		{
			buttons.push({
				label: str("REGISTER_PAGE_BUTTON"),
				href: "/register"
			})
		}
		if (LoginHelper.singleton.loggedIn)
		{
			//Gate the existence of these buttons on whether one is logged in or not
			buttons.push({
				label: str("USER_PAGE_BUTTON"),
				href: "/user"
			});
		}
		return buttons;
	}
}