import {makeAutoObservable} from "mobx";
import firebase from "firebase/app";
import "firebase/auth";

export class LoginHelper {
	constructor() {
		makeAutoObservable(this);
	}

	private static _singleton?: LoginHelper;
	static get singleton(): LoginHelper
	{
		if (!LoginHelper._singleton)
		{
			LoginHelper._singleton = new LoginHelper();
		}
		return LoginHelper._singleton;
	}

	// For Firebase JS SDK v7.20.0 and later, measurementId is optional
	private readonly _firebaseConfig = {
	  apiKey: "AIzaSyD5rBtEytv9Q8LPWoveevFBgPhjuein9Vk",
	  authDomain: "qb-league.firebaseapp.com",
	  projectId: "qb-league",
	  storageBucket: "qb-league.appspot.com",
	  messagingSenderId: "751781995983",
	  appId: "1:751781995983:web:9c4c5ba5b2677f694f9eba",
	  measurementId: "G-R69NH3TBGJ"
	};

	private _initialized: boolean = false;
	get initialized(): boolean
	{
		return this._initialized;
	}

	init(): void
	{
		this._initialized = true;
		firebase.initializeApp(this._firebaseConfig);
	}

	readonly uiConfig = {
		signInOptions: [{
			provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
			requireDisplayName: true
		}],
		signInSuccessUrl: "/"
	};

	get auth()
	{
		return this.initialized ? firebase.auth() : undefined;
	}

	get loggedIn(): boolean {return this._user != null}

	_userSet: boolean = false;
	_user?: firebase.User;

	logOut(): void
	{
		this.auth?.signOut();
		window.location.reload();
	}

	get displayName(): string
	{
		return this._user?.displayName ?? "A User";
	}

	get uid(): string
	{
		return this._user?.uid ?? "";
	}
}