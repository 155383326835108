import {action, makeAutoObservable} from "mobx";
import {APIModel} from "util/APIModel";
import {LoginHelper} from "util/LoginHelper";
import {PlayerInfo} from "dto/PlayerInfo";
import {TeamInfo} from "dto/TeamInfo";
import {AccountInfo, PlayerRole} from "dto/AccountInfo";
import firebase from "firebase/app";
import "firebase/firestore";

export class UserModel {
	constructor()
	{
		makeAutoObservable(this);
	}

	private static _singleton?: UserModel;
	static get singleton(): UserModel
	{
		if (!UserModel._singleton)
		{
			UserModel._singleton = new UserModel();
		}
		return UserModel._singleton;
	}

	_userInfo?: AccountInfo;
	get userInfo(): AccountInfo | undefined
	{
		return this._userInfo;
		// return {user_id: "gXeVSBhGp6W5O9cnfMJomQqYUo92", player_id: 331, is_captain: 1, team_id: "team59"} as AccountInfo;
		// return {user_id: "sTSDLArQSRWCq3eupfZuX9Op0D82", player_id: 47, is_captain: 1, team_id: "team10"} as AccountInfo;
		// return {user_id: "fakeuser", player_id: 0, is_captain: 1, team_id: "team0"} as AccountInfo;
	}

	_userLoaded: boolean = false;
	async refreshUser(): Promise<void>
	{
		const uid = LoginHelper.singleton.uid;
		if (uid)
		{
			firebase.firestore().collection("users").doc(uid).get()
				.then(action((doc) => {
					this._userLoaded = true;
					if (doc.exists) {
						this._userInfo = doc.data() as AccountInfo;
					}
				}))
				.catch((e) => console.log(e));
		}
	}

	static async validateUser(user_id: string, player_id: number, is_captain: number): Promise<boolean>
	{
		return firebase.firestore().collection("users")
			.where("user_id", "==", user_id)
			.where("player_id", "==", ""+player_id)
			.where("is_captain", "==", is_captain).get()
			.then((doc) => !doc.empty)
	}

	get isCaptain(): boolean
	{
		return this.userInfo?.is_captain === 1;
	}

	get currentTeam(): TeamInfo | undefined
	{
		return this.currentPlayer?.id != null
			//@ts-ignore
			? APIModel.singleton.teamsByPlayerId.get(""+this.currentPlayer.id)
			: undefined;
	}

	get currentPlayer(): PlayerInfo | undefined
	{
		return this.userInfo?.player_id != null
			//@ts-ignore
			? APIModel.singleton.playersById.get(""+this.userInfo.player_id)
			: undefined;
	}
}