import * as React from "react";
import {makeAutoObservable} from "mobx";
import {PlayerStatsWrapper} from "util/StatsRowWrapper";
import {RegexHelper} from "util/RegexHelper";
import {APIModel} from "util/APIModel";
import {SeasonHelper} from "util/SeasonHelper";
import {PlayerInfo} from "dto/PlayerInfo";
import {TeamInfo} from "dto/TeamInfo";
import {PlayerStatsInfo} from "dto/StatsInfo";

export class PlayerWrapper {
	private _row: PlayerInfo;

	constructor(row: PlayerInfo)
	{
		this._row = row;
		makeAutoObservable(this);		
	}
	get row(): PlayerInfo {return this._row}
	division(season: number = SeasonHelper.currentSeason): string {
		const seasonIndex = this.team?.seasons?.indexOf(season);
		if (seasonIndex != undefined && seasonIndex >= 0)
			return this.team?.divisions?.[seasonIndex].toString() ?? ""
		return "";
	}
	prettyDivision(season: number = SeasonHelper.currentSeason): string {return this.division(season) ?? "TBD"}
	get fname(): string {return this.row.fname}
	get lname(): string {return this.row.lname}
	get full_name(): string {return this.row.full_name ?? (this.row.fname + " " + this.row.lname)}
	get id(): number | undefined {return this.row.id}
	get team(): TeamInfo | undefined {return this.id ? APIModel.singleton.teamsByPlayerId.get(this.id) : undefined}
	get teamname(): string {return this.team?.name ?? ""}

	get stats(): PlayerStatsWrapper | undefined {
		return this.id != null && APIModel.singleton.playerSummariesById.has(this.id) && SeasonHelper.currentSeason
			? new PlayerStatsWrapper(APIModel.singleton.playerSummariesById.get(this.id)!.get(SeasonHelper.currentSeason)!)
			: undefined;
	}
	get games(): number {return this.stats?.games ?? 0}
	get powers(): number {return this.stats?.powers ?? 0}
	get tens(): number {return this.stats?.tens ?? 0}
	get negs(): number {return this.stats?.negs ?? 0}
	get ppg(): number {return this.stats?.ppg ?? 0}
	get tuh(): number {return this.stats?.tuh ?? 0}

	prettyDivisionFilter(filterText: string, season: number = SeasonHelper.currentSeason): React.ReactNode {return RegexHelper.markFiltered(this.prettyDivision(season), filterText)};
	fnameFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.fname, filterText)}
	lnameFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.lname, filterText)}
	full_nameFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.full_name, filterText)}
	teamnameFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.teamname, filterText)}
	gamesFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.games, filterText)}
	powersFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.powers, filterText)}
	tensFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.tens, filterText)}
	negsFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.negs, filterText)}
	ppgFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.ppg, filterText)}
	tuhFilter(filterText: string): React.ReactNode {return RegexHelper.markFiltered(this.tuh, filterText)}

	static filterF(filterText: string, season: number = SeasonHelper.currentSeason): (wrapper: PlayerWrapper) => boolean
	{
		const regExp = RegexHelper.niceRegExp(filterText);
		return (wrapper: PlayerWrapper) => {
			for (let field in wrapper.row)
			{
				if (field !== "id" && field !== "division"
					&& wrapper.row[field as keyof PlayerInfo]
					&& regExp.test(wrapper.row[field as keyof PlayerInfo]!.toString()))
				{
					return true;
				}
			}
			if (regExp.test(wrapper.prettyDivision(season))) return true;
			if (regExp.test(wrapper.teamname)) return true;
			return false;
		};
	}
}