import * as React from "react";
import {action, computed, IReactionDisposer, makeObservable, observable, ObservableMap, reaction, runInAction, when} from "mobx";
import {observer} from "mobx-react";
import {Link, Redirect} from "react-router-dom";
import Collapsible from "react-collapsible";
import {HorizontalBox} from "ui/HorizontalBox";
import {VerticalBox} from "ui/VerticalBox";
import {LoadingPane} from "ui/LoadingPane";
import {PlayerTable} from "ui/PlayerTable";
import {ScheduleTable} from "ui/ScheduleTable";
import {TableHeader} from "ui/TableHeader";
import {EmptyPane} from "ui/EmptyPane";
import {str} from "util/TextHelper";
import {LoginHelper} from "util/LoginHelper";
import {APICaller} from "util/APICaller";
import {APIModel} from "util/APIModel";
import {UserModel} from "util/UserModel";
import {StateMachine} from "util/StateMachine";
import {TeamScheduleRowWrapper} from "util/TeamScheduleRowWrapper";
import {SeasonHelper} from "util/SeasonHelper";
import {PlayerStatsWrapper} from "util/StatsRowWrapper";
import {PlayerInfo} from "dto/PlayerInfo";
import {TeamInfo} from "dto/TeamInfo";
import {PlayerRole} from "dto/AccountInfo";
import {GameLocation} from "dto/ScheduleRow";

@observer
export class UserPane extends React.Component {
	_destructors: IReactionDisposer[] = [];
	constructor(props: {}) {
		super(props);
		makeObservable(this, {
			userTeam: computed,
			userPlayer: computed,
			isCaptain: computed,
			submitEnabled: computed,
			claimEnabled: computed,
			isDirty: computed,
			_currentPlayerIndex: observable,
			_playerIndices: observable,
			_initialTeam: observable,
			_currentTeam: observable,
			_currentPlayer: observable,
			_confirm: observable,
			_teamName: observable,
			_rosterResponseMessage: observable,
			_claimResponseMessage: observable,
			_memberClaimResponseMessage: observable,
		});

		this._destructors.push(reaction(
			() => this.userTeam,
			action(() => {
				if (this.userTeam)
				{
					this._teamName = this.userTeam.name;
					this._initialTeam = this.userTeam.players;
					this._currentPlayerIndex = this._initialTeam.length - 1;
					this._playerIndices = this._initialTeam.map((_, i) => i);
					this._firstNames.replace(this._initialTeam.map((player, i) => [i, player.fname]) as [number, string][]);
					this._lastNames.replace(this._initialTeam.map((player, i) => [i, player.lname]) as [number, string][]);
					this._confirmDeletion = new ObservableMap(this._initialTeam.map((_, i) => [i, false]) as [number, boolean][]);
				}
			})
		));
		this._destructors.push(reaction(
			() => this.userPlayer,
			action(() => {
				if (this.userPlayer?.id)
				{
					APIModel.singleton.refreshPlayerCareerStats(this.userPlayer.id);
					APIModel.singleton.refreshPlayerStats(this.userPlayer.id);
				}
			})
		));
		this._destructors.push(reaction(
			() => this.isDirty,
			action(() => this._rosterResponseMessage = undefined)
		));
		this._destructors.push(reaction(
			() => LoginHelper.singleton.uid,
			action(() => UserModel.singleton.refreshUser()),
			{fireImmediately: true}
		));

		when(() => this.userTeam != null, () => APIModel.singleton.refreshSchedule(this.userTeam!.id!));
	}

	componentDidMount() {
		APIModel.singleton.refreshTeams();
		APIModel.singleton.refreshPlayers();
	}

	componentWillUnmount() {
		this._destructors.map((destructor) => destructor());
	}

	get userTeam(): TeamInfo | undefined
	{
		return UserModel.singleton.currentTeam;
	}
	get userPlayer(): PlayerInfo | undefined
	{
		return UserModel.singleton.currentPlayer;
	}

	render() {
		return LoginHelper.singleton.loggedIn === true
			? this.renderLoggedIn
			: !LoginHelper.singleton._userSet
				? <LoadingPane/>
				: <Redirect to="/login"/>
	}

	get renderLoggedIn(): React.ReactNode
	{
		return <VerticalBox spaced className="container-fluid">
			<h2>{LoginHelper.singleton.displayName}</h2>
			{UserModel.singleton._userLoaded
				? this.userTeam == null
					? this.renderTeamUnaffiliated()
					: this.renderTeamAffiliated()				
				: <LoadingPane/>
			}
		</VerticalBox>;
	}

	get isCaptain(): boolean
	{
		return UserModel.singleton.isCaptain;
	}

	get statRows(): PlayerStatsWrapper[]
	{
		const rows: PlayerStatsWrapper[] = [];
		if (this.userPlayer?.id)
		{

			const statsBySeason = APIModel.singleton.playerStatsById.get(this.userPlayer.id);
			if (statsBySeason)
			{
				const stats = statsBySeason.get(SeasonHelper.currentSeason);
				if (stats)
				{
					rows.push(...stats
						.map((row) => new PlayerStatsWrapper(row))
						.sort((a,b) => a.week-b.week)
						.sort(StateMachine.singleton._userPaneSortFunc));
				}
			}

			const seasonSummaries: PlayerStatsWrapper[] = [];
			//@ts-ignore
			const stats: PlayerStatsInfo[] = APIModel.singleton.statsSummaryForPlayer(this.userPlayer.id);
			if (stats)
			{
				stats.forEach((summary, index) => {
					const wrapper = new PlayerStatsWrapper(summary);
					const season = APIModel.singleton.playerSeasonsPlayed(this.userPlayer!.id!)[index];
					wrapper.row.season = season;
					seasonSummaries.push(wrapper);
					if (season === SeasonHelper.currentSeason) rows.push(wrapper);
				});
			}

			const add = (x: number, y: number) => x+y;
			const total = new PlayerStatsWrapper({
				player: this.userPlayer ? (this.userPlayer.fname+" "+this.userPlayer.lname) : "",
				team: "", //intentionally invalid teamname
				tossups_heard: (seasonSummaries.length && seasonSummaries.map((row) => row.tuh).reduce(add)) ?? 0,
				games_played: (seasonSummaries.length && seasonSummaries.map((row) => row.games).reduce(add)) ?? 0,
				powers: (seasonSummaries.length && seasonSummaries.map((row) => row.powers).reduce(add)) ?? 0,
				tens: (seasonSummaries.length && seasonSummaries.map((row) => row.tens).reduce(add)) ?? 0,
				negs: (seasonSummaries.length && seasonSummaries.map((row) => row.negs).reduce(add)) ?? 0
			});
			total.row.ppg = total.games > 0 ? (15*total.powers + 10*total.tens - 5*total.negs)/total.games : 0;
			rows.push(total);

			return rows;
		}
		return [];
	}
	get seasonHeaders(): string[]
	{
		return ["Week", "Division", "Opponent", "TUH", "15", "10", "-5", "PPG"];
	}

	renderTeamAffiliated(): React.ReactNode
	{
		if (this.userTeam == null) return null;

		return <VerticalBox>
			<div className="Text-Wrap">
				{str("MEMBER_OF_TEAM_PART_1")}
				{this.isCaptain ? str("CAPTAIN_LABEL") : str("MEMBER_LABEL")}
				{str("MEMBER_OF_TEAM_PART_2")}
				<Link to={`/teams/id=${this.userTeam.id}`}>
					{this.userTeam.name}
				</Link>
				{str("MEMBER_OF_TEAM_PART_3")}
			</div>
			<Collapsible trigger={"Schedule"} open>
				{SeasonHelper.initialized
					? SeasonHelper.currentWeek > 0
						? <ScheduleTable
							sortFunc={StateMachine.singleton._teamScheduleSortFunc}
							columnModes={StateMachine.singleton.teamScheduleColumnModes}
							sortFuncs={StateMachine.scheduleColumnSortFuncs}
							setSortFunc={StateMachine.singleton.setTeamScheduleSortFunc}
							rows={StateMachine.singleton.teamScheduleRows}
							hideTitle suppressHomeLink/>
						: str("SEASON_NOT_STARTED_MESSAGE")
					: <LoadingPane/>
				}
			</Collapsible>
			{this.userPlayer?.id != null &&
				<Collapsible trigger={"Stats"} open>
					{this.statRows.length
						? <table className="table">
							<TableHeader
								headers={this.seasonHeaders}
								columnModes={StateMachine.singleton.userPaneColumnModes}
								sortFuncs={StateMachine.playerStatsColumnSortFuncs}
								setSortFunc={StateMachine.singleton.setUserPaneSortFunc}/>
							<tbody>
								{this.statRows.map(this.renderSeasonRow)}
							</tbody>
						</table>
						: <EmptyPane/>
					}
				</Collapsible>
			}
			{this.isCaptain
				? <React.Fragment>
					<Collapsible trigger={"Contact info"}>{this.renderContactInfoSection}</Collapsible>
					<Collapsible trigger={"Edit team"}>{this.renderEditTeamSection}</Collapsible>
				</React.Fragment>
				: this._memberClaimResponseMessage != null 
					? <div className="Text-Wrap">{this._memberClaimResponseMessage}</div>
					: <HorizontalBox className="Text-Wrap row">
						<button type="button" onClick={this.onPetitionCaptain} className="col-lg-2 col-md-4 col-sm-6 col-xs-10">
							{str("CLAIM_CAPTAIN_LABEL")}
						</button>
						<VerticalBox className="col-lg-10 col-md-8 col-sm-6 col-xs-10">
							<div>{str("JOIN_TEAM_P3")}</div>
							<div>{str("JOIN_TEAM_P3.5")}</div>
						</VerticalBox>
					</HorizontalBox>
			}
		</VerticalBox>;
	}

	private readonly renderSeasonRow = (row: PlayerStatsWrapper, index: number) => {
		//render interior of cells manually to allow custom rendering
		let interior: React.ReactNode[];
		switch(index)
		{
			case this.statRows.length -2:
				interior = [
					"Current Season",
					row.division(),
					"-",
					"-",
					row.powers,
					row.tens,
					row.negs,
					row.ppg.toString().slice(0,5),
				].map((contents, i) => <strong key={i}>{contents}</strong>)
				break;
			case this.statRows.length -1:
				interior = [
					"All Seasons",
					"-",
					"-",
					"-",
					row.powers,
					row.tens,
					row.negs,
					row.ppg.toString().slice(0,5),
				].map((contents, i) => <strong key={i}>{contents}</strong>)
				break;
			default:
				interior = [
					row.week,
					row.division(),
					row.opponent_id
						? <Link to={`/teams/id=${row.opponent_id}`}>
							{row.opponentname}
						</Link>
						: row.opponentname,
					row.tuh,
					row.powers,
					row.tens,
					row.negs,
					row.ppg.toString().slice(0,5),
				];
				break;
		}

		return <tr key={index} className="Player-Table-Row">
			{PlayerTable.headers.map((header, i) => 
				<td className={`Table-Cell Table-Cell-${header}-Column Table-Cell-Row-${index.toString()}`} key={i}>
					{interior[i]}
				</td>
			)}
		</tr>;
	}

	_memberClaimResponseMessage?: React.ReactNode;
	private readonly onPetitionCaptain = async () => {
		if (!this._claimClicked) //prevent the player from accidentally spamming the submit button
		{
			this._claimClicked = true;
			if (this.userPlayer && this.userPlayer.id)
			{
				const success = await APICaller.makeClaim({
					user_id: LoginHelper.singleton.uid,
					player_id: this.userPlayer.id,
					player_role: PlayerRole.CAPTAIN
				});

				if (success)
				{
					runInAction(() => this._memberClaimResponseMessage = str("PLAYER_CLAIM_SUCCESS_PART_1") + (this.userPlayer?.fname + " " + this.userPlayer?.lname) + str("CAPTAIN_CLAIM_SUCCESS_PART_2") + this.userTeam?.name + str("PLAYER_CLAIM_SUCCESS_PART_3"));
				} else {
					runInAction(() => this._memberClaimResponseMessage = <div dangerouslySetInnerHTML={{__html: str("TEAM_CLAIM_FAILURE")}}/>);
				}
			}
			this._claimClicked = false;
		}
	};

	get renderContactInfoSection() {
		if (this.userTeam?.id != null && APIModel.singleton.teamsLoaded)
		{
			const rows = APIModel.singleton.teamIdsToSchedule.get(this.userTeam.id)
				?.map((row) => new TeamScheduleRowWrapper(row));
			if (rows?.length)
			{
				return <VerticalBox>
					<h5>{"You are in division "+rows[0].division()}</h5>
					<table><thead>
						<tr><th>Team</th><th>Email</th></tr>
						</thead><tbody>
						{rows.map((row,i) =>
							<tr key={i}><td>{row.opponentName}</td><td>{row.otherTeam?.email}</td></tr>)}
					</tbody></table>
				</VerticalBox>;
			}
			else
			{
				return str("SEASON_NOT_STARTED_MESSAGE");
			}
		}
		return null;
	}

	_initialTeam?: PlayerInfo[];

	_rosterResponseMessage?: React.ReactNode;

	_teamName?: string = this.userTeam?.name;

	_currentPlayerIndex: number = 0;
	_playerIndices?: number[];
	_firstNames: ObservableMap<number, string> = new ObservableMap();
	_lastNames: ObservableMap<number, string> = new ObservableMap();
	_confirmDeletion: ObservableMap<number, boolean> = new ObservableMap();

	_confirm: boolean = false;

	get removeEnabled(): boolean
	{
		return this._playerIndices != null ? this._playerIndices.length > 1 : false;
	}
	get isDirty(): boolean
	{
		if (this._initialTeam == null || this._playerIndices == null)
		{
			return false;
		}
		if (this._teamName?.trim() !== this.userTeam?.name)
		{
			return true;
		}
		if (this._initialTeam?.length !== this._playerIndices.length) //abs(#additions - #deletions) > 0
		{
			return true;
		}
		 //abs(#additions - #deletions) = 0 && edits >= 0
		const union = new Set([...this._initialTeam.map((_, i) => i),...this._playerIndices]);
		if (union.size > this._initialTeam.length) //#additions = #deletions > 0
		{
			return true;
		}
		//edits >= 0
		for (let index = 0; index < this._initialTeam.length; index++) {
			if (this._firstNames.get(index)?.trim() !== this._initialTeam[index].fname
				|| this._lastNames.get(index)?.trim() !== this._initialTeam[index].lname)
			{
				return true;
			}
		}
		//edits = 0
		return false;
	}
	get isValid(): boolean
	{
		return this._playerIndices != null && this._playerIndices.length > 0
			&& this._playerIndices.every((index) => this.getPlayerNamePretty(index).length > 2);
	}
	get submitEnabled(): boolean
	{
		return this.isDirty && this._confirm && this.isValid;
	}

	get renderEditTeamSection(): React.ReactNode
	{
		return <form onSubmit={this.onUpdateTeam}>
			{this._rosterResponseMessage && <div className="User-Pane-Section Text-Wrap">{this._rosterResponseMessage}</div>}
			<HorizontalBox className="row">
				<label className="col-lg-2 col-md-3 col-sm-4 col-xs-6" htmlFor="teamName">Team name:</label>
				<input className="col-lg-9 col-md-8 col-sm-7 col-xs-6"
					type="text"
					id="teamName"
					value={this._teamName}
					onChange={this.onChangeTeamName}/>
			</HorizontalBox>
			<VerticalBox className="Register-Pane-Row-Section">
				<table className="table">
					<thead>
						<tr>
							<th>{str("FIRST_NAME_LABEL")}</th>
							<th>{str("LAST_NAME_LABEL")}</th>
						</tr>
					</thead>
					<tbody>
						{this._playerIndices?.map((player, i) =>
							<tr className={""} key={i}>
								<td><input
									className="User-Pane-Row-Input"
									type="text"
									value={this._firstNames?.get(player) ?? ""}
									onChange={this.createFirstNameOnChange(player)}/></td>
								<td><input
									className="User-Pane-Row-Input"
									type="text"
									value={this._lastNames?.get(player) ?? ""}
									onChange={this.createLastNameOnChange(player)}/></td>
								<td><HorizontalBox spaced>
									<input type="checkbox"
										id={`confirmDelete${i}`}
										disabled={!this.removeEnabled}
										checked={this._confirmDeletion.get(player) ?? false}
										onChange={this.createDisableDeleteOnChange(player)}/>
									<button type="button"
										disabled={!this._confirmDeletion.get(player) || !this.removeEnabled}
										onClick={this.createOnRemoveIndex(i)}>
										{str("REMOVE_PLAYER_LABEL")}
									</button>
								</HorizontalBox></td>
							</tr>)
						}
					</tbody>
				</table>
				<button className="Register-Pane-Add-Button" type="button" onClick={this.onAddRosterMember}>
					{str("ADD_PLAYER_LABEL")}
				</button>
				{this.renderChanges()}
				<HorizontalBox spaced className="Margin-Auto">
					<input type="checkbox" id="confirm" checked={this._confirm} onChange={this.onChangeConfirm}/>
					<label htmlFor="confirm">{str("CONFIRM_LABEL")}</label>
				</HorizontalBox>
				<input type="submit" disabled={!this.submitEnabled}/>
			</VerticalBox>
		</form>;
	}

	readonly onChangeTeamName = action((e: React.ChangeEvent<HTMLInputElement>) => this._teamName = e.target.value);

	createOnRemoveIndex(index: number): (e: React.MouseEvent<HTMLButtonElement>) => void
	{
		return action((e: React.MouseEvent<HTMLButtonElement>) => {
			this._playerIndices?.splice(index, 1);
			this._confirmDeletion.set(index, false);
		});
	}
	createFirstNameOnChange(index: number): (e: React.ChangeEvent<HTMLInputElement>) => void
	{
		return action((e: React.ChangeEvent<HTMLInputElement>) => this._firstNames?.set(index, e.target.value));
	}
	createLastNameOnChange(index: number): (e: React.ChangeEvent<HTMLInputElement>) => void
	{
		return action((e: React.ChangeEvent<HTMLInputElement>) => this._lastNames?.set(index, e.target.value));
	}
	createDisableDeleteOnChange(index: number): (e: React.ChangeEvent<HTMLInputElement>) => void
	{
		return action((e: React.ChangeEvent<HTMLInputElement>) => this._confirmDeletion.set(index, e.target.checked));
	}

	readonly onAddRosterMember = action(() => {
		this._currentPlayerIndex++;
		this._playerIndices?.push(this._currentPlayerIndex);
	});

	readonly onChangeConfirm = action(() => this._confirm = !this._confirm);

	private _updateClicked = false;
	readonly onUpdateTeam = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		if (!this._updateClicked //prevent the player from accidentally spamming the submit button
			&& this.userTeam?.id != null && this._teamName && this._playerIndices && this._initialTeam) //ensure that all our ducks are in order
		{
			this._updateClicked = true;
			//if new player created
			//	we assume that all newly players are truly "new" i.e. that they do not exist in the database yet, so create them
			//	then write to a map that is undefined if the player already existed and the new player's object otherwise
			const newOrExistingPlayers = await Promise.all(this._playerIndices
				.map((index) => index >= this._initialTeam!.length
					? APICaller.createPlayer({
						fname: this._firstNames.get(index) ?? "",
						lname: this._lastNames.get(index) ?? "",
						team_id: this.userTeam?.id
					})
					: undefined
				)
			);

			//if player deleted
			//	then simply do not add it to the list of players

			//fill in team info
			//	combine the list of new players with the existing players
			const new_team: TeamInfo = {
				name: this._teamName,
				players: this._playerIndices.map((index) => {
					return {
						id: newOrExistingPlayers[index]?.id ?? this._initialTeam![index].id ?? 0,
						fname: this._firstNames.get(index) ?? "",
						lname: this._lastNames.get(index) ?? ""
					};
				})
			};

			//for every edited player, update the player entry
			const success = await Promise.all([...this._initialTeam.filter((player, i) =>
					player.id != null
					&& (this._firstNames.get(i)?.trim() !== this._initialTeam![i].fname
						|| this._lastNames.get(i)?.trim() !== this._initialTeam![i].lname))
				.map((player, i) => APICaller.updatePlayer(player.id!, {
					fname: this._firstNames.get(i) ?? "",
					lname: this._lastNames.get(i) ?? ""
				})),
				APICaller.updateTeam(this.userTeam.id, new_team)
			]);

			this._updateClicked = false;
			if (success.every((result) => result === true))
			{
				await APIModel.singleton.refreshTeams();
				runInAction(() => {
					this._confirm = false;
					this._rosterResponseMessage = <p dangerouslySetInnerHTML={{__html: str("ROSTER_UPDATE_SUCCESS")}}/>;
				});
			} else {
				runInAction(() => {
					this._confirm = false;
					this._rosterResponseMessage = <p dangerouslySetInnerHTML={{__html: str("ROSTER_UPDATE_FAILURE")}}/>;
				});
			}
		}
	}

	getPlayerNamePretty(index: number): string
	{
		return (this._firstNames.get(index) ?? "").trim() + " " + (this._lastNames.get(index) ?? "").trim();
	}

	renderChanges(): React.ReactNode
	{
		if (this._playerIndices == null || this._initialTeam == null)
		{
			return null;
		}

		const countnewOrExistingPlayers = this._playerIndices.filter((index) => index >= this._initialTeam!.length).length;

		const idToIndex = new Map<number, number>(this._initialTeam.map((player, i) => [player.id, i] as [number, number]));
		const countRemovedPlayers = this._initialTeam?.filter((_, i) => this._playerIndices!.indexOf(i) === -1).length;

		const countEditedPlayers = this._playerIndices.filter((index, i) =>
			index < this._initialTeam!.length && (
				this._firstNames.get(i)?.trim() !== this._initialTeam![i].fname
				|| this._lastNames.get(i)?.trim() !== this._initialTeam![i].lname)).length;

		return this.isDirty && <VerticalBox>
			<h4>Changes:</h4>
			<table className="table">
				<thead>
					<tr>
						<th></th>
						<th>{str("BEFORE_LABEL")}</th>
						<th>{str("AFTER_LABEL")}</th>
					</tr>
				</thead>
				<tbody>
					{this._teamName?.trim() !== this.userTeam?.name && <tr>
						<td>{str("TEAM_NAME_COLON")}</td>
						<td className="User-Pane-Before">{this.userTeam?.name}</td>
						<td className="User-Pane-After">{this._teamName}</td>
					</tr>}
					{countnewOrExistingPlayers > 0 && <tr><td rowSpan={countnewOrExistingPlayers+1}>New player</td></tr>}
					{this._playerIndices?.filter((index) => index >= this._initialTeam!.length)
						.map((index, i) => <tr key={i}>
							<td className="User-Pane-Before">[n/a]</td>
							<td className="User-Pane-After">{this.getPlayerNamePretty(index)}</td>
						</tr>)
					}
					{countRemovedPlayers > 0 && <tr><td rowSpan={countRemovedPlayers+1}>Removed player</td></tr>}
					{this._initialTeam.filter((_, i) => this._playerIndices?.indexOf(i) === -1)
						.map((player, i) => <tr key={i}>
							<td className="User-Pane-Before">{player.fname + " " + player.lname}</td>
							<td className="User-Pane-After">[deleted]</td>
						</tr>)
					}
					{countEditedPlayers > 0 && <tr><td rowSpan={countEditedPlayers+1}>Edited player</td></tr>}
					{this._playerIndices?.filter((index, i) => index < this._initialTeam!.length
						&& (this._firstNames.get(index)?.trim() !== this._initialTeam![index]?.fname
							|| this._lastNames.get(index)?.trim() !== this._initialTeam![index]?.lname))
						.map((index, i) => {
							const player = this._initialTeam![index];
							return <tr key={i}>
								<td className="User-Pane-Before">{player.fname + " " + player.lname}</td>
								<td className="User-Pane-After">{this.getPlayerNamePretty(index)}</td>
							</tr>;})
					}
				</tbody>
			</table>
			<VerticalBox>
				{countnewOrExistingPlayers > 0 && <div className="Text-Wrap" dangerouslySetInnerHTML={{__html: str("ADD_PLAYER_WARNING")}}/>}
				{countRemovedPlayers > 0 && <div className="Text-Wrap" dangerouslySetInnerHTML={{__html: str("DELETE_PLAYER_WARNING")}}/>}
			</VerticalBox>
			<VerticalBox className="Warning-Section">
				{!this.isValid && <strong className="Text-Wrap"
					dangerouslySetInnerHTML={{__html: str("PLAYER_EMPTY_WARNING")}}/>}
			</VerticalBox>

		</VerticalBox>
	}

	_currentTeam: number = -1;
	_currentPlayer?: number;

	get teams(): TeamInfo[]
	{
		return APIModel.singleton.teams;
	}
	get claimEnabled(): boolean
	{
		return this._currentTeam > 0 && this._currentPlayer != null;
	}

	_claimResponseMessage?: React.ReactNode;

	renderTeamUnaffiliated(): React.ReactNode
	{
		//it really doesn't the team id being a number, presumably because maps store everything as strings
		const teamAsString: string = "" + this._currentTeam;
		//@ts-ignore
		const players = APIModel.singleton.teamsById.get(teamAsString)?.players;

		return <VerticalBox spaced>
			<div className="Text-Wrap">{str("UNAFFILIATED_MESSAGE")}</div>
			<Collapsible trigger={str("JOIN_TEAM_LABEL")} open>
				{this._claimResponseMessage
					? <div className="Text-Wrap">{this._claimResponseMessage}</div>
					:
					<VerticalBox spaced className="Text-Wrap">
						<HorizontalBox spaced>
							<label htmlFor="teamSelect" className="User-Pane-Select-Label">{str("TEAM_COLON")}</label>
							<select id="teamSelect" className="User-Pane-Select" value={this._currentTeam} onChange={this.onChangeTeam}>
								<option value={-1}>{str("NO_TEAM_SELECTED_LABEL")}</option>
								{this.teams.map((team, i) => <option key={i} className="" value={team.id}>{team.name}</option>)}
							</select>
						</HorizontalBox>
						<HorizontalBox spaced>
							<label htmlFor="playerSelect" className="User-Pane-Select-Label">{str("PLAYER_COLON")}</label>
							<select id="playerSelect" className="User-Pane-Select" value={this._currentPlayer} onChange={this.onChangePlayer}>
									{this._currentTeam && (players?.length
										? players
											.map((player, i) => <option key={i} className="" value={i}>
												{player.fname + " " + player.lname}
											</option>)
										: <option>{str("NO_PLAYERS_LABEL")}</option>
									)}
							</select>
						</HorizontalBox>
						<HorizontalBox spaced>
							<button type="button" disabled={!this.claimEnabled} onClick={this.onClaimPlayer}>
								{str("CLAIM_PLAYER_LABEL")}
							</button>
							<button type="button" disabled={!this.claimEnabled} onClick={this.onClaimCaptain}>
								{str("CLAIM_CAPTAIN_LABEL")}
							</button>
						</HorizontalBox>
						<VerticalBox spaced>
							<div className="Text-Left">{str("JOIN_TEAM_P1")}</div>
							<div className="Text-Left">{str("JOIN_TEAM_P2")}</div>
							<div className="Text-Left">{str("JOIN_TEAM_P3")}</div>
						</VerticalBox>
					</VerticalBox>
				}
			</Collapsible>
		</VerticalBox>;
	}

	private readonly onChangeTeam = action((e: React.ChangeEvent<HTMLSelectElement>) => {
		this._currentTeam = Number.parseInt(e.target.value);
		this._currentPlayer = 0;
	});

	private readonly onChangePlayer = action((e: React.ChangeEvent<HTMLSelectElement>) =>
		this._currentPlayer = Number.parseInt(e.target.value));

	private _claimClicked = false;
	private readonly onClaimPlayer = async () => {
		if (!this._claimClicked //prevent the player from accidentally spamming the submit button
			&& this._currentTeam > 0 && this._currentPlayer != null)
		{
			this._claimClicked = true;
			//@ts-ignore
			const team = APIModel.singleton.teamsById.get(""+this._currentTeam);
			if (team)
			{
				const player = team.players[this._currentPlayer];
				if (player && player.id)
				{
					const success = await APICaller.makeClaim({
						user_id: LoginHelper.singleton.uid,
						player_id: player.id,
						player_role: PlayerRole.PLAYER
					});

					if (success)
					{
						runInAction(() => this._claimResponseMessage = str("PLAYER_CLAIM_SUCCESS_PART_1") + (player.fname + " " + player.lname) + str("PLAYER_CLAIM_SUCCESS_PART_2") + team.name + str("PLAYER_CLAIM_SUCCESS_PART_3"));
					} else {
						runInAction(() => this._claimResponseMessage = <div dangerouslySetInnerHTML={{__html: str("TEAM_CLAIM_FAILURE")}}/>);
					}
				}
			}
			this._claimClicked = false;
		}
	};
	private readonly onClaimCaptain = async () => {
		if (!this._claimClicked //prevent the player from accidentally spamming the submit button
			&& this._currentTeam > 0 && this._currentPlayer != null)
		{
			this._claimClicked = true;
			//@ts-ignore
			const team = APIModel.singleton.teamsById.get(""+this._currentTeam);
			if (team)
			{
				const player = team.players[this._currentPlayer];
				if (player && player.id)
				{
					const success = await APICaller.makeClaim({
						user_id: LoginHelper.singleton.uid,
						player_id: player.id,
						player_role: PlayerRole.CAPTAIN
					});

					if (success)
					{
						runInAction(() => this._claimResponseMessage = str("PLAYER_CLAIM_SUCCESS_PART_1") + (player.fname + " " + player.lname) + str("CAPTAIN_CLAIM_SUCCESS_PART_2") + team.name + str("PLAYER_CLAIM_SUCCESS_PART_3"));
					} else {
						runInAction(() => this._claimResponseMessage = <div dangerouslySetInnerHTML={{__html: str("TEAM_CLAIM_FAILURE")}}/>);
					}
				}
			}
			this._claimClicked = false;
		}
	};
}