import * as React from "react";
import {action, computed, makeObservable, observable, runInAction, when} from "mobx";
import {observer} from "mobx-react";
import Collapsible from "react-collapsible";
// import {ModaqControl} from "modaq";
import {VerticalBox} from "ui/VerticalBox";
import {HorizontalBox} from "ui/HorizontalBox";
import {LoadingPane} from "ui/LoadingPane";
import {QBJHelper} from "util/QBJHelper";
import {APICaller} from "util/APICaller";
import {APIModel} from "util/APIModel";
import {str} from "util/TextHelper";
import {SeasonHelper} from "util/SeasonHelper";
import ex1 from "ex1.png";
import ex2 from "ex2.png";
import ex3 from "ex3.png";
import ex4 from "ex4.png";
import ex5 from "ex5.png";
import ex6 from "ex6.png";

@observer
export class ScorerPane extends React.Component {
	fileRef: React.RefObject<HTMLInputElement>;
	constructor(props: {}) {
		super(props);
		this.fileRef = React.createRef();

		makeObservable(this, {
			_currentFile: observable,
			helper: observable,
			confirmedStats: observable,
			confirmedRosters: observable,
			selectedTeam: observable,
			selectedPlayers: observable,
			confirmRosterDisabled: computed
		});

		when(() => this._currentFile != null, () => this.helper = new QBJHelper(this._currentFile!));
	}

	componentDidMount() {
		APIModel.singleton.refreshSchedules();
	}

	private instructionLength = 14;
	private images: {[index: number]: React.ReactNode} = {5: <React.Fragment>
			<br/><div className='Margin-Auto Fit-Content'><img src={ex1}/></div>
		</React.Fragment>,
		6: <React.Fragment>
			<br/><div className='Margin-Auto Fit-Content'><img src={ex2}/></div>
		</React.Fragment>,
		7: <React.Fragment>
			<br/><div className='Margin-Auto Fit-Content'><img src={ex3}/></div>
		</React.Fragment>,
		8: <React.Fragment>
			<br/><div className='Margin-Auto Fit-Content'><img src={ex6}/></div>
		</React.Fragment>,
		10: <React.Fragment>
			<br/><div className='Margin-Auto Fit-Content'><img src={ex4}/></div>
			<br/><div className='Margin-Auto Fit-Content'><img src={ex5}/></div>
		</React.Fragment>
	};
	get instructions(): React.ReactNode {
		const output: React.ReactNode[] = [];
		for (let i = 1; i <= this.instructionLength; i++)
		{
			output.push(<div key={i}>
				<b>{`${i}. `}</b>
				<div className="Left-Pad" dangerouslySetInnerHTML={{__html: str(`SCORER_INSTRUCTIONS_${i}`)}}/>
				{this.images[i]}
			</div>);
		}
		return output;
	}
	_currentFile?: File;
	// <ModaqControl yappServiceUrl={"https://yetanotherpacketparserazurefunction.azurewebsites.net/api/ParseDocx"}/>
	render() {
		return <VerticalBox spaced className="container-fluid Text-Wrap">
			<h2>{str("SCORER_TITLE")}</h2>
			<p dangerouslySetInnerHTML={{__html: str("SCORER_NOTE_TO_SCOREKEEPERS")}}/>
			<div/>
			<VerticalBox className="Scorer-Pane-Header-Section">
				<HorizontalBox spaced className="Margin-Auto">
					<VerticalBox>
						<h5>{str("LINK_TO_SCORER")}</h5>
						<a className="Scorer-Pane-MODAQ-Link Margin-Auto" href="https://www.quizbowlreader.com/demo.html">MODAQ</a>
					</VerticalBox>
					<div/>
					<VerticalBox>
						<h5>{str("PROTEST_FORM")}</h5>
						<a className="Scorer-Pane-Protest-Link Margin-Auto" href="https://forms.gle/9iSbgPUa5pr1BAYG8">Form</a>
					</VerticalBox>
					<div/>
					<VerticalBox>
						<h5>{str("UPLOAD_RESULTS")}</h5>
						<div className="Scorer-Pane-File-Input Margin-Auto">
							<input
								className=""
								accept=".qbj,.json" type="file" ref={this.fileRef}
								onChange={this.onSetFile}
								disabled={!SeasonHelper.initialized}/>
						</div>
					</VerticalBox>
				</HorizontalBox>	
				{this._currentFile != null && this.renderConfirmationSection()}
			</VerticalBox>
			<div/>
			<Collapsible trigger={str("SCORER_INSTRUCTIONS_TITLE")}>
				<VerticalBox spaced className="Left-Pad Text-Left">
					{this.instructions}
				</VerticalBox>
			</Collapsible>
		</VerticalBox>;
	}

	private readonly onSetFile = action(() => this._currentFile = this.fileRef.current?.files?.[0]);

	helper?: QBJHelper;
	confirmedStats: boolean = false;
	confirmedRosters?: boolean;
	renderConfirmationSection() {
		if (this.helper)
		{
			return !this.confirmedStats
				? <VerticalBox>
					<HorizontalBox spaced className="Scorer-Pane-Confirmation-Section">
						{this.renderHalfQBJConfirmationSection(0)}
						{this.renderHalfQBJConfirmationSection(1)}
					</HorizontalBox>
					<HorizontalBox spaced className="Margin-Auto">
						<strong>{str("SCORER_STATS_CONFIRM_LABEL")}</strong>
						<button onClick={this.onConfirmQBJ}>{str("CONFIRM_LABEL")}</button>
					</HorizontalBox>
					<div>{str("SCORER_STATS_CONFIRM_INFO")}</div>
				</VerticalBox>
				: this.confirmedRosters == null
					? <VerticalBox spaced>
						<VerticalBox spaced>
							{this.renderHalfRosterConfirmationSection(0)}
							{this.renderHalfRosterConfirmationSection(1)}
						</VerticalBox>
						{this.renderConfirmRosterErrors()}
						<HorizontalBox spaced className="Margin-Auto">
							<div>{str("SCORER_ROSTER_CONFIRM_LABEL")}</div>
							<button disabled={this.confirmRosterDisabled} onClick={this.onConfirmRosters}>{str("CONFIRM_LABEL")}</button>
						</HorizontalBox>
					</VerticalBox>
					: this.confirmedRosters
						? <div>Stats successfully submitted.</div>
						: <div>A problem came up while submitting stats. Try submitting again, and if you continue to run into difficulties, contact us at <a href='mailto:quizbowlleague@gmail.com?subject=Error submitting stats'>quizbowlleague@gmail.com</a>; be sure to let us know which teams were playing and attach the .qbj file.</div>;
		}
	}

	private readonly onConfirmQBJ = action(() => {
		this.confirmedStats = true;
		this.selectedPlayers = this.helper!.teamNames.map((name) => this.helper!.teamPlayerNames[name].map(() => -1));
		APIModel.singleton.refreshTeams();
		APIModel.singleton.refreshPlayers();
	});

	get confirmRosterDisabled(): boolean
	{
		const playerIdSet: Set<number> = new Set(this.selectedPlayers.flat());
		const scheduleSet: Set<number> = new Set(APIModel.singleton.teamIdsToSchedule.get(this.selectedTeam[0])
			?.map((schedule) => schedule.row.opponent.id) ?? []);
		return this.selectedTeam.some((id) => id <= 0)
			|| this.selectedTeam[0] === this.selectedTeam[1]
			|| this.selectedPlayers.some((team) => !team.length || team.some((id) => id <= 0))
			|| playerIdSet.size !== this.selectedPlayers.flat().length
			|| !scheduleSet.has(this.selectedTeam[1]);
	}

	_confirmRosterClicked = false;
	private readonly onConfirmRosters = async () => {
		if (this.helper && !this._confirmRosterClicked)
		{
			this._confirmRosterClicked = true;
			const qbj = this.helper.formatForSaving(this.selectedTeam, this.selectedPlayers);
			if (qbj)
			{
				const response = await APICaller.submitStats(qbj);
				if (response)
				{
					runInAction(() => this.confirmedRosters = true);
				}
			}
			this._confirmRosterClicked = false;
		}
	};

	renderConfirmRosterErrors() {
		if (this.confirmRosterDisabled)
		{
			return this.selectedTeam.every((id) => id > 0) && (this.selectedTeam[0] === this.selectedTeam[1]
				? <div className="Text-Wrap">Teams cannot be the same</div>
				: !(new Set(APIModel.singleton.teamIdsToSchedule.get(this.selectedTeam[0])
			?.map((schedule) => schedule.row.opponent.id) ?? [])).has(this.selectedTeam[1])
					? <div>Are you sure these teams play each other?</div>
					: this.selectedPlayers.every((team) => team.length && team.every((id) => id > 0))
						&& new Set(this.selectedPlayers.flat()).size !== this.selectedPlayers.flat().length
						&& <div className="Text-Wrap">There cannot be multiple of the same player</div>)
			;
		}
	}

	renderHalfQBJConfirmationSection(teamIndex: number) {
		if (this.helper && teamIndex in this.helper.teamNames) {
			const teamName = this.helper.teamNames[teamIndex];
			const teamStats = this.helper.teamStats[teamName];
			return <VerticalBox spaced className="Scorer-Pane-Confirmation-Section-Half">
				<h5>{teamName}: {this.helper.teamScores[teamName]}</h5>
				<table><thead>
					<tr>
						<th>Player</th>
						<th>15</th>
						<th>10</th>
						<th>-5</th>
						<th>Tossup points</th>
					</tr>
				</thead><tbody>
					{Object.entries(this.helper.teamPlayerStats[teamName]).map(([playerName, stats], i) => <tr key={i}>
						<td>{playerName}</td>
						<td>{stats.powers}</td>
						<td>{stats.tens}</td>
						<td>{stats.negs}</td>
						<td>{15*stats.powers + 10*stats.tens - 5*stats.negs}</td>
					</tr>)}
					<tr>
						<th>{str("PLAYER_TOTAL_NAME")}</th>
						<th>{teamStats.powers}</th>
						<th>{teamStats.tens}</th>
						<th>{teamStats.negs}</th>
						<th>{15*teamStats.powers + 10*teamStats.tens - 5*teamStats.negs}</th>
					</tr>
				</tbody></table>
				<VerticalBox>
					<div>{str("SCORER_STATS_BONUS_POINTS")}{this.helper.teamBonuses[teamName]}</div>
					<div>{str("SCORER_STATS_PPB")}{this.helper.teamPPB[teamName].toString().slice(0,5)}</div>
				</VerticalBox>
			</VerticalBox>;
		}
	}

	selectedTeam: number[] = [-1, -1];
	selectedPlayers: number[][] = [[], []];
	renderHalfRosterConfirmationSection(teamIndex: number) {
		if (this.helper && teamIndex in this.helper.teamNames) {
			const teamName = this.helper.teamNames[teamIndex];
			const teamStats = this.helper.teamStats[teamName];

			return APIModel.singleton.teamsLoaded && APIModel.singleton.playersLoaded
				? <VerticalBox spaced className="Scorer-Pane-Confirmation-Section-Half">
					<HorizontalBox>
						<div className="Scorer-Pane-Team-Label">{teamName + ": "}</div>
						<select value={this.selectedTeam[teamIndex]} onChange={this.createTeamOnChange(teamIndex)}>
							<option value={-1}>{str("SCORER_ROSTER_NO_TEAM_SELECTED")}</option>
							{APIModel.singleton.teams.slice().sort((a,b) => a.name.localeCompare(b.name)) //slice() first since sort() is in-place
								.map((team, i) => <option key={i} value={team.id}>
									{team.name}
								</option>)}
						</select>
					</HorizontalBox>
					{this.selectedTeam[teamIndex] > 0 && <table>
						<thead><tr>
							<th>{str("SCORER_ROSTER_NAME_LABEL")}</th>
							<th>{str("SCORER_ROSTER_PLAYER_LABEL")}</th>
						</tr></thead>
						<tbody>
							{this.helper.teamPlayerNames[teamName].map((playerName, i) => <tr key={i}>
								<td>{playerName}</td>
								<td>
									<select value={this.selectedPlayers[teamIndex][i]} onChange={this.createPlayerOnChange(teamIndex, i)}>
										<option value={-1}>{str("SCORER_ROSTER_NO_TEAM_SELECTED")}</option>
										{//@ts-ignore
											APIModel.singleton.teamsById.get(this.selectedTeam[teamIndex].toString())?.players
												.map((player, ii) => <option key={ii} value={player.id}>
													{player.fname + " " + player.lname}
												</option>)
										}
									</select>
								</td>
							</tr>)}
						</tbody>
					</table>}
			</VerticalBox>
			: <LoadingPane/>;
		}
	}

	createTeamOnChange(teamIndex: number): (e: React.ChangeEvent<HTMLSelectElement>) => void
	{
		return action((e: React.ChangeEvent<HTMLSelectElement>) => {
			this.selectedTeam[teamIndex] = Number.parseInt(e.target.value);
			this.selectedPlayers[teamIndex] = this.selectedPlayers[teamIndex].map(() => -1);
		});
	}

	createPlayerOnChange(teamIndex: number, playerIndex: number): (e: React.ChangeEvent<HTMLSelectElement>) => void
	{
		return action((e: React.ChangeEvent<HTMLSelectElement>) => this.selectedPlayers[teamIndex][playerIndex] = Number.parseInt(e.target.value));
	}
}