import React from "react";
import {makeAutoObservable} from "mobx";
import {RegexHelper} from "util/RegexHelper";
import {APIModel} from "util/APIModel";
import {TossupConversionInfo, BonusConversionInfo, SummaryConversionInfo} from "dto/ConversionInfo";

export class TossupConversionWrapper {
	private _row: TossupConversionInfo;

	constructor(row: TossupConversionInfo)
	{
		this._row = row;
		makeAutoObservable(this);
	}

	get row(): TossupConversionInfo {return this._row}
	get season(): number {return this.row.season}
	get packet(): number {return this.row.packet}
	get tossup(): number {return this.row.tossup}
	get answer(): string {return this.row.answer}
	get powers(): number {return this.row.powers}
	get tens(): number {return this.row.tens}
	get negs(): number {return this.row.negs}
	get power_pct(): number {return this.row.power_pct}
	get conv_pct(): number {return this.row.conv_pct}
	get neg_pct(): number {return this.row.neg_pct}

	seasonFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.season, filterText)}
	packetFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.packet, filterText)}
	tossupFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.tossup, filterText)}
	answerFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.answer, filterText)}
	powersFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.powers, filterText)}
	tensFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.tens, filterText)}
	negsFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.negs, filterText)}
	power_pctFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.power_pct, filterText)}
	conv_pctFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.conv_pct, filterText)}
	neg_pctFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.neg_pct, filterText)}

	static filterF(filterText: string): (wrapper: TossupConversionWrapper) => boolean
	{
		const regExp = RegexHelper.niceRegExp(filterText);
		return (wrapper: TossupConversionWrapper) => {
			for (let field in wrapper.row)
			{
				if (wrapper.row[field as keyof TossupConversionInfo]
					&& regExp.test(wrapper.row[field as keyof TossupConversionInfo]!.toString()))
				{
					return true;
				}
			}
			return false;
		};
	}
}

export class BonusConversionWrapper {
	private _row: BonusConversionInfo;

	constructor(row: BonusConversionInfo)
	{
		this._row = row;
		makeAutoObservable(this);
	}

	get row(): BonusConversionInfo {return this._row}
	get season(): number {return this.row.season}
	get packet(): number {return this.row.packet}
	get bonus(): number {return this.row.bonus}
	get answers(): string {return this.row.answers}
	get part1_conv_pct(): number {return this.row.part1_conv_pct}
	get part2_conv_pct(): number {return this.row.part2_conv_pct}
	get part3_conv_pct(): number {return this.row.part3_conv_pct}
	get overall_conv_pct(): number {return (this.part1_conv_pct + this.part2_conv_pct + this.part3_conv_pct)/3}

	seasonFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.season, filterText)}
	packetFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.packet, filterText)}
	bonusFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.bonus, filterText)}
	answersFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.answers, filterText)}
	part1_conv_pctFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.part1_conv_pct, filterText)}
	part2_conv_pctFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.part2_conv_pct, filterText)}
	part3_conv_pctFilter(filterText:string): React.ReactNode {return RegexHelper.markFiltered(this.part3_conv_pct, filterText)}

	static filterF(filterText: string): (wrapper: BonusConversionWrapper) => boolean
	{
		const regExp = RegexHelper.niceRegExp(filterText);
		return (wrapper: BonusConversionWrapper) => {
			for (let field in wrapper.row)
			{
				if (wrapper.row[field as keyof BonusConversionInfo]
					&& regExp.test(wrapper.row[field as keyof BonusConversionInfo]!.toString()))
				{
					return true;
				}
			}
			return false;
		};
	}
}

export class SummaryConversionWrapper {
	private _row: SummaryConversionInfo;

	constructor(row: SummaryConversionInfo)
	{
		this._row = row;
		makeAutoObservable(this);
	}

	get row(): SummaryConversionInfo {return this._row}
	get season(): number {return this.row.season}
	get packet(): number {return this.row.packet}
	get powers(): number {return this.row.powers}
	get tens(): number {return this.row.tens}
	get negs(): number {return this.row.negs}
	get power_pct(): number {return this.row.power_pct}
	get conv_pct(): number {return this.row.conv_pct}
	get neg_pct(): number {return this.row.neg_pct}
	get overall_conv_pct(): number {return this.row.overall_conv_pct}
}