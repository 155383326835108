export enum PlayerRole {
	PLAYER = "player",
	CAPTAIN = "captain"
}

export interface AccountInfo {
	user_id: string;
	player_id: number;
	player_role?: PlayerRole;
	is_captain?: number;
}