import * as React from "react";
import {computed, IReactionDisposer, makeObservable, observable, ObservableMap, reaction, runInAction, when} from "mobx";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import Collapsible from "react-collapsible";
import {APICaller} from "util/APICaller";
import {APIModel} from "util/APIModel";
import {StateMachine} from "util/StateMachine";
import {TeamWrapper} from "util/TeamWrapper";
import {TextHelper, str} from "util/TextHelper";
import {PlayerStatsWrapper} from "util/StatsRowWrapper"
import {TeamScheduleRowWrapper} from "util/TeamScheduleRowWrapper";
import {SeasonHelper} from "util/SeasonHelper";
import {VerticalBox} from "ui/VerticalBox";
import {HorizontalBox} from "ui/HorizontalBox";
import {EmptyPane} from "ui/EmptyPane";
import {TableHeader} from "ui/TableHeader";
import {TeamTable} from "ui/TeamTable";
import {LoadingPane} from "ui/LoadingPane";
import {ScheduleTable} from "ui/ScheduleTable";
import {SeasonToggle} from "ui/SeasonToggle";
import {LocationProps} from "./../App";
import {TeamInfo} from "dto/TeamInfo"
import {PlayerStatsInfo} from "dto/StatsInfo";
import {TeamScheduleRow} from "dto/ScheduleRow";

@observer
export class TeamsPane extends React.Component<LocationProps> {
	private params: ObservableMap<string, string> = new ObservableMap();

	private static ID_FIELD = "id";

	disposers: IReactionDisposer[] = [];
	componentDidMount() {
		this.processProps();
		this.disposers.push(reaction(() => [SeasonHelper.currentSeason, SeasonHelper.currentWeek],
			() => {
				APIModel.singleton.refreshTeams();
				APIModel.singleton.refreshPlayers();
				APIModel.singleton.refreshTeamStatSummaries();
				APIModel.singleton.refreshPlayerStatSummaries();
		}, {fireImmediately: true}));
	}

	componentDidUpdate(prevProps: LocationProps)
	{
		if (this.props.path !== prevProps.path
			|| this.props.params !== prevProps.params)
		{
			this.processProps();
		}
	}

	componentWillUnmount()
	{
		this.disposers.map((disposer: IReactionDisposer) => disposer());
	}

	processProps(): void
	{
		//id: optional
		this.params.clear();
		const split = this.props.path.split("/");
		if (split.length > 2)
		{
			this.params.replace(split.slice(2).map((param) => {
				const [key, value] = param.split("=");
				console.log(`Found param '${key}' with value '${value}'`);
				return [key, value];
			}));
		}
	}

	render() {
		const id = this.params.get(TeamsPane.ID_FIELD);
    	return id
    		? <SpecificTeam id={Number.parseInt(id)} disposers={this.disposers}/>
    		: <TeamsOverview/>
    }
}

interface SpecificTeamProps {
	id: number;
	disposers: IReactionDisposer[];
}

@observer
class SpecificTeam extends React.Component<SpecificTeamProps> {
	constructor(props: SpecificTeamProps)
	{
		super(props);
		makeObservable(this, {
			_team: observable,
			team: computed,
			scheduleRows: computed,
			playerStats: computed,
			renderRecord: computed,
			renderLegend: computed
		});
	}

	_team?: TeamInfo;

	componentDidMount() {
		this.props.disposers.push(reaction(() => [this.props.id],
			async () => {
				if (this.props.id)
				{
					await this.fetchTeam();
					await when(() => SeasonHelper.initialized);
					// if (this.team?.seasons && this.team.seasons.indexOf(SeasonHelper.currentSeason) >= 0)
					// 	APIModel.singleton.refreshSchedule(this.props.id);
					APIModel.singleton.refreshTeamPlayerStats(this.props.id);
				}
		}, {fireImmediately: true}));
	}

	async fetchTeam(): Promise<void>
	{
		//@ts-ignore
		const team = APIModel.singleton.teamsById.get(""+this.props.id) || await APICaller.getTeam(this.props.id);
		runInAction(() => this._team = team);
	}

	get team(): TeamInfo | undefined
	{
		return this._team;
	}
	get playerColumnHeaders(): string[]
	{
		return ["Player", "G", "15", "10", "-5", "PPG"];
	}

	get season(): number
	{
		return this.team?.seasons?.[StateMachine.singleton.teamsSeasonIndex-1] ?? 0;
	}
	get scheduleRows(): TeamScheduleRowWrapper[] | undefined
	{
		const baseRows = APIModel.singleton.teamIdsToSchedule.get(this.props.id);
		if (!baseRows) return [];

		return baseRows
			.map((row) => new TeamScheduleRowWrapper(row))
			.sort((a, b) => a.week - b.week)
			.sort(StateMachine.singleton._teamsPaneScheduleSortFunc);
	}

	get playerStats(): PlayerStatsWrapper[]
	{
		const baseRows: PlayerStatsWrapper[] = [];
		const season = this.season;

		if (this.team?.players)
		{
			this.team.players.forEach((player) => {
				if (player.id)
				{
					let summary;
					if (StateMachine.singleton.teamsSeasonIndex === 0)
					{
						summary = {
							player: player.fname + " " + player.lname,
							player_id: player.id,
							team: this.team?.name ?? "",
							powers: 0,
							tens: 0,
							negs: 0,
							games_played: 0
						};
						for (let i = 1; i <= SeasonHelper.currentSeason; i++)
						{
							//@ts-ignore
							const seasonSummary = APIModel.singleton.playerSummariesById.get(Number.parseInt(player.id))?.get(i);
							if (seasonSummary)
							{
								summary.powers += seasonSummary.powers;
								summary.tens += seasonSummary.tens;
								summary.negs += seasonSummary.negs;
								summary.games_played += seasonSummary.games_played ?? 0;
							}
						}
					}
					else
					{
						//@ts-ignore
						summary = APIModel.singleton.playerSummariesById.get(Number.parseInt(player.id))?.get(season);
					}
					if (summary)
					{
						baseRows.push(new PlayerStatsWrapper(summary));
					} else {
						baseRows.push(new PlayerStatsWrapper({
							player: player.fname + " " + player.lname,
							player_id: player.id,
							team: this.team?.name ?? "",
							powers: 0,
							tens: 0,
							negs: 0
						}));
					}
				}
			});
		}

		const sortedRows = baseRows
			.filter((row) => row.games > 0)
			.sort(StateMachine.singleton._teamsPanePlayerSortFunc);

		//create a "total" row
		if (sortedRows.length)
		{
			let summary;
			if (season === 0)
			{
				summary = {
					player: "",
					team: this.team?.name ?? "",
					powers: 0,
					tens: 0,
					negs: 0,
					games_played: 0
				};
				for (let i = 1; i <= SeasonHelper.currentSeason; i++)
				{
					//@ts-ignore
					const seasonSummary = APIModel.singleton.teamSummariesById.get(this.props.id)?.get(i);
					if (seasonSummary)
					{
						summary.powers += seasonSummary.powers;
						summary.tens += seasonSummary.tens;
						summary.negs += seasonSummary.negs;
						summary.games_played += seasonSummary.games_played ?? 0;						
					}
				}
			}
			else
			{
				//@ts-ignore
				summary = APIModel.singleton.teamSummariesById.get(this.props.id)?.get(season);
			}
			if (summary)
			{
				const total = new PlayerStatsWrapper({
					...summary,
					player: "", //intentionally invalid name
					ppg: summary.games_played ? sortedRows.map((row) =>
						15*row.powers+10*row.tens-5*row.negs).reduce((x,y)=>x+y)/summary.games_played : 0
				});
				sortedRows.push(total);
			}
		}
		return sortedRows;
	}

	get prettyDivision(): string
	{
		if (this.team)
		{
			const seasonIndex = this.team.seasons?.indexOf(SeasonHelper.currentSeason);
			return (seasonIndex != undefined && seasonIndex >= 0)
				? this.team.divisions?.[seasonIndex].toString() ?? ""
				: "";
		}
		return "";
	}

	render() {
		return this.team
			? <VerticalBox className="container-fluid" spaced>
				<Link to="/teams" className="Teams-Pane-Specific-Back-Button">
					<h4>{`<- ${str("BACK_TO_OVERIEW_BUTTON")}`}</h4>
				</Link>
				<VerticalBox className="Teams-Pane-Header-Section">
					<h2 className="Teams-Pane-Specific-Title Text-Wrap">{this.team?.name}</h2>
					<HorizontalBox spaced>
						<strong>{str("RECORD_COLON")}</strong>
						{this.renderRecord}
					</HorizontalBox>
					<HorizontalBox spaced>
						<strong>{str("DIVISION_COLON")}</strong>
						<div title={this.prettyDivision}>{this.prettyDivision}</div>
					</HorizontalBox>
					<HorizontalBox spaced>
						<strong className={"Margin-Bottom"}>{str("ROSTER_COLON")}</strong>
						<HorizontalBox spaced className="Flex-Wrap">
							{this.team.players.map((player, i) =>
								<Link to={`/players/id=${player.id}`} key={i}>{player.fname + " " + player.lname}</Link>)}
						</HorizontalBox>
					</HorizontalBox>
				</VerticalBox>
				<div/>
				<VerticalBox>
					<Collapsible trigger={str("SCHEDULE_CURRENT_SEASON")} open>
						{!(this.team?.seasons && this.team.seasons.indexOf(SeasonHelper.currentSeason) >= 0)
							? "Team is not active this season"
							: this.scheduleRows
								?
								<ScheduleTable
									sortFunc={StateMachine.singleton._teamsPaneScheduleSortFunc}
									columnModes={StateMachine.singleton.teamsPaneScheduleColumnModes}
									sortFuncs={StateMachine.scheduleColumnSortFuncs}
									setSortFunc={StateMachine.singleton.setTeamsPaneScheduleSortFunc}
									rows={this.scheduleRows}
									hideTitle suppressHomeLink/>
								: <LoadingPane/>
						}
					</Collapsible>
					<Collapsible trigger={str("STATS_LABEL")} open>
						<VerticalBox spaced>
							<SeasonToggle overallEnabled
								selectedIndex={StateMachine.singleton.teamsSeasonIndex}
								onToggleIndex={this.onToggleSeason}
								subsetOfSeasons={this.team?.seasons}/>
							<div/>
							{this.renderLegend}
							{StateMachine.singleton.teamsSeasonIndex > 0 && 
								<TeamTable
									id={this.props.id}
									season={this.season}
									columnModes={StateMachine.singleton.teamsPaneTeamColumnModes}
									sortFuncs={StateMachine.teamStatsColumnSortFuncs}
									setSortFunc={StateMachine.singleton.setTeamsPaneTeamSortFunc}
									sortFunc={StateMachine.singleton._teamsPaneTeamSortFunc}/>
							}
							<VerticalBox>
								<h5 className="Teams-Pane-Table-Title">{str("TEAMS_PAGE_PLAYER_TITLE")}</h5>
								{this.playerStats.length
									?
									<table className="table">
										<TableHeader
											headers={this.playerColumnHeaders}
											columnModes={StateMachine.singleton.teamsPanePlayerColumnModes}
											sortFuncs={StateMachine.playerStatsColumnSortFuncs}
											setSortFunc={StateMachine.singleton.setTeamsPanePlayerSortFunc}
										/>
										<tbody>
											{this.playerStats.map(this.renderPlayerRow)}
										</tbody>
									</table>
									: <EmptyPane/>
								}
							</VerticalBox>
						</VerticalBox>
					</Collapsible>
				</VerticalBox>
			</VerticalBox>
		: <LoadingPane/>;
	}

	get renderRecord() {
		//@ts-ignore
		const seasonStats = APIModel.singleton.teamSummariesById.get(this.props.id);
		const currentSeasonRecord = {wins: 0, losses: 0, ties: 0};
		const aggregateRecord = {wins: 0, losses: 0, ties: 0};
		seasonStats?.forEach((summary, season) => {
			if (season === SeasonHelper.currentSeason)
			{
				currentSeasonRecord.wins = summary.wins;
				currentSeasonRecord.losses = summary.losses;
				currentSeasonRecord.ties = summary.ties;
			}
			aggregateRecord.wins = aggregateRecord.wins + summary.wins;
			aggregateRecord.losses = aggregateRecord.losses + summary.losses;
			aggregateRecord.ties = aggregateRecord.ties + summary.ties;
		})

		return <div title={currentSeasonRecord.wins+str("RECORD_WINS")
			+currentSeasonRecord.losses+str("RECORD_LOSSES")
			+currentSeasonRecord.ties+str("RECORD_TIES")+" "
			+"this season "
			+"("+aggregateRecord.wins+str("RECORD_WINS")
			+aggregateRecord.losses+str("RECORD_LOSSES")
			+aggregateRecord.ties+str("RECORD_TIES")+" overall)"}>
			{`${currentSeasonRecord.wins}${str("RECORD_HYPHENS")}${currentSeasonRecord.losses}${str("RECORD_HYPHENS")}${currentSeasonRecord.ties} (${aggregateRecord.wins}${str("RECORD_HYPHENS")}${aggregateRecord.losses}${str("RECORD_HYPHENS")}${aggregateRecord.ties})`}
		</div>
	}

	get renderLegend(): React.ReactNode
	{
		const rows = Array.from(new Set([...TeamTable.headers, ...this.playerColumnHeaders]));

		const renderLegendRow = (header: string, index: number) =>
			 header !== "N" &&
			<li title={TextHelper.headerTooltips.get(header)} className="Stats-Pane-Header-Legend-Item" key={index}>
				<strong>{`${header}`}</strong>{`: ${TextHelper.legendText.get(header)}`}
			</li>;

		return <VerticalBox className="Stats-Pane-Header-Legend">
			<HorizontalBox spaced>
				<div className="Stats-Pane-Header-Legend-Title">{str("LEGEND_TITLE")}</div>
				<div className="Stats-Pane-Header-Button" onClick={this.onToggleLegendCollapsed}>
					{`(${StateMachine.singleton.teamsLegendCollapsed
						? str("EXPAND_TOGGLE_BUTTON")
						: str("COLLAPSE_TOGGLE_BUTTON")})`
					}
				</div>
			</HorizontalBox>
			<ul className="Stats-Pane-Header-Legend-Box">
				{StateMachine.singleton.teamsLegendCollapsed
					? null
					: rows
						.filter((row) => TextHelper.legendText.get(row))
						.map(renderLegendRow)
				}
			</ul>
		</VerticalBox>
	}

	readonly renderPlayerRow = (row: PlayerStatsWrapper, index: number) => {//render interior of cells manually to allow custom rendering
		//render interior of cells manually to allow custom rendering
		const interior: React.ReactNode[] = [
			index === this.playerStats.length - 1
				? str("PLAYER_TOTAL_NAME")
				: row.player_id
					? <Link to={`/players/id=${row.player_id}`}>
						{row.playername}
					</Link>
					: row.playername,
			row.games,
			row.powers,
			row.tens,
			row.negs,
			row.prettyPpg
		];
		return <tr key={index} className="Teams-Pane-Row">
			{this.playerColumnHeaders.map((header, i) =>
				<td className={`Table-Cell Table-Cell-${header}-Column Table-Cell-Row-${index.toString()}`} key={i}>
					{ index === this.playerStats.length - 1 ? <strong>{interior[i]}</strong> : interior[i]}
				</td>
			)}
		</tr>;
	}

    readonly onToggleLegendCollapsed = StateMachine.singleton.onToggleTeamsLegendCollapsed;
	readonly onToggleSeason = StateMachine.singleton.createToggleTeamsSeasonIndexCallback;
}

@observer
class TeamsOverview extends React.Component {
	componentDidMount() {
		APIModel.singleton.refreshSchedules();
	}

	get headers(): string[]
	{
		return ["N", "Division", "Team", "Players"];
	}
	get filterText(): string
	{
		return StateMachine.singleton.teamsFilterText;
	}

	render() {
		return <VerticalBox className="container-fluid" spaced>
			<h2>{str("TEAM_OVERVIEW_TITLE")}</h2>
			<SeasonToggle
				selectedIndex={StateMachine.singleton.teamsSeasonIndex}
				onToggleIndex={this.onToggleSeason}/>
			<VerticalBox className="Teams-Pane-Header">
				<HorizontalBox spaced className="Teams-Pane-Filter-Box col-lg-3 col-sm-5 col-xs-6">
					<label className="Teams-Pane-Filter-Box-Label " htmlFor="statsFilter">
						{str("FILTER_BY_TEXT_LABEL")}
					</label>
					<input className="col-sm-6" type="text" id="statsFilter" defaultValue={this.filterText} onChange={this.onChangeFilter}/>
				</HorizontalBox>
			</VerticalBox>
			<TeamsOverviewSeasonTable headers={this.headers} season={StateMachine.singleton.teamsSeasonIndex}/>
		</VerticalBox>
	}

	readonly onChangeFilter = StateMachine.singleton.onChangeTeamFilterText;
	readonly onToggleSeason = StateMachine.singleton.createToggleTeamsSeasonIndexCallback;
}

interface TeamsOverviewSeasonTableProps {
	season: number;
	headers: string[];
}

@observer
class TeamsOverviewSeasonTable extends React.Component<TeamsOverviewSeasonTableProps> {
	get filterText(): string
	{
		return StateMachine.singleton.teamsFilterText;
	}
	get rows(): TeamWrapper[]
	{
		return APIModel.singleton.allTeams
			.map(team => new TeamWrapper(team))
			.filter(team => (SeasonHelper.currentSeason != null && SeasonHelper.currentSeason === this.props.season)
				|| team.seasons.indexOf(this.props.season+1) >= 0)
			.filter(TeamWrapper.filterF(this.filterText))
			.sort(StateMachine.singleton._teamsPaneSortFunc);
	}

	render() {
		return APIModel.singleton.teamsLoaded
			? this.rows.length
				?
				<table>
					<TableHeader
						headers={this.props.headers}
						columnModes={StateMachine.singleton.teamsPaneColumnModes}
						sortFuncs={StateMachine.teamColumnSortFuncs}
						setSortFunc={StateMachine.singleton.setTeamsPaneSortFunc}
						season={this.props.season+1}
					/>
					<tbody>
						{this.rows.map(this.renderTableRow)}
					</tbody>
				</table>
				: <EmptyPane/>
			: <LoadingPane/>;
	}

	readonly renderTableRow = (team: TeamWrapper, index: number) => {
		//render interior of cells manually to allow custom rendering
		const interior: React.ReactNode[] = [
			index+1,
			team.prettyDivisionFilter(this.filterText, this.props.season+1),
			<Link key={2} to={`/teams/id=${team.id}`}>
				{team.nameFilter(this.filterText)}
			</Link>,
			team.playerNamesFilter(this.filterText)
		];

		return <tr key={index}>
			{this.props.headers.map((header, i) => <td key={i} className={`Table-Cell Table-Cell-${header}-Column`}>
				{interior[i]}
			</td>)}
		</tr>;
	};
}