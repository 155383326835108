import * as React from "react";
import {TeamInfo} from "dto/TeamInfo";
import {RegexHelper} from "util/RegexHelper";
import {TextHelper} from "util/TextHelper";
import {PlayerWrapper} from "util/PlayerWrapper";
import {APIModel} from "util/APIModel";
import {SeasonHelper} from "util/SeasonHelper";

export class TeamWrapper {
	private _row: TeamInfo;
	
	constructor(row: TeamInfo)
	{
		this._row = row;
	}
	get row(): TeamInfo {return this._row}
	division(season: number = SeasonHelper.currentSeason): string {
		const seasonIndex = this.row.seasons?.indexOf(season);
		if (seasonIndex != undefined && seasonIndex >= 0)
			return this.row.divisions?.[seasonIndex].toString() ?? ""
		return "";
	}
	prettyDivision(season: number = SeasonHelper.currentSeason): string {return this.division(season) ?? "TBD"}
	get name(): string {return this.row.name}
	get id(): number | undefined {return this.row.id}
	get players(): PlayerWrapper[] {return this.row.players.map((player) => new PlayerWrapper(player))}
	get playerNames(): string[] {return this.players.map(player => player.full_name ?? "")}
	get playerFNames(): string[] {return this.players.map(player => player.fname)}
	get playerLNames(): string[] {return this.players.map(player => player.lname)}
	get email(): string {return this.row.email ?? ""}
	get seasons(): number[] {return this.row.seasons ?? []}

	markFiltered(raw: string | number, filterText: string): React.ReactNode
	{
		return <div dangerouslySetInnerHTML={{__html: RegexHelper.markText(raw, filterText)}}/>;
	}

	prettyDivisionFilter(filterText: string, season: number = SeasonHelper.currentSeason): React.ReactNode {return this.markFiltered(this.prettyDivision(season), filterText)};
	nameFilter(filterText: string): React.ReactNode {return this.markFiltered(this.name, filterText)}
	playerNamesFilter(filterText: string): React.ReactNode {
		const markedText = this.playerNames.map((name) => RegexHelper.markText(name, filterText)).join(", ");
		return <div dangerouslySetInnerHTML={{__html: markedText}}/>;
	}
	emailFilter(filterText: string): React.ReactNode {return this.markFiltered(this.email, filterText)}

	static filterF(filterText: string, season: number = SeasonHelper.currentSeason): (wrapper: TeamWrapper) => boolean
	{
		const regExp = RegexHelper.niceRegExp(filterText);
		return (wrapper: TeamWrapper) => {
			for (let field in wrapper.row)
			{
				//explicitly handle players field
				if (field === "players")
				{
					for (let name of wrapper.playerNames)
					{
						if (regExp.test(name))
						{
							return true;
						}
					}
				}
				else
				{
					if (field !== "id"
						&& wrapper.row[field as keyof TeamInfo]
						&& regExp.test(wrapper.row[field as keyof TeamInfo]!.toString()))
					{
						return true;
					}
				}
			}
			if (regExp.test(wrapper.prettyDivision(season))) return true;
			return false;
		};
	}
}