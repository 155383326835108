import React from "react";
import {computed, IReactionDisposer, makeObservable, ObservableMap, reaction} from "mobx";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {LocationProps} from "App";
import {APIModel} from "util/APIModel";
import {SeasonHelper} from "util/SeasonHelper";
import {StateMachine} from "util/StateMachine";
import {TossupConversionWrapper, BonusConversionWrapper, SummaryConversionWrapper} from "util/ConversionInfoWrapper";
import {HorizontalBox} from "ui/HorizontalBox";
import {VerticalBox} from "ui/VerticalBox";
import {TableHeader} from "ui/TableHeader";
import {ToggleButtons} from "ui/ToggleButtons";
import {LoadingPane} from "ui/LoadingPane";

@observer
export class ConversionPane extends React.Component<LocationProps> {
	private params: ObservableMap<string, string> = new ObservableMap();

	private static SEASON_FIELD = "season";
	private static WEEK_FIELD = "week";

	disposers: IReactionDisposer[] = [];
	constructor(props: LocationProps)
	{
		super(props);
		makeObservable(this, {
			season: computed,
			week: computed,
			tossupRows: computed,
			bonusRows: computed,
			summaryRows: computed
		});
		this.disposers.push(reaction(() => [SeasonHelper.currentSeason, SeasonHelper.currentWeek],
			() => APIModel.singleton.refreshConversion(),
			{fireImmediately: true}));
	}

	componentDidMount() {
		this.processProps();
		// APIModel.singleton
	}

	componentDidUpdate(prevProps: LocationProps)
	{
		if (this.props.path !== prevProps.path
			|| this.props.params !== prevProps.params)
		{
			this.processProps();
		}
	}

	componentWillUnmount()
	{
		this.disposers.map((disposer: IReactionDisposer) => disposer());
	}

	processProps() {
		this.params.clear();
		const split = this.props.path.split("/");
		if (split.length > 2)
		{
			this.params.replace(split.slice(2).map((param) => {
				const [key, value] = param.split("=");
				console.log(`Found param '${key}' with value '${value}'`);
				return [key, value];
			}));
		}
	}

	get season(): number
	{
		const season = this.params.get(ConversionPane.SEASON_FIELD);
		return season ? Number.parseInt(season) : 0;
	}
	get week(): number
	{
		const week = this.params.get(ConversionPane.WEEK_FIELD);
		return week ? Number.parseInt(week) : 0;
	}

	render() {
		return <VerticalBox spaced className="container-fluid">
			<h3>CONVERSION STATS</h3>
			<HorizontalBox spaced>
				{this.renderSeasonSelect}
				{this.season > 0 && this.renderWeekSelect}
				{this.week > 0 && <ToggleButtons
					labels={["Tossups", "Bonuses"]}
					selectedIndex={StateMachine.singleton.tossupBonus}
					onToggleIndex={StateMachine.singleton.createToggleTossupBonusCallback}
				/>}
			</HorizontalBox>
			{this.season > 0 && (this.week === 0
				? this.renderSeasonConversion
				: this.renderPacketConversion)}
		</VerticalBox>
	}

	get renderSeasonSelect() {
		return <HorizontalBox spaced>
			<div>Season:</div>
			<HorizontalBox className="btn-group">
				{[...new Array(SeasonHelper.currentSeason)].map((_, i) =>
					<button key={i} type="button" className={`btn Conversion-Toggle-Button ${this.season === i+1 ? "Active-Toggle-Button" : ""}`}>
						<Link className="Conversion-Toggle-Button-Inner" to={`/conversion/season=${i+1}`}>{i+1}</Link>
					</button>
				)}
			</HorizontalBox>
		</HorizontalBox>
	}

	get renderWeekSelect() {
		return <HorizontalBox spaced>
			<div>Week:</div>
			<HorizontalBox className="btn-group">
				{[...new Array(this.season === SeasonHelper.currentSeason
					? SeasonHelper.currentWeek
					: SeasonHelper.seasonLength[this.season]+1)].map((_, i) =>
					<button key={i} type="button" className={`btn Conversion-Toggle-Button ${this.week === i ? "Active-Toggle-Button" : ""}`}>
						{i === 0
							? <Link className="Conversion-Toggle-Button-Inner" to={`/conversion/season=${this.season}`}>
								All
							</Link>
							: <Link className="Conversion-Toggle-Button-Inner" to={`/conversion/season=${this.season}/week=${i}`}>
								{i}
							</Link>
						}
					</button>
				)}
			</HorizontalBox>
		</HorizontalBox>
	}

	get tossupHeaders(): string[]
	{
		return ["Week", "TU", "Answer", "15", "10", "-5", "%15", "%(15+10)", "%(-5)"];
	}
	get bonusHeaders(): string[]
	{
		return ["Week", "B", "Answers", "%(Part 1)", "%(Part 2)", "%(Part 3)", "Average PPB"];
	}
	get summaryHeaders(): string[]
	{
		return ["Week", "15", "10", "-5", "%15", "%(15+10)", "%(-5)", "Average PPB"]
	}

	baseTossupRows(season: number, week: number): TossupConversionWrapper[]
	{
		return APIModel.singleton.tossupConversionByWeekBySeason
			.get(season)
			?.get(week)
			?.map((row) => new TossupConversionWrapper(row))
			.sort(StateMachine.singleton._tossupSortFunc) ?? [];
	}
	tossupSummary(rows: TossupConversionWrapper[]): TossupConversionWrapper
	{
		const summary = new TossupConversionWrapper({
			...rows[0]?.row,
			powers: rows.length ? rows.slice(0,20).map((row) => row.powers).reduce((x,y)=>x+y) : 0,
			tens: rows.length ? rows.slice(0,20)?.map((row) => row.tens)?.reduce((x,y)=>x+y) : 0,
			negs: rows.length ? rows.slice(0,20)?.map((row) => row.negs)?.reduce((x,y)=>x+y) : 0
		});
		const total = 35*20;
		summary.row.power_pct = total ? summary.powers/total : 0;
		summary.row.conv_pct = total ? (summary.powers + summary.tens)/total : 0;
		summary.row.neg_pct = total ? summary.negs/total : 0;
		return summary;
	}
	get tossupRows(): TossupConversionWrapper[]
	{
		const rows = this.baseTossupRows(this.season, this.week).slice();
		if (rows.length)
		{
			rows.push(this.tossupSummary(rows));
		}
		return rows;
	}

	baseBonusRows(season: number, week: number): BonusConversionWrapper[]
	{
		return APIModel.singleton.bonusConversionByWeekBySeason
			.get(season)
			?.get(week)
			?.map(row => new BonusConversionWrapper(row))
			.sort(StateMachine.singleton._bonusSortFunc) ?? [];
	}
	bonusSummary(rows: BonusConversionWrapper[]): BonusConversionWrapper
	{
		return new BonusConversionWrapper({
			...rows[0]?.row,
			part1_conv_pct: rows.length ? rows.slice(0,20).map((row) => row.part1_conv_pct)?.reduce((x,y)=>x+y)/20 : 0,
			part2_conv_pct: rows.length ? rows.slice(0,20).map((row) => row.part2_conv_pct)?.reduce((x,y)=>x+y)/20 : 0,
			part3_conv_pct: rows.length ? rows.slice(0,20).map((row) => row.part3_conv_pct)?.reduce((x,y)=>x+y)/20 : 0,
		});
	}
	get bonusRows(): BonusConversionWrapper[]
	{
		const rows = this.baseBonusRows(this.season, this.week).slice();
		if (rows.length)
		{
			rows.push(this.bonusSummary(rows));
		}
		return rows;
	}

	get renderPacketConversion() {
		const rows = StateMachine.singleton.tossupBonus === 0
			? this.tossupRows
			: this.bonusRows;
		return rows.length
			? StateMachine.singleton.tossupBonus === 0
				? <table className="table">
					<TableHeader
						headers={this.tossupHeaders}
						columnModes={StateMachine.singleton.tossupConversionColumnModes}
						sortFuncs={StateMachine.tossupColumnSortFuncs}
						setSortFunc={StateMachine.singleton.setTossupSortFunc}
					/>
					<tbody>
						{this.tossupRows.map(this.renderTossupRow)}
					</tbody>
				</table>
				: <table className="table">
					<TableHeader
						headers={this.bonusHeaders}
						columnModes={StateMachine.singleton.bonusConversionColumnModes}
						sortFuncs={StateMachine.bonusColumnSortFuncs}
						setSortFunc={StateMachine.singleton.setBonusSortFunc}
					/>
					<tbody>
						{this.bonusRows.map(this.renderBonusRow)}
					</tbody>
				</table>
			: <LoadingPane/>;
	}

	private readonly renderTossupRow = (row: TossupConversionWrapper, index: number) => {
		const interior: React.ReactNode[] = index === this.tossupRows.length - 1
			? [
				"-",
				"-",
				"Total",
				row.powers,
				row.tens,
				row.negs,
				row.power_pct.toString().slice(0,5),
				row.conv_pct.toString().slice(0,5),
				row.neg_pct.toString().slice(0,5)
			]
			: [
				row.packet,
				row.tossup,
				row.answer,
				row.powers,
				row.tens,
				row.negs,
				row.power_pct,
				row.conv_pct,
				row.neg_pct
			];
		return <tr key={index} className="">
			{this.tossupHeaders.map((header, i) =>
				<td key={i} className={`Table-Cell Table-Cell-${header}-Column Table-Cell-Row-${index.toString()}`}>
					{index === this.tossupRows.length - 1 ? <strong>{interior[i]}</strong> : interior[i]}
				</td>)}
		</tr>;
	}

	private readonly renderBonusRow = (row: BonusConversionWrapper, index: number) => {
		const interior: React.ReactNode[] = index === this.bonusRows.length - 1
			? [
				"",
				"",
				"Total",
				"",
				"",
				"",
				(30*row.overall_conv_pct).toString().slice(0,5)
			]
			: [
				row.packet,
				row.bonus,
				row.answers,
				row.part1_conv_pct,
				row.part2_conv_pct,
				row.part3_conv_pct,
				(30*row.overall_conv_pct).toString().slice(0,5)
			];
		return <tr key={index} className="">
			{this.bonusHeaders.map((header, i) =>
				<td key={i} className={`Table-Cell Table-Cell-${header}-Column Table-Cell-Row-${index.toString()}`}>
					{index === this.bonusRows.length - 1 ? <strong>{interior[i]}</strong> : interior[i]}
				</td>)}
		</tr>;
	}

	get summaryRows(): SummaryConversionWrapper[]
	{
		const baseRows = [...new Array(this.season === SeasonHelper.currentSeason
			? SeasonHelper.currentWeek ? SeasonHelper.currentWeek - 1 : 0
			: SeasonHelper.seasonLength[this.season-1])].map((_, weekMinusOne) => {
				const tossupSummary = this.tossupSummary(this.baseTossupRows(this.season, weekMinusOne+1));
				const bonusSummary = this.bonusSummary(this.baseBonusRows(this.season, weekMinusOne+1));
				return new SummaryConversionWrapper({
					...tossupSummary.row,
					...bonusSummary.row,
					overall_conv_pct: bonusSummary.overall_conv_pct
				});
			}).sort(StateMachine.singleton._summarySortFunc);
		const summary = new SummaryConversionWrapper({
			...baseRows[0]?.row,
			powers: baseRows.length ? baseRows.map((row) => row.powers).reduce((x,y)=>x+y) : 0,
			tens: baseRows.length ? baseRows.map((row) => row.tens).reduce((x,y)=>x+y) : 0,
			negs: baseRows.length ? baseRows.map((row) => row.negs).reduce((x,y)=>x+y) : 0,
			overall_conv_pct: baseRows.length ? baseRows.map((row) => row.overall_conv_pct).reduce((x,y)=>x+y)/baseRows.length : 0
		});
		const total = 35*20*baseRows.length;
		summary.row.power_pct = total ? summary.powers/total : 0;
		summary.row.conv_pct = total ? (summary.powers + summary.tens)/total : 0;
		summary.row.neg_pct = total ? summary.negs/total : 0;
		baseRows.push(summary);
		
		return baseRows;
	}

	get renderSeasonConversion() {
		return <table className="table">
			<TableHeader
				headers={this.summaryHeaders}
				columnModes={StateMachine.singleton.weeklyConversionColumnModes}
				sortFuncs={StateMachine.summaryColumnSortFuncs}
				setSortFunc={StateMachine.singleton.setSummarySortFunc}
			/>
			<tbody>
				{this.summaryRows.map(this.renderSummaryRow)}
			</tbody>
		</table>;
	}
	private readonly renderSummaryRow = (row: SummaryConversionWrapper, index: number) => {
		const interior: React.ReactNode[] = index === this.summaryRows.length - 1
			? [
				"Total",
				row.powers,
				row.tens,
				row.negs,
				row.power_pct.toString().slice(0,5),
				row.conv_pct.toString().slice(0,5),
				row.neg_pct.toString().slice(0,5),
				(30*row.overall_conv_pct).toString().slice(0,5)
			]
			: [
				row.packet,
				row.powers,
				row.tens,
				row.negs,
				row.power_pct.toString().slice(0,5),
				row.conv_pct.toString().slice(0,5),
				row.neg_pct.toString().slice(0,5),
				(30*row.overall_conv_pct).toString().slice(0,5)
			];
		return <tr key={index} className="">
			{this.summaryHeaders.map((header, i) =>
				<td key={i} className={`Table-Cell Table-Cell-${header}-Column Table-Cell-Row-${index.toString()}`}>
					{index === this.summaryRows.length - 1 ? <strong>{interior[i]}</strong> : interior[i]}
				</td>)}
		</tr>;
	}
}