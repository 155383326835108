import {action, computed, makeObservable, observable, ObservableMap, runInAction} from "mobx";
import {observer} from "mobx-react";
import * as React from "react";
import {LocationProps} from "App";
import {LoadingPane} from "ui/LoadingPane";
import {VerticalBox} from "ui/VerticalBox";
import {UserModel} from "util/UserModel";
import {APIModel} from "util/APIModel";
import {str} from "util/TextHelper";

@observer
export class ConfirmPane extends React.Component<LocationProps> {
	private params: ObservableMap<string, string> = new ObservableMap();

	private static USER_ID_FIELD = "user_id";
	private static PLAYER_ID_FIELD = "player_id";
	private static IS_CAPTAIN_FIELD = "is_captain";

	constructor(props: LocationProps) {
		super(props);
		makeObservable(this, {
			_validated: observable,
			user_id: computed,
			player_id: computed,
			is_captain: computed
		});
	}

	componentDidMount() {
		APIModel.singleton.refreshPlayers();
		APIModel.singleton.refreshTeams();
		this.processProps();
	}

	componentDidUpdate(prevProps: LocationProps)
	{
		if (this.props.path !== prevProps.path
			|| this.props.params !== prevProps.params)
		{
			this.processProps();
		}
	}

	processProps() {
		this.params.clear();
		const split = this.props.path.split("/");
		if (split.length > 2)
		{
			this.params.replace(split.slice(2).map(action((param) => {
				const [key, value] = param.split("=");
				console.log(`Found param '${key}' with value '${value}'`);
				return [key, value];
			})));
		}
		this.validateProps();
	}

	get user_id(): string
	{
		const user_id = this.params.get(ConfirmPane.USER_ID_FIELD);
		return user_id ?? "";
	}

	get player_id(): number
	{
		const player_id = this.params.get(ConfirmPane.PLAYER_ID_FIELD);
		return player_id
			? Number.parseInt(player_id)
			: 0;
	}
	get is_captain(): number
	{
		const is_captain = this.params.get(ConfirmPane.IS_CAPTAIN_FIELD);
		return is_captain
			? Number.parseInt(is_captain)
			: 0;
	}

	_validated?: boolean;
	async validateProps(): Promise<void>
	{
		if (this.player_id && this.user_id)
		{
			const valid = await UserModel.validateUser(
				this.user_id,
				this.player_id,
				this.is_captain
			);
			runInAction(() => this._validated = valid);
			return;
		}
		runInAction(() => this._validated = false);
	}

	render() {
		return this._validated != null
			? <VerticalBox className="container-fluid">
				{this._validated === true
					? this.renderConfirmSuccess()
					: this.renderConfirmFailure()
				}
			</VerticalBox>
			: <LoadingPane/>;
	}

	renderConfirmSuccess(): React.ReactNode {
		//@ts-ignore
		const player = APIModel.singleton.playersById.get(""+this.player_id);
		//@ts-ignore
		const team = APIModel.singleton.teamsByPlayerId.get(""+this.player_id);

		return <VerticalBox spaced>
			<h3>{str("SUCCESSFUL_CONFIRM_LABEL")}</h3>
			{(player && team) && <div className="Text-Wrap">
				{str("SUCCESSFUL_CONFIRM_PART_1") + (player?.fname + " " + player?.lname) + (this.is_captain ? str("SUCCESSFUL_CAPTAIN_CONFIRM_PART_2") : str("SUCCESSFUL_PLAYER_CONFIRM_PART_2")) + (team?.name ?? "") + str("SUCCESSFUL_CONFIRM_PART_3")
				}
			</div>}
		</VerticalBox>;
	}

	renderConfirmFailure(): React.ReactNode {
		return <VerticalBox spaced>
			<h3>{str("FAILED_CONFIRM_LABEL")}</h3>
			<div className="Text-Wrap" dangerouslySetInnerHTML={{__html: str("FAILED_CONFIRM")}}/>
		</VerticalBox>;
	}
}