import * as React from "react";
import {action, computed, makeObservable, observable, ObservableMap, runInAction, when} from "mobx";
import {observer} from "mobx-react";
import {str} from "util/TextHelper";
import {StateMachine} from "util/StateMachine";
import {APICaller} from "util/APICaller";
import {SeasonHelper} from "util/SeasonHelper";
import {APIModel} from "util/APIModel";
import {HorizontalBox} from "ui/HorizontalBox";
import {VerticalBox} from "ui/VerticalBox";
import {ToggleButtons} from "ui/ToggleButtons";
import {PlayerInfo} from "dto/PlayerInfo";
import {TeamInfo} from "dto/TeamInfo";

@observer
export class RegisterPane extends React.Component {
	_submissionStatus?: boolean;

	_teamIndividualToggle: number = 0;
	_name: string = "";
	_email: string = "";

	_currentPlayerIndex: number = 0;
	_playerIndices: number[] = [0];
	_firstNames: ObservableMap<number, string> = new ObservableMap();
	_lastNames: ObservableMap<number, string> = new ObservableMap();

	_experience: string = "";

	_confirm: boolean = false;
	_confirmRereg: boolean = false;

	_currentTeam: number = -1;

	constructor(props: {}) {
		super(props);
		makeObservable(this, {
			_submissionStatus: observable,
			_teamIndividualToggle: observable,
			_name: observable,
			_email: observable,
			_playerIndices: observable,
			_experience: observable,
			_confirm: observable,
			_confirmRereg: observable,
			_currentTeam: observable,
			isEmailValid: computed,
			removeEnabled: computed,
			submitEnabled: computed,
			renderRosterRows: computed
		});
	}

	componentDidMount() {
		APIModel.singleton.refreshTeams();
	}

	get isNewTeamMode(): boolean {return this._teamIndividualToggle === 0}
	get isExistingTeamMode(): boolean {return this._teamIndividualToggle === 1}

	get players(): PlayerInfo[] {
		return this._playerIndices.map((index) => {
			return {fname: this._firstNames.get(index)?.trim() ?? "", lname: this._lastNames.get(index)?.trim() ?? ""};
		});
	}
	get teams(): TeamInfo[] {
		return APIModel.singleton.allTeams
			.filter(team =>	!SeasonHelper.currentSeason
				|| !team.seasons
				|| team.seasons.indexOf(SeasonHelper.currentSeason) === -1)
			.sort((a,b) => a.name.localeCompare(b.name));
	}

	render() {
		return SeasonHelper.registrationOpen
			? <VerticalBox className="container-fluid Text-Wrap">
				<h2 className="">{str("REGISTER_TITLE")}</h2>
				<p dangerouslySetInnerHTML={{__html: str("REGISTER_INFO_SEASON_2")}}/>
				{this._submissionStatus == null
					? this.renderRegisterForm
					: this.renderPostRegister
				}
			</VerticalBox>
			: <VerticalBox className="container-fluid Text-Wrap">
				<h2 className="">{str("REGISTER_TITLE")}</h2>
				<p dangerouslySetInnerHTML={{__html: str("REGISTER_INFO_SEASON_2_CLOSED")}}/>
				<p dangerouslySetInnerHTML={{__html: str("REGISTER_INFO_SEASON_3_PREVIEW")}}/>
			</VerticalBox>;
	}

	get isNameValid(): boolean
	{
		return this._name.length > 0;
	}
	//adapted from https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
	private readonly _emailRegex = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)/);
	get isEmailValid(): boolean
	{
		return this._email.length > 0 && this._emailRegex.test(this._email);
	}
	get removeEnabled(): boolean
	{
		return this._playerIndices.length > 1;
	}
	get submitEnabled(): boolean
	{
		return this._name.length > 0
			&& this.isEmailValid
			&& (!this.isNewTeamMode
				|| (this.players.length > 0	&& this.players.some((player) => player.fname.trim().length && player.lname.trim().length)))
			&& this._confirm;
	}

	get renderRegisterForm()
	{
		return <VerticalBox spaced className="Register-Pane-Input-Section">
			<div className="Text-Wrap">{str("REGISTER_GENERAL_INFO")}</div>
			<HorizontalBox className="Margin-Auto" spaced>
				<div>{str("SIGN_UP_AS_LABEL")}</div>
				<ToggleButtons
					labels={[
						str("A_NEW_TEAM_TOGGLE_BUTTON"),
						str("AN_EXISTING_TEAM_TOGGLE_BUTTON"),
						str("AN_INDIVIDUAL_TOGGLE_BUTTON")
					]}
					selectedIndex={this._teamIndividualToggle}
					onToggleIndex={this.createToggleTeamIndividualCallback}/>
			</HorizontalBox>
			<div className="Register-Pane-Info-Section Text-Wrap Margin-Auto" dangerouslySetInnerHTML={{__html: this.isNewTeamMode
				? str("REGISTER_TEAM_INFO.1")
				: this.isExistingTeamMode
					? str("REREGISTER_TEAM_INFO")
					//implicitly the final option is free agent
					: str("REGISTER_PLAYER_INFO")
				}}/>
			{this.isNewTeamMode && this.renderNewTeamRegistration}
			{this.isExistingTeamMode && this.renderExistingTeamRegistration}
		</VerticalBox>;
	}

	get renderNewTeamRegistration(): React.ReactNode
	{
		return <form className="row" onSubmit={this.onRegister}>
			<VerticalBox spaced>
				<HorizontalBox spaced className="Margin-Auto">
					<label className="Register-Pane-Name-Label" htmlFor="name">
						{str("TEAM_NAME_LABEL")}
					</label>
					<input className={`Register-Pane-Name-Input ${this.isNameValid ? "" : ""}`} id="name" type="text" defaultValue={this._name} onChange={this.onChangeName}/>
				</HorizontalBox>				
				<HorizontalBox spaced className="Margin-Auto">
					<label className="Register-Pane-Email-Label" htmlFor="email">{str("EMAIL_LABEL")}</label>
					<input className={`Register-Pane-Email-Input ${this.isEmailValid ? "" : ""}`} id="email" type="text" defaultValue={this._email} onChange={this.onChangeEmail}/>
				</HorizontalBox>
				{this.isNewTeamMode && this.renderRosterRows}
				<VerticalBox spaced>
					<label htmlFor="experience">{str("EXPERIENCE_LABEL")}</label>
					<textarea id="experience" rows={10} cols={40} onChange={this.onChangeExperience} defaultValue={this._experience}/>
				</VerticalBox>
				<VerticalBox className="Warning-Section">
					{!this.isNameValid && <strong className="Register-Pane-Validation-Message">
							{str("TEAM_NAME_EMPTY_WARNING")}
						</strong>
					}
					{!this.isEmailValid && <strong className="Register-Pane-Validation-Message">
						{this._email.length === 0 ? str("EMAIL_EMPTY_WARNING") : str("EMAIL_INVALID_WARNING")}
					</strong>}
					{(this.players.length === 0 || (this.players.every((player) => !player.fname.trim().length || !player.lname.trim().length))) && <strong>
						{str("PLAYER_COUNT_WARNING")}
					</strong>}
					{(this.players.length > 1 && (this.players.some((player) => !player.fname.trim().length || !player.lname.trim().length))) && <strong>
						{str("PLAYER_EMPTY_WARNING")}
					</strong>}
				</VerticalBox>
				<HorizontalBox spaced className="Margin-Auto">
					<input type="checkbox" id="confirm" checked={this._confirm} onChange={this.onChangeConfirm}/>
					<label htmlFor="confirm">{str("CONFIRM_LABEL")}</label>
				</HorizontalBox>
				<input type="submit" disabled={!this.submitEnabled}/>
			</VerticalBox>
		</form>;
	}

	get renderExistingTeamRegistration(): React.ReactNode
	{
		return <form className="row" onSubmit={this.onReregister}>
			<VerticalBox>
				<HorizontalBox spaced>
					<label htmlFor="teamSelect" className="Register-Pane-Select-Label">{str("TEAM_COLON")}</label>
					<select id="teamSelect" className="User-Pane-Select" value={this._currentTeam} onChange={this.onChangeTeam}>
						<option value={-1}>{str("NO_TEAM_SELECTED_LABEL")}</option>
						{this.teams.map((team, i) => <option key={i} className="" value={team.id}>{team.name}</option>)}
					</select>
				</HorizontalBox>
				{this._currentTeam > 0 && <strong><u>{str("ROSTER_TITLE")}</u></strong>}
				<table>
					<tbody>
						{//@ts-ignore
						APIModel.singleton.teamsById.get(""+this._currentTeam)?.players.map((player, i) =>
							<tr key={i}><td>{player.fname + " " + player.lname}</td></tr>
						)}
					</tbody>
				</table>
				<HorizontalBox spaced className="Margin-Auto">
					<input type="checkbox" id="reregConfirm" checked={this._confirmRereg} onChange={this.onChangeReregConfirm}/>
					<label htmlFor="reregConfirm">{str("CONFIRM_LABEL")}</label>
				</HorizontalBox>
				<input type="submit" disabled={this._currentTeam < 0 || !this._confirmRereg}/>
			</VerticalBox>
		</form>;
	}

	get renderRosterRows(): React.ReactNode
	{
		return <VerticalBox spaced className="Register-Pane-Row-Section">
			<h3>{str("ROSTER_TITLE")}</h3>
			<table className="table">
				<thead>
					<tr><th>{str("FIRST_NAME_LABEL")}</th><th>{str("LAST_NAME_LABEL")}</th></tr>
				</thead>
				<tbody>
					{this._playerIndices.map((player, i) =>
						<tr className={""} key={i}>
							<td><input className="Register-Pane-Row-Input" type="text" value={this._firstNames.get(player) ?? ""} onChange={this.createFirstNameOnChange(player)}/></td>
							<td><input className="Register-Pane-Row-Input" type="text" value={this._lastNames.get(player) ?? ""} onChange={this.createLastNameOnChange(player)}/></td>
							<td><button type="button" disabled={!this.removeEnabled} onClick={this.createOnRemoveIndex(i)}>
								{str("REMOVE_PLAYER_LABEL")}
							</button></td>
						</tr>)
					}
				</tbody>
			</table>
			<button className="Register-Pane-Add-Button" type="button" onClick={this.onAddRosterMember}>
				{str("ADD_PLAYER_LABEL")}
			</button>
		</VerticalBox>;
	}

	get renderPostRegister()
	{
		return <VerticalBox spaced>
			<div className="" dangerouslySetInnerHTML={{__html: this._submissionStatus
				? str("REGISTRATION_SUCCESS_TEAM_MESSAGE") + " " + str("CURRENT_SEASON")
				: str("REGISTRATION_FAILURE_MESSAGE")}}/>
			<table className="Register-Pane-Confirm-Section">
				<tbody>
					<tr>
						<td>{str("TEAM_NAME_LABEL")}</td>
						<td>{this._name}</td>
					</tr>
					<tr>
						<td>{str("EMAIL_LABEL")}</td>
						<td>{this._email}</td>
					</tr>
					<tr>
						<td>{`${str("ROSTER_TITLE")}:`}</td>
						<td>{this.players.map((player) => player.fname + " " + player.lname).join(", ")}</td>
					</tr>
				</tbody>
			</table>
			<div className="Text-Wrap" dangerouslySetInnerHTML={{__html: str("REGISTRATION_AFTER_MESSAGE")}}/>
		</VerticalBox>;
	}

	readonly createToggleTeamIndividualCallback = (index: number) =>  action(() => this._teamIndividualToggle = index);
	readonly onChangeName = action((e: React.ChangeEvent<HTMLInputElement>) => this._name = e.target.value ?? "");

	private emailDelayTimer?: NodeJS.Timeout = undefined;
	readonly onChangeEmail = StateMachine.createDelayedOnChange(this.emailDelayTimer, (e) => this._email = e.target.value, 300);

	readonly onAddRosterMember = action(() => {
		this._currentPlayerIndex++;
		this._playerIndices.push(this._currentPlayerIndex);
	});

	createOnRemoveIndex(index: number): (e: React.MouseEvent<HTMLButtonElement>) => void
	{
		return action((e: React.MouseEvent<HTMLButtonElement>) => this._playerIndices.splice(index, 1));
	}
	createFirstNameOnChange(index: number): (e: React.ChangeEvent<HTMLInputElement>) => void
	{
		return action((e: React.ChangeEvent<HTMLInputElement>) => this._firstNames.set(index, e.target.value));
	}
	createLastNameOnChange(index: number): (e: React.ChangeEvent<HTMLInputElement>) => void
	{
		return action((e: React.ChangeEvent<HTMLInputElement>) => this._lastNames.set(index, e.target.value));
	}

	readonly onChangeExperience = action((e: React.ChangeEvent<HTMLTextAreaElement>) => this._experience = e.target.value ?? "")

	readonly onChangeConfirm = action((e: React.ChangeEvent<HTMLInputElement>) => this._confirm = e.target.checked);
	readonly onChangeReregConfirm = action((e: React.ChangeEvent<HTMLInputElement>) => this._confirmRereg = e.target.checked);

	private _registerClicked = false;
	readonly onRegister = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		if (!this._registerClicked)
		{
			await when(() => SeasonHelper.currentSeason > 0);
			this._registerClicked = true;
			const success = await APICaller.registerTeam({
				name: this._name,
				email: this._email,
				players: this.players,
				experience: this._experience,
				seasons: [SeasonHelper.currentSeason]
			});
			runInAction(() => this._submissionStatus = success);
		}
	}

	readonly onChangeTeam = action((e: React.ChangeEvent<HTMLSelectElement>) => this._currentTeam = Number.parseInt(e.target.value))

	private _reregisterClicked = false;
	readonly onReregister = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		if (!this._reregisterClicked)
		{
			when(() => SeasonHelper.currentSeason > 0);
			this._reregisterClicked = true;
			const success = await APICaller.reupTeam(this._currentTeam, SeasonHelper.currentSeason);
			runInAction(() => this._submissionStatus = success);
		}
	}
}