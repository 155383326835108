import * as React from "react";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import bee2 from "bee2.png";
import {LocationProps} from "App";
import {NavigationHelper, ButtonProps} from "util/NavigationHelper";
import {MainToolbarLoginSection} from "ui/MainToolbarLoginSection";
import {BurgerMenu} from "ui/BurgerMenu";

@observer
export class MainToolbar extends React.Component<LocationProps> {
	render() {
		return <header className="Main-Toolbar">
			<div className="Main-Toolbar-Top-Section">
				<Link className="Main-Toolbar-Link" to="/">
					<img className="Main-Toolbar-Banner-Image" src={bee2} alt={""}/>
					<h1 className="Main-Toolbar-Banner-Text">QB League</h1>
				</Link>
				<MainToolbarLoginSection />
			</div>
			<div className='Main-Toolbar-Burger-Menu'>
				<BurgerMenu />
			</div>
			<nav>
				<ul className="Main-Toolbar-Bottom-Section">
					{this.buttons.map((buttonProps, index) => this.renderBottomSectionButton(buttonProps, index))}
					<li className='Main-Toolbar-Bottom-Section-Button'><a href='https://www.facebook.com/QuizbowlLeague/'>Facebook</a></li>
					<li className='Main-Toolbar-Bottom-Section-Button'><a href='https://www.facebook.com/groups/1081042829087387'>Discussion</a></li>
				</ul>
			</nav>
		</header>
	}

	get buttons(): ButtonProps[]
	{
		return NavigationHelper.buttons;
	}

	renderBottomSectionButton(buttonProps: ButtonProps, index: number): React.ReactNode
	{
		let classes: string = "Main-Toolbar-Bottom-Section-Button"
		//ignore home button
		if (index > 0 && this.props.path.match("^"+buttonProps.href))
		{
			classes += " Main-Toolbar-Bottom-Section-Active-Button";
		}
		return <li className={classes} key={index}>
			<Link to={buttonProps.href}>{buttonProps.label}</Link>
		</li>;
	}
}