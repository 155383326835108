import * as React from "react";
import {computed, IReactionDisposer, makeObservable, observable, ObservableMap, reaction, runInAction, when} from "mobx";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {LocationProps} from "App";
import {APICaller} from "util/APICaller";
import {APIModel} from "util/APIModel";
import {TextHelper, str} from "util/TextHelper";
import {StateMachine} from "util/StateMachine";
import {PlayerWrapper} from "util/PlayerWrapper";
import {PlayerStatsWrapper} from "util/StatsRowWrapper";
import {SeasonHelper} from "util/SeasonHelper";
import {HorizontalBox} from "ui/HorizontalBox";
import {VerticalBox} from "ui/VerticalBox";
import {EmptyPane} from "ui/EmptyPane";
import {TableHeader} from "ui/TableHeader";
import {PlayerTable} from "ui/PlayerTable";
import {LoadingPane} from "ui/LoadingPane";
import {SeasonToggle} from "ui/SeasonToggle";
import {PlayerInfo} from "dto/PlayerInfo";
import {TeamInfo} from "dto/TeamInfo";
import {PlayerStatsInfo} from "dto/StatsInfo";

@observer
export class PlayersPane extends React.Component<LocationProps> {
	private params: ObservableMap<string, string> = new ObservableMap();

	private static ID_FIELD = "id";

	disposers: IReactionDisposer[] = [];
	componentDidMount() {
		this.processProps();
		this.disposers.push(reaction(() => [SeasonHelper.currentSeason, SeasonHelper.currentWeek],
			() => {
				APIModel.singleton.refreshTeams();
		}, {fireImmediately: true}));
	}

	componentDidUpdate(prevProps: LocationProps)
	{
		if (this.props.path !== prevProps.path
			|| this.props.params !== prevProps.params)
		{
			this.processProps();
		}
	}

	componentWillUnmount()
	{
		this.disposers.map((disposer: IReactionDisposer) => disposer());
	}

	processProps() {
		//id: optional
		this.params.clear();
		const split = this.props.path.split("/");
		if (split.length > 2)
		{
			this.params.replace(split.slice(2).map((param) => {
				const [key, value] = param.split("=");
				console.log(`Found param '${key}' with value '${value}'`);
				return [key, value];
			}));
		}
	}

	render() {
		const id = this.params.get(PlayersPane.ID_FIELD);
    	return id
    		? <SpecificPlayer id={Number.parseInt(id)}/>
    		: <PlayersOverview />
    }
}

interface SpecificPlayerProps {
	id: number;
}

@observer
class SpecificPlayer extends React.Component<SpecificPlayerProps> {
	constructor(props: SpecificPlayerProps)
	{
		super(props);
		makeObservable(this, {
			_player: observable,
			player: computed,
			team: computed,
			seasonStats: computed,
			playerSeasons: computed
		});
	}

	_player?: PlayerInfo;
	loadingComplete: boolean = false;
	async componentDidMount()
	{
		when(() => this.team == null, () => APIModel.singleton.refreshTeams());
		const player = APIModel.singleton.playersById.get(this.props.id) || await APICaller.getPlayer(this.props.id);
		runInAction(() => this._player = player);
		this.loadingComplete = true;

		APIModel.singleton.refreshPlayerCareerStats(this.props.id);
		APIModel.singleton.refreshPlayerStats(this.props.id);
	}

	async componentDidUpdate(oldProps: SpecificPlayerProps)
	{
		if (this.props.id !== oldProps.id)
		{
			this.loadingComplete = false;
			when(() => this.team == null, () => APIModel.singleton.refreshTeams());
			const player = APIModel.singleton.playersById.get(this.props.id) || await APICaller.getPlayer(this.props.id);
			runInAction(() => this._player = player);
			this.loadingComplete = true;

			APIModel.singleton.refreshPlayerCareerStats(this.props.id);
			APIModel.singleton.refreshPlayerStats(this.props.id);
		}
	}

	get player(): PlayerInfo | undefined
	{
		return this._player;
	}
	get team(): TeamInfo | undefined
	{
		//@ts-ignore
		return APIModel.singleton.teamsByPlayerId.get(""+this.props.id);
	}

	get prettyDivision(): string
	{
		if (this.team)
		{
			const seasonIndex = this.team.seasons?.indexOf(SeasonHelper.currentSeason);
			return (seasonIndex != undefined && seasonIndex >= 0)
				? this.team.divisions?.[seasonIndex].toString() ?? ""
				: "";
		}
		return "";
	}

	get season(): number
	{
		return APIModel.singleton.playerSeasonsPlayed(this.props.id)[StateMachine.singleton.playersSeasonIndex-1] ?? 0;
	}
	get seasonHeaders(): string[]
	{
		return ["Season", "G", "15", "10", "-5", "PPG"];
	}
	get seasonStats(): PlayerStatsWrapper[]
	{
		const rows: PlayerStatsWrapper[] = [];

		//@ts-ignore
		const stats: PlayerStatsInfo[] = APIModel.singleton.statsSummaryForPlayer(this.props.id);
		if (stats)
		{
			stats.forEach((summary, index) => {
				const wrapper = new PlayerStatsWrapper(summary);
				wrapper.row.season = APIModel.singleton.playerSeasonsPlayed(this.props.id)[index];
				rows.push(wrapper);
			});
		}

		const sortedRows = rows
			.sort(StateMachine.singleton._individualPlayerSeasonSortFunc);

		const add = (x: number, y: number) => x+y;
		const total = new PlayerStatsWrapper({
			player: this.player ? (this.player.fname+" "+this.player.lname) : "",
			team: "", //intentionally invalid teamname
			games_played: (rows.length && rows.map((row) => row.games).reduce(add)) ?? 0,
			powers: (rows.length && rows.map((row) => row.powers).reduce(add)) ?? 0,
			tens: (rows.length && rows.map((row) => row.tens).reduce(add)) ?? 0,
			negs: (rows.length && rows.map((row) => row.negs).reduce(add)) ?? 0
		});
		total.row.ppg = total.games > 0 ? (15*total.powers + 10*total.tens - 5*total.negs)/total.games : 0;
		sortedRows.push(total);

		return sortedRows;
	}

	get playerSeasons(): number[]
	{
		return APIModel.singleton.playerSeasonsPlayed(this.props.id);
	}

	/* remove players overview
	<Link to="/players" className="Players-Pane-Specific-Back-Button">
		<h4>{`<- ${str("BACK_TO_OVERIEW_BUTTON")}`}</h4>
	</Link>
	*/

	render() {
		return this.player
			? <VerticalBox spaced className="container-fluid">
				<VerticalBox className="Players-Pane-Header-Section">
					<h3>{this.player.fname + " " + this.player.lname}</h3>
					<HorizontalBox spaced>
						<strong>{str("TEAM_COLON")}</strong>
						{this.team && <Link to={`/teams/id=${this.team.id}`}>{this.team.name}</Link>}
					</HorizontalBox>
					<HorizontalBox spaced>
						<strong>{str("DIVISION_COLON")}</strong>
						<div title={this.prettyDivision}>{this.prettyDivision}</div>
					</HorizontalBox>
					<HorizontalBox spaced>
						<strong className={"Margin-Bottom"}>{str("TEAMMATES_COLON")}</strong>
						<HorizontalBox spaced className="Flex-Wrap">
							{this.team?.players
								.filter((player) => player.id?.toString() !== this.props.id.toString())
								.map((player, i) =>
								<Link to={`/players/id=${player.id}`} key={i}>{player.fname + " " + player.lname}</Link>)}
						</HorizontalBox>
					</HorizontalBox>
				</VerticalBox>
				<div/>
				<SeasonToggle overallEnabled
					selectedIndex={StateMachine.singleton.playersSeasonIndex}
					onToggleIndex={this.onToggleSeason}
					subsetOfSeasons={this.playerSeasons}/>
				{StateMachine.singleton.playersSeasonIndex > 0
					? <PlayerTable id={this.props.id}
								   season={this.season}
								   sortFunc={StateMachine.singleton._individualPlayerPaneSortFunc}
								   columnModes={StateMachine.singleton.individualPlayerPaneColumnModes}
								   sortFuncs={StateMachine.playerStatsColumnSortFuncs}
								   setSortFunc={StateMachine.singleton.setIndividualPlayerPaneSortFunc}/>
					: <div>
						<h5>{str("SEASON_SUMMARY_TITLE")}</h5>
						<table className="table">
							<TableHeader
								headers={this.seasonHeaders}
								columnModes={StateMachine.singleton.individualPlayerSeasonColumnModes}
								sortFuncs={StateMachine.playerStatsColumnSortFuncs}
								setSortFunc={StateMachine.singleton.setindividualPlayerSeasonSortFunc}/>
							<tbody>
								{this.seasonStats.map(this.renderSeasonRow)}
							</tbody>
						</table>
					</div>
				}
				</VerticalBox>
			: <LoadingPane/>;
	}

	readonly renderSeasonRow = (row: PlayerStatsWrapper, index: number) => {
		//render interior of cells manually to allow custom rendering
		const interior: React.ReactNode[] = index === this.seasonStats.length-1
		? [
			"Total",
			row.games,
			row.powers,
			row.tens,
			row.negs,
			row.ppg.toString().substring(0,5)
		].map((row, i) => <strong key={i}>{row}</strong>)
		: [
			row.season,
			row.games,
			row.powers,
			row.tens,
			row.negs,
			row.ppg
		];

		return <tr key={index} className="Player-Season-Table-Row">
			{this.seasonHeaders.map((header: string, i: number) =>
				<td className={`Table-Cell Table-Cell-${header}-Column Table-Cell-Row-${index.toString()}`} key={i}>
					{interior[i]}
				</td>
			)}
		</tr>;
	};

	readonly onToggleSeason = StateMachine.singleton.createTogglePlayersSeasonIndexCallback;
}

@observer
class PlayersOverview extends React.Component {
	componentDidMount() {
		APIModel.singleton.refreshTeams();
		APIModel.singleton.refreshPlayers();
		APIModel.singleton.refreshPlayerStatSummaries();
	}

	get headers(): string[]
	{
		return ["N", "Player", "Team", "Division", "G", "15", "10", "-5", "PPG"];
	}
	get filterText(): string
	{
		return StateMachine.singleton.playersFilterText;
	}
	get rows(): PlayerWrapper[]
	{
		return StateMachine.singleton.playersPaneRows;
	}

	get columnModes(): ObservableMap<number, number>
	{
		return StateMachine.singleton.playersPaneColumnModes;
	}

	render() {
		return <VerticalBox spaced className="container-fluid">
			<h2>{str("PLAYER_OVERVIEW_TITLE")}</h2>
			<VerticalBox className="Players-Pane-Header">
				<HorizontalBox spaced className="Players-Pane-Filter-Box col-lg-3 col-sm-5 col-xs-6">
					<label className="Players-Pane-Filter-Box-Label " htmlFor="statsFilter">
						{str("FILTER_BY_TEXT_LABEL")}
					</label>
					<input className="col-sm-6" type="text" id="statsFilter" defaultValue={this.filterText} onChange={this.onChangeFilter}/>
				</HorizontalBox>
			</VerticalBox>
			{APIModel.singleton.playersLoaded
				? this.rows.length
					?
					<table>
						<TableHeader
							headers={this.headers}
							columnModes={this.columnModes}
							sortFuncs={StateMachine.playerColumnSortFuncs}
							setSortFunc={StateMachine.singleton.setPlayersPaneSortFunc}
						/>
						<tbody>
							{this.rows.map(this.renderTableRow)}
						</tbody>
					</table>
					: <EmptyPane/>
				: <LoadingPane/>
			}
		</VerticalBox>;
	}

	readonly renderTableRow = (player: PlayerWrapper, index: number) => {
		const player_id = player.id;
		const team_id = player.team?.id;

		//render interior of cells manually to allow custom rendering
		const interior: React.ReactNode[] = [
			index+1,
			player_id != null
				? <Link to={`/players/id=${player_id}`}>{player.full_nameFilter(this.filterText)}</Link>
				: player.full_nameFilter(this.filterText),
			team_id != null
				? <Link to={`/teams/id=${team_id}`}>{player.teamnameFilter(this.filterText)}</Link>
				: player.teamnameFilter(this.filterText),
			player.prettyDivisionFilter(this.filterText),
			player.gamesFilter(this.filterText),
			player.powersFilter(this.filterText),
			player.tensFilter(this.filterText),
			player.negsFilter(this.filterText),
			player.ppgFilter(this.filterText)
		]
		return <tr key={index} className="Stats-Pane-Row">
			{this.headers.map((header, i) => 
				<td className={`Table-Cell Table-Cell-${header}-Column Table-Cell-Row-${index.toString()}`} key={i}>
					{interior[i]}
				</td>
			)}
		</tr>;
	};

	readonly onChangeFilter = StateMachine.singleton.onChangePlayerFilterText;
}