import * as React from "react";
import {observer} from "mobx-react";
import {APICaller} from "util/APICaller";
import {APIModel} from "util/APIModel";
import {StateMachine} from "util/StateMachine";
import {TextHelper as txt, str} from "util/TextHelper";
import {SeasonHelper} from "util/SeasonHelper";
import {VerticalBox} from "ui/VerticalBox";
import {HorizontalBox} from "ui/HorizontalBox";
import {EmptyPane} from "ui/EmptyPane";
import {ToggleButtons} from "ui/ToggleButtons";
import {ScheduleTable} from "ui/ScheduleTable";
import {LoadingPane} from "ui/LoadingPane";
import {TeamScheduleRow} from "dto/ScheduleRow";

@observer
export class SchedulePane extends React.Component {
	async componentDidMount()
	{
		await APIModel.singleton.refreshTeams();
		APIModel.singleton.refreshSchedules();
	}

	get filterText(): string
	{
		return StateMachine.singleton.scheduleFilterText;
	}

	get rows()
	{
		return StateMachine.singleton.scheduleRows;
	}
	get finishedRows()
	{
		return this.rows.filter((row) => row.result != "");
	}
	get unfinishedRows()
	{
		return this.rows.filter((row) => row.result === "");
	}

	render() {
		return (
			<VerticalBox className="Schedule-Pane container-fluid" spaced>
				<h2 className="Schedule-Pane-Title">{txt.getResource("SCHEDULE_TITLE")}</h2>
				{SeasonHelper.currentWeek != null && SeasonHelper.currentSeason != null
					&& SeasonHelper.currentWeek > 0 && SeasonHelper.currentWeek <= SeasonHelper.seasonLength[SeasonHelper.currentSeason-1]
					&& <div className="Schedule-Pane-Header row">
						<div className="Schedule-Pane-Filter-Box col-lg-3 col-md-4 col-sm-5 col-xs-6">
							<label className="Schedule-Pane-Filter-Box-Label" htmlFor="scheduleFilter">{str("FILTER_BY_TEXT_LABEL")}</label>
							<input className="col-sm-6" type="text" id="scheduleFilter" defaultValue={this.filterText} onChange={this.onChangeFilter}/>
						</div>
						<HorizontalBox spaced className="col-lg-3 col-md-3 col-sm-6 col-xs-8">
							<div>{str("WEEK_COLON")}</div>
							<ToggleButtons
								labels={[str("CURRENT_WEEK_LABEL"), str("ALL_LABEL")]}
								selectedIndex={StateMachine.singleton.scheduleWeekToggle}
								onToggleIndex={StateMachine.singleton.createToggleScheduleWeekCallback}/>
						</HorizontalBox>
						{SeasonHelper.currentSeason == null
							? <LoadingPane/>
							: <HorizontalBox spaced className="col-lg-5 col-md-6 col-sm-7 col-xs-10">
								<div>{str("DIVISION_COLON")}</div>
								<ToggleButtons
									//manually set labels because of the lettering scheme
									labels={[str("ALL_LABEL"), 1, "2a", "2b", "3a", "3b"]}
										//...[...new Array(SeasonHelper.seasonDivisions[SeasonHelper.currentSeason!-1])].map((_, i) => `${i+1}`)]}
									selectedIndex={StateMachine.singleton.scheduleDivisionToggle}
									onToggleIndex={StateMachine.singleton.createToggleScheduleToggleCallback}/>
							</HorizontalBox>
						}
					</div>
				}
				{SeasonHelper.currentWeek != null && SeasonHelper.currentSeason != null
					?  SeasonHelper.currentWeek > SeasonHelper.seasonLength[SeasonHelper.currentSeason-1]
						? str("SEASON_OVER_MESSAGE")
						: SeasonHelper.currentWeek === 0
							? str("SEASON_NOT_STARTED_MESSAGE")
							: APIModel.singleton.schedulesLoaded
								? <React.Fragment>
									<h5>Played games</h5>
									<ScheduleTable
										columnModes={StateMachine.singleton.scheduleColumnModes}
										sortFuncs={StateMachine.scheduleColumnSortFuncs}
										setSortFunc={StateMachine.singleton.setScheduleSortFunc}
										sortFunc={StateMachine.singleton._scheduleSortFunc}
										rows={this.finishedRows}
										hideTitle/>
									<h5>Unplayed games</h5>
									<ScheduleTable
										columnModes={StateMachine.singleton.scheduleColumnModes}
										sortFuncs={StateMachine.scheduleColumnSortFuncs}
										setSortFunc={StateMachine.singleton.setScheduleSortFunc}
										sortFunc={StateMachine.singleton._scheduleSortFunc}
										rows={this.unfinishedRows}
										hideTitle/>
								</React.Fragment>
								: <LoadingPane/>
					: <LoadingPane/>
				}
			</VerticalBox>
		);
	}

	readonly onChangeFilter = StateMachine.singleton.onChangeScheduleFilterText;
}