import * as React from "react";

export interface VerticalBoxProps {
	className?: string;
	spaced?: boolean;
}

export class VerticalBox extends React.Component<VerticalBoxProps> {
	render() {
		return <div className={`Vertical-Box ${this.props.className ?? ""} ${this.props.spaced ? "Vertical-Box-Spaced" : ""}`}>
			{this.props.children}
		</div>;
	}
}