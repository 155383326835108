import firebase from "firebase";
import "firebase/storage";
import {action, makeAutoObservable} from "mobx";
import {TextHelper} from "util/TextHelper";
import {APICaller} from "util/APICaller";

export class SeasonHelper {
	_currentSeason?: number;
	_currentWeek?: number;

	constructor()
	{
		makeAutoObservable(this);
		this.refreshMeta();
	}

	private static _singleton?: SeasonHelper;
	static get singleton(): SeasonHelper {
		if (!SeasonHelper._singleton)
		{
			SeasonHelper._singleton = new SeasonHelper();
		}
		return SeasonHelper._singleton;
	}

	static get currentSeason(): number
	{
		return SeasonHelper.singleton._currentSeason ?? 0;
	}
	static get currentWeek(): number
	{
		return SeasonHelper.singleton._currentWeek ?? 0;
	}

	static get initialized(): boolean
	{
		return SeasonHelper.currentSeason != null && SeasonHelper.currentWeek != null;
	}

	refreshMeta(): void
	{
		if (this._currentSeason == null)
		{
			APICaller.getCurrentSeason().then(action((season) => this._currentSeason = season));
		}
		if (this._currentWeek == null)
		{
			APICaller.getCurrentWeek().then(action((week) => this._currentWeek = week));
		}
	}

	static registrationOpen: boolean = false;

	static seasonLength: number[] = [
		9, 9
	];
	static seasonDivisions: number[] = [
		7, 5
	];
	static parallelDivisions: number[][] = [
		[], //not applicable to season 1
		[1, 2, 2, 2, 2, 2, 2, 2, 2, 2]
	];

	static downloadPacket(season: number, week: number, parsed: boolean = false, docxOrPDF: "DOCX" | "PDF" = "PDF"): void {
		const packetNames = TextHelper.getPacketNames(season, parsed, docxOrPDF);
		const seasonIndex = season - 1;
		const weekIndex = week - 1;

		if (packetNames[weekIndex]?.length) {
			const storage = firebase.storage();
			storage.ref(packetNames[weekIndex]).getDownloadURL().then((url) => {
				const a = document.createElement("a");
				//@ts-ignore
				a.style = "display: none";
				a.href = url;
				document.body.appendChild(a);
				a.click();
			}).catch((e) => console.log("getPacket:", e)) as Promise<string | undefined>;
		}
	}

	static getSeasonWeeks(season: number, reverse: boolean = false): number[]
	{
		const weeks: number[] = [];
		const size = season === SeasonHelper.currentSeason ? SeasonHelper.currentWeek ?? 0 : SeasonHelper.seasonLength[season-1];
		if (reverse) {
			for (let i = size; i >=1; i--) {
				weeks.push(i);
			}
		} else {
			for (let i = 1; i <= size; i++) {
				weeks.push(i);
			}
		}
		return weeks;
	}

	static getCurrentSeasonWeeks(reverse: boolean = false): number[]
	{
		return SeasonHelper.getSeasonWeeks(SeasonHelper.currentSeason ?? 0, reverse);
	}

	static getSeasons(reverse: boolean = false): number[]
	{
		const seasons: number [] = [];
		if (reverse) {
			for (let i = SeasonHelper.currentSeason ?? 0; i > 0; i--)
			{
				seasons.push(i);
			}
		} else {
			for (let i = 1; i <= (SeasonHelper.currentSeason ?? 0); i++)
			{
				seasons.push(i);
			}
		}
		return seasons;
	}
}