import * as React from "react";
import {observer} from "mobx-react";
import {HorizontalBox} from "ui/HorizontalBox";

export interface ToggleButtonsProps {
	labels: React.ReactNode[];
	selectedIndex: number;
	onToggleIndex: (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => void;
	className?: string;
}

@observer
export class ToggleButtons extends React.Component<ToggleButtonsProps> {
	render() {
		const {labels, selectedIndex, onToggleIndex, className}: ToggleButtonsProps = this.props;

		return <HorizontalBox className={`${className} btn-group Toggle-Buttons`}>
			{labels.map((label, index) => 
				<button type="button" className={`btn Toggle-Button ${selectedIndex === index ? "Active-Toggle-Button" : ""}`} onClick={onToggleIndex(index)} key={index}>
					{label}
				</button>
			)}
		</HorizontalBox>;
	}
}