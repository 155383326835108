import * as React from "react";
import { AnnouncementsPane } from "./AnnouncementsPane";
import {Link} from "react-router-dom";
import {str} from "util/TextHelper";
import {SeasonHelper} from "util/SeasonHelper";
import {VerticalBox} from "ui/VerticalBox";

export class HomePane extends React.Component {
    render() {
        return(
            <div className="Home-Pane container-fluid">
                <h1>{str("HOME_WELCOME")}</h1>
                <div className='Home-Pane-Main-Content row'>
                    <VerticalBox className='col-lg-8 col-md-6'spaced>
                        <p className="Home-Pane-Main-Content-Text " dangerouslySetInnerHTML={{__html: this.mainContentText}}/>
                        {SeasonHelper.registrationOpen && <Link className="Big-Registration-Button" to='/register'>
                            {str("HOME_REGISTER_NOW")}
                        </Link>}
                    </VerticalBox>
                    <AnnouncementsPane />
                </div>
            </div> 
        )
    }

    get mainContentText(): string
    {
        const paragraphs = [
            str("HOME_CONTENT_P1"),
            str("HOME_CONTENT_P2.1"),
            // str("HOME_CONTENT_P3")
        ];
        return paragraphs.join("\n\n");
    }
}