import "./App.css";
import * as React from "react";
import {Switch, Route, useLocation} from "react-router-dom";
import {str} from "util/TextHelper";
import {SeasonHelper} from "util/SeasonHelper";
import {MainToolbar} from "ui/MainToolbar";
import {FAQPane} from "ui/FAQPane";
import {LoginPane} from "ui/LoginPane";
import {PlayersPane} from "ui/PlayersPane";
import {QuestionsPane} from "ui/QuestionsPane";
import {SchedulePane} from "ui/SchedulePane";
import {ScorerPane} from "ui/ScorerPane";
import {StatsPane} from "ui/StatsPane";
import {TeamsPane} from "ui/TeamsPane";
import {UserPane} from "ui/UserPane";
import {RegisterPane} from "ui/RegisterPane";
import {HomePane} from "ui/HomePane";
import {ConfirmPane} from "ui/ConfirmPane";
import {ConversionPane} from "ui/ConversionPane";
import {VerticalBox} from "ui/VerticalBox"

export interface LocationProps {
  path: string;
  params?: string;
}

export function App() {
  const location = useLocation();
  const props: LocationProps = {path: location.pathname, params: location.search};

  const renderContent = (): React.ReactNode => {
    return <Switch>
      <Route path="/schedule">
        <SchedulePane/>
      </Route>
      <Route path="/stats">
        <StatsPane/>
      </Route>
      <Route path="/scorer">
      	<ScorerPane/>
      </Route>
      <Route path="/questions">
       <QuestionsPane/>
      </Route>
      <Route path="/user">
        <UserPane/>
      </Route>
      <Route path="/register">
        <RegisterPane/>
      </Route>
      <Route path="/faq">
        <FAQPane/>
      </Route>
      <Route path="/login">
        <LoginPane/>
      </Route>
      <Route path = "/conversion/:season?/:week?">
        <ConversionPane {...props}/>
      </Route>
      <Route path="/teams/:id?">
        <TeamsPane {...props}/>
      </Route>
      <Route path="/players/:id?">
        <PlayersPane {...props}/>
      </Route>
      <Route path="/confirm/:user_id/:player_id/:is_captain">
        <ConfirmPane {...props}/>
      </Route>
      <Route path="/">
        <HomePane/>
      </Route>
    </Switch>;
  }

  return <div className="App">
      <MainToolbar {...props}/>
      <VerticalBox className="Main-Content-Pane">
        {renderContent()}
        <div className="Spacer"/>
      </VerticalBox>
      <footer className="Main-Footer" dangerouslySetInnerHTML={{__html: str("FOOTER_TEXT")}}>
      </footer>
    </div>;
}