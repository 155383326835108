import json from "resources/text.json";
import questions from "resources/sample_questions.json";
import packets from "resources/packet_names.json";
import {SeasonHelper} from "util/SeasonHelper";

export class TextHelper {
	static getResource(key: string): string
	{
		// return "!!retrieved!!";
		return key in json ? (json as {[key: string]: string})[key] as string : `Resource '${key}' not found`;
	}

	static getQuestionText(key: string): string
	{
		const textKey = `${key}_TEXT`;
		return textKey in questions ? (questions as {[key: string]: string})[textKey] as string : `Text for sample question ${key} not found`;
	}

	static getQuestionAnswerline(key: string): string
	{
		const answerKey = `${key}_ANSWERLINE`;
		return answerKey in questions ? (questions as {[key: string]: string})[answerKey] as string : `Answerline for sample tossup ${key} not found`;
	}

	static getPacketNames(season: number, parsed: boolean = false, docxOrPDF: "DOCX" | "PDF" = "PDF"): string[]
	{
		const names = [];
		for (let i = 0; i < SeasonHelper.seasonLength[season - 1]; i++)
		{
			const packetKey = `SEASON_${season}_PACKET_${i+1}_${parsed ? "PARSED" : docxOrPDF}`;
			names.push(packetKey in packets
				? (packets as {[key: string]: string})[packetKey] as string
				: "");
		}
		return names;
	}

	private static _legendText: Map<string, string | undefined> = new Map([
		["N", undefined],
		["Player", undefined],
		["Team", undefined],
		["Opponent", undefined],
		["Result", str("RESULT_LEGEND")],
		["PF", str("PF_LEGEND")],
		["PA", str("PA_LEGEND")],
		["G", str("G_LEGEND")],
		["TUH", str("TUH_LEGEND")],
		["W", str("W_LEGEND")],
		["L", str("L_LEGEND")],
		["T", str("T_LEGEND")],		
		["15", str("15_LEGEND")],
		["10", str("10_LEGEND")],
		["-5", str("NEG5_LEGEND")],
		["PPG", str("PPG_LEGEND")],
		["PPB", str("PPB_LEGEND")]
	]);
	static get legendText(): Map<string, string | undefined>
	{
		return this._legendText;
	}

	private static _headerTooltips: Map<string, string> = new Map([
		["Player", str("PLAYER_TOOLTIP")],
		["Players", str("PLAYERS_TOOLTIP")],
		["Team", str("TEAM_TOOLTIP")],
		["Opponent", str("OPPONENT_TOOLTIP")],
		["Result", str("RESULT_TOOLTIP")],
		["PF", str("PF_TOOLTIP")],
		["PA", str("PA_TOOLTIP")],
		["G", str("G_TOOLTIP")],
		["TUH", str("TUH_TOOLTIP")],
		["W", str("W_TOOLTIP")],
		["L", str("L_TOOLTIP")],
		["T", str("T_TOOLTIP")],
		["15", str("15_TOOLTIP")],
		["10", str("10_TOOLTIP")],
		["-5", str("NEG5_TOOLTIP")],
		["PPG", str("PPG_TOOLTIP")],
		["PPB", str("PPB_TOOLTIP")],
		["Division", str("DIVISION_TOOLTIP")],
		["Week", str("WEEK_TOOLTIP")],
		["Home", str("HOME_TOOLTIP")],
		["Away", str("AWAY_TOOLTIP")],
		["TU", str("TU_TOOLTIP")],
		["Answer", str("ANSWER_TOOLTIP")],
		["%15", str("%15_TOOLTIP")],
		["%(15+10)", str("%(15+10)_TOOLTIP")],
		["%(-5)", str("%(-5)_TOOLTIP")],
		["%(Part 1)", str("%(Part 1)_TOOLTIP")],
		["%(Part 2)", str("%(Part 2)_TOOLTIP")],
		["%(Part 3)", str("%(Part 3)_TOOLTIP")],
		["Average PPB", str("AVERAGE_PPB_TOOLTIP")]
	]);
	static get headerTooltips(): Map<string, string>
	{
		return this._headerTooltips;
	}

	private static _leagueNames: Map<string, string> = new Map([
		["super-competitive", "Super Competitive"]
	]);
	static get leagueNames(): Map<string, string>
	{
		return this._leagueNames;
	}
}

export function str(key: string): string
{
	return TextHelper.getResource(key);
}