import {makeAutoObservable, ObservableMap, runInAction, when} from "mobx";
import {APICaller} from "util/APICaller";
import {TeamScheduleRowWrapper} from "util/TeamScheduleRowWrapper";
import {SeasonHelper} from "util/SeasonHelper";
import {TeamStatsInfo, PlayerStatsInfo} from "dto/StatsInfo";
import {TeamInfo} from "dto/TeamInfo";
import {PlayerInfo} from "dto/PlayerInfo";
import {GameInfo, GameLocation, ScheduleRow, TeamScheduleRow} from "dto/ScheduleRow";
import {TossupConversionInfo, BonusConversionInfo} from "dto/ConversionInfo";

export class APIModel {
	constructor() {
		makeAutoObservable(this);
	}

	private static _singleton?: APIModel;
	static get singleton(): APIModel {
		if (!APIModel._singleton)
		{
			APIModel._singleton = new APIModel();
		}
		return APIModel._singleton;
	}

	//stats page
	//player id -> season -> summary of season
	private readonly _playerSummariesById: ObservableMap<number, Map<number, PlayerStatsInfo>> = new ObservableMap();
	get playerSummariesById(): ObservableMap<number, Map<number, PlayerStatsInfo>> {return this._playerSummariesById}
	//player id -> season -> stats
	private readonly _playerStatsById: ObservableMap<number, Map<number, PlayerStatsInfo[]>> = new ObservableMap();
	get playerStatsById(): ObservableMap<number, Map<number, PlayerStatsInfo[]>> {return this._playerStatsById}

	async refreshPlayerStats(player: number): Promise<void>
	{
		await when(() => SeasonHelper.initialized);
		const stats = await APICaller.getPlayerStats(player);
		const statsBySeason: Map<number, PlayerStatsInfo[]> = new Map();
		stats.forEach((stat) => {
			const season = stat.season ?? 0;
			const seasonStats = statsBySeason.get(season);
			if (seasonStats)
			{
				seasonStats.push(stat);
			}
			else
			{
				statsBySeason.set(season, [stat]);
			}
		});
		if (statsBySeason.get(0))
			return; //something's wrong
		runInAction(() => {
			APIModel.singleton.playerStatsById.set(player, statsBySeason);
		});
	}

	allPlayerStatSummaries(season: number = SeasonHelper.currentSeason): PlayerStatsInfo[]
	{
		const stats: PlayerStatsInfo[] = [];
		this.playerSummariesById.forEach((seasonMap) => stats.push(seasonMap.get(season)!));
		return stats;
	}

	private _playerStatSummariesLoaded: boolean = false;
	get playerStatSummariesLoaded(): boolean {return this._playerStatSummariesLoaded}

	async refreshPlayerStatSummaries(): Promise<void>
	{
		let batch: PlayerStatsInfo[];
		//player id -> season -> summary of season
		const map: Map<number, Map<number, PlayerStatsInfo>> = new Map();
		for (let i = 1; i <= SeasonHelper.currentSeason; i++)
		{
			batch = await APICaller.getPlayerStatSummaries(i);
			batch?.forEach((row) => {
				if (row.player_id)
				{
					const summaries = map.get(row.player_id);
					if (summaries)
					{
						summaries.set(i, row);
					} else {
						map.set(row.player_id, new Map([[i, row]]));
					}
				}
			});
		}

		runInAction(() => {
			this._playerSummariesById.replace(map);
			this._playerStatSummariesLoaded = true;
		});
	}

	async refreshPlayerCareerStats(player: number): Promise<void>
	{
		const summaries = await APICaller.getPlayerCareerStats(player);
		//season -> summary of season
		const map: Map<number, PlayerStatsInfo> = new Map();
		summaries.forEach(summary => {
			if (summary.season)
			{
				map.set(summary.season, summary);
			}
		});

		runInAction(() => {
			this._playerSummariesById.merge(new Map([[player, map]]));
		})
	}

	//player id -> seasons played (will be subset of team's season's played)
	private readonly _playerSeasonsPlayed: ObservableMap<number, number[]> = new ObservableMap();

	playerSeasonsPlayed(player: number): number[]
	{
		const seasons = this._playerSeasonsPlayed.get(player);
		if (seasons == null)
		{
			//attempt to lazily fill in cache
			const summary = this._playerSummariesById.get(player);
			if (summary)
			{
				const summarySeasons: number[] = [];
				//@ts-ignore
				for (const [season, _] of summary)
				{
					summarySeasons.push(season);
				}
				this._playerSeasonsPlayed.set(player, summarySeasons);
				return summarySeasons;
			}
		}
		else
		{
			return seasons;
		}
		return [];
	}

	statsSummaryForPlayer(player: number): PlayerStatsInfo[]
	{
		const seasons = this.playerSeasonsPlayed(player);
		const summariesBySeason = this.playerSummariesById.get(player);
		return seasons
			.map((season) => summariesBySeason?.get(season))
			.filter((row) => row != null) as PlayerStatsInfo[];
	}

	//team id -> season -> summary of season
	private readonly _teamSummariesById: ObservableMap<number, Map<number, TeamStatsInfo>> = new ObservableMap();
	get teamSummariesById(): ObservableMap<number, Map<number, TeamStatsInfo>> {return this._teamSummariesById}
	//team id -> season -> stats
	private readonly _teamStatsById: ObservableMap<number, Map<number, TeamStatsInfo[]>> = new ObservableMap();
	get teamStatsById(): ObservableMap<number, Map<number, TeamStatsInfo[]>> {return this._teamStatsById}

	async refreshTeamStats(team: number): Promise<void>
	{
		await when(() => SeasonHelper.initialized);
		const stats = await APICaller.getTeamStats(team);
		const statsBySeason: Map<number, TeamStatsInfo[]> = new Map();
		stats.forEach((stat) => {
			const season = stat.season ?? 0;
			const seasonStats = statsBySeason.get(season);
			if (seasonStats)
			{
				seasonStats.push(stat);
			}
			else
			{
				statsBySeason.set(season, [stat]);
			}
		});
		if (statsBySeason.get(0))
			return; //something's wrong
		runInAction(() => {
			APIModel.singleton.teamStatsById.set(team, statsBySeason);
		});
	}

	async refreshTeamPlayerStats(team: number): Promise<void>
	{
		await when(() => SeasonHelper.initialized);
		//@ts-ignore
		const teamInfo = APIModel.singleton.teamsById.get(""+team);
		if (teamInfo?.seasons)
		{
			const playerNameToId: Map<string, number> = new Map(teamInfo.players.map((player) =>
				[player.fname+" "+player.lname, player.id!]));
			for (const season of teamInfo.seasons)
			{
				const stats = await APICaller.getTeamPlayerStats(team, season);
				stats.forEach((stat) => {
					const playerId = playerNameToId.get(stat.player);
					if (playerId)
					{
						runInAction(() => {
							//@ts-ignore
							const statMap = APIModel.singleton.playerSummariesById.get(""+playerId);
							if (statMap)
							{
								statMap.set(season, stat);
							} else {
								APIModel.singleton.playerSummariesById.set(playerId, new Map([[season, stat]]));
							}
						});
					}
				});
			}

		}
	}

	teamStatSummaries(season: number = SeasonHelper.currentSeason): TeamStatsInfo[]
	{
		const stats: TeamStatsInfo[] = [];
		this.teamSummariesById.forEach((seasonMap) => {
			if (seasonMap.has(season))
			{
				stats.push(seasonMap.get(season)!);
			}});
		return stats;
	}

	private _teamStatSummariesLoaded: boolean = false;
	get teamStatSummariesLoaded(): boolean {return this._teamStatSummariesLoaded}

	async refreshTeamStatSummaries(): Promise<void>
	{
		let batch: TeamStatsInfo[];
		const map: Map<number, Map<number, TeamStatsInfo>> = new Map();
		for (let season = 1; season <= SeasonHelper.currentSeason; season++)
		{
			batch = await APICaller.getTeamStatSummaries(season);
			batch?.forEach((row) => {
				if (row.team_id)
				{
					const summaries = map.get(row.team_id);
					if (summaries)
					{
						summaries.set(season, row);
					} else {
						map.set(row.team_id, new Map([[season, row]]));
					}
				}
			})
		}
		runInAction(() => {
			// this._teamStatSummaries = batch; //current season
			this._teamSummariesById.replace(map);
			this._teamStatSummariesLoaded = true;
		});
	}

	//conversion stats
	private readonly _tossupConversionByWeekBySeason: ObservableMap<number, Map<number, TossupConversionInfo[]>> = new ObservableMap();
	get tossupConversionByWeekBySeason(): ObservableMap<number, Map<number, TossupConversionInfo[]>> {return this._tossupConversionByWeekBySeason}

	private readonly _bonusConversionByWeekBySeason: ObservableMap<number, Map<number, BonusConversionInfo[]>> = new ObservableMap();
	get bonusConversionByWeekBySeason(): ObservableMap<number, Map<number, BonusConversionInfo[]>> {return this._bonusConversionByWeekBySeason}

	async refreshConversion(): Promise<void>
	{
		const tossupSeasonMap: ObservableMap<number, Map<number, TossupConversionInfo[]>> = new ObservableMap();
		const bonusSeasonMap: ObservableMap<number, Map<number, BonusConversionInfo[]>> = new ObservableMap();
		for (let season = 1; season <= (SeasonHelper.currentSeason ?? 0); season++)
		{
			const limit = season === SeasonHelper.currentSeason
				? SeasonHelper.currentWeek
					? SeasonHelper.currentWeek - 1
					: 0
				: SeasonHelper.seasonLength[season-1];

			const tossupMap: Map<number, TossupConversionInfo[]> = new Map();
			const bonusMap: Map<number, BonusConversionInfo[]> = new Map();

			for (let week = 1; week <= limit; week++)
			{
				const tossupBatch = await APICaller.getTossupConversionStats(week, season);
				tossupMap.set(week, tossupBatch);
				const bonusBatch = await APICaller.getBonusConversionStats(week, season);
				bonusMap.set(week, bonusBatch);
			}
			tossupSeasonMap.set(season, tossupMap);
			bonusSeasonMap.set(season, bonusMap);
		}

		runInAction(() => {
			this._tossupConversionByWeekBySeason.replace(tossupSeasonMap);
			this._bonusConversionByWeekBySeason.replace(bonusSeasonMap);
		});
	}

	//schedule page
	private readonly _teamIdsToSchedule: ObservableMap<number, TeamScheduleRow[]> = new ObservableMap();
	get teamIdsToSchedule(): ObservableMap<number, TeamScheduleRow[]> {return this._teamIdsToSchedule}
	private readonly _weekToSchedule: ObservableMap<number, TeamScheduleRow[]> = new ObservableMap();
	get weekToSchedule(): ObservableMap<number, TeamScheduleRow[]> {return this._weekToSchedule}

	private _allScheduleRows: TeamScheduleRow[] = [];
	get allScheduleRows(): TeamScheduleRow[] {return this._allScheduleRows}

	private _schedulesLoaded: boolean = false;
	get schedulesLoaded(): boolean {return this._schedulesLoaded}

	async refreshSchedules(): Promise<void>
	{
		const games = await APICaller.getGames();

		const scheduleMap: Map<number, TeamScheduleRow[]> = new Map();
		const weekMap: Map<number, TeamScheduleRow[]> = new Map();
		const batch: TeamScheduleRow[] = [];
		
		Object.keys(games).forEach((game_id) => {
			const game = games[game_id];

			const homeRow = {
				game_id,
				week: game.week,
				location: GameLocation.HOME,
				opponent: game.away,
				result: game.result
			};
			const homeTeamRow = {
				team_id: game.home.id,
				teamname: game.home.name,
				row: homeRow
			};
			const awayRow = {
				game_id,
				week: game.week,
				location: GameLocation.AWAY,
				opponent: game.home,
				result: TeamScheduleRowWrapper.invertResult(game.result)
			}
			const awayTeamRow = {
				team_id: game.away.id,
				teamname: game.away.name,
				row: awayRow
			}

			const homeTeamRows = scheduleMap.get(game.home.id);
			if (homeTeamRows)
			{
				homeTeamRows.push(homeTeamRow);
			} else {
				scheduleMap.set(game.home.id, [homeTeamRow]);
			}

			const awayTeamRows = scheduleMap.get(game.away.id);
			if (awayTeamRows)
			{
				awayTeamRows.push(awayTeamRow);
			} else {
				scheduleMap.set(game.away.id, [awayTeamRow]);
			}			

			const weekRows = weekMap.get(game.week);
			if (weekRows)
			{
				weekRows.push(homeTeamRow);

			} else {
				weekMap.set(game.week, [homeTeamRow]);
			}
			batch.push(homeTeamRow);
		});

		runInAction(() => {
			this._teamIdsToSchedule.replace(scheduleMap);
			this._weekToSchedule.replace(weekMap);
			this._allScheduleRows = batch;
			this._schedulesLoaded = true;
		});
	}

	async refreshSchedule(team_id: number, season?: number): Promise<void>
	{
		const response = await APICaller.getSchedule(team_id, season);

		const schedule = response?.map((row) => {
			return {
				team_id: team_id,
				row: row
			} as TeamScheduleRow;
		}) ?? [];

		runInAction(() => {
			this._teamIdsToSchedule.set(team_id, schedule);
		});
	}

	//teams
	private readonly _teamIdsToNames: ObservableMap<number, string> = new ObservableMap();
	get teamIdsToNames(): ObservableMap<number, string> {return this._teamIdsToNames}

	private readonly _teamsById: ObservableMap<number, TeamInfo> = new ObservableMap();
	get teamsById(): ObservableMap<number, TeamInfo> {return this._teamsById}
	private readonly _teamsByPlayerId: ObservableMap<number, TeamInfo> = new ObservableMap();
	get teamsByPlayerId(): ObservableMap<number, TeamInfo> {return this._teamsByPlayerId}

	_teams: ObservableMap<number, TeamInfo[]> = new ObservableMap();
	get allTeams(): TeamInfo[]
	{
		const teamNames: Set<string> = new Set();
		const teams: TeamInfo[] = [];
		for (let i = 0; i < SeasonHelper.currentSeason; i++) this._teams.get(i+1)?.forEach(team => {
			if (!teamNames.has(team.name))
			{
				teamNames.add(team.name);
				teams.push(team);
			}
		});
		return teams;
	}
	//implicitly teams for current season
	get teams(): TeamInfo[]
	{
		return this.allTeams.filter((team) => team.seasons && SeasonHelper.initialized && team.seasons.indexOf(SeasonHelper.currentSeason) >= 0);
	}

	private _teamsLoaded: boolean = false;
	get teamsLoaded(): boolean {return this._teamsLoaded}

	async refreshTeams(): Promise<void>
	{
		const batch: TeamInfo[] = await APICaller.getTeams();
		const teamsBySeason: TeamInfo[][] = [];
		//filter on admin_approval
		const filtered = batch.filter(team => team.admin_approval === "True");
		filtered.forEach(team =>
			team.seasons?.forEach(season => {
				if (!(season-1 in teamsBySeason)) teamsBySeason[season-1] = [];
				teamsBySeason[season-1]?.push(team);
			}));
		const teamsMap = new Map(teamsBySeason
			.map((teams, i) => [i+1, teams]));

		runInAction(() => {
			this._teams.replace(teamsMap);
			this.teamIdsToNames.replace(filtered.map((team) => [team.id, team.name]));
			this.teamsById.replace(filtered.map(teams => [teams.id, teams]));
			//map each player of each team to the team and then flatten the array
			this.teamsByPlayerId.replace(filtered.map(team => team.players?.map(player => [player.id, team]) ?? []).flat());
			this._teamsLoaded = true;
		});
	}

	async refreshTeamsOfSeason(season: number = SeasonHelper.currentSeason): Promise<void>
	{
		const batch: TeamInfo[] = await APICaller.getTeamsBySeason(season);
		//filter on admin_approval
		const filtered = batch.filter(team => team.admin_approval === "True");
		const teamsMap = new Map([[season, filtered]]);

		runInAction(() => {
			this._teams.replace(teamsMap);
			this.teamIdsToNames.merge(filtered.map((team) => [team.id, team.name]));
			this.teamsById.merge(filtered.map(teams => [teams.id, teams]));
			//map each player of each team to the team and then flatten the array
			this.teamsByPlayerId.merge(filtered.map(team => team.players?.map(player => [player.id, team]) ?? []).flat());
			this._teamsLoaded = true;
		});
	}

	//players
	private readonly _playersById: ObservableMap<number, PlayerInfo> = new ObservableMap();
	get playersById(): ObservableMap<number, PlayerInfo> {return this._playersById}

	_players: PlayerInfo[] = [];
	get players(): PlayerInfo[]
	{
		return this._players;
	}

	private _playersLoaded: boolean = false;
	get playersLoaded(): boolean {return this._playersLoaded}

	async refreshPlayers(): Promise<void>
	{
		const players: PlayerInfo[] = await APICaller.getPlayers();

		runInAction(() => {
			this._players = players;
			this.playersById.replace(players.map(player => [player.id, player]));
			this._playersLoaded = true;
		});
	}
}