import * as React from "react";
import {action, ObservableMap} from "mobx";
import {observer} from "mobx-react";
import {TextHelper} from "util/TextHelper";

export interface TableHeaderProps<T> {
	season?: number;
	headers: string[];
	columnModes: ObservableMap<number, number>;
	sortFuncs: Map<string, sortF<T>>;
	setSortFunc: (sortF: sortF<T>) => void;
}

type sortF<T> = (r1: T, r2: T) => number;

@observer
export class TableHeader<T> extends React.Component<TableHeaderProps<T>> {
	get columnModes(): ObservableMap<number, number>
	{
		return this.props.columnModes;
	}

	render() {
		const renderCell = (text: string, index: number) =>
			this.props.sortFuncs.get(this.props.headers[index]) == null
				//hard code "Division" in
				&& !(this.props.season != null && this.props.headers[index] == "Division")
			? <th scope="col" title={TextHelper.headerTooltips.get(text)} key={index}>{text}</th>
			:
			<th title={TextHelper.headerTooltips.get(text)} scope="col" className="Table-Header-Cell" onClick={this.sortByColumn(index)} key={index}>
				{text}
				<sup className={this.columnModes.get(index) === 1
					? "Teams-Pane-Header-Active-Sort-Arrow"
					: "Teams-Pane-Header-Inactive-Sort-Arrow"}>^</sup>
				<sub className={this.columnModes.get(index) === 2
					? "Teams-Pane-Header-Active-Sort-Arrow"
					: "Teams-Pane-Header-Inactive-Sort-Arrow"}>v</sub>
			</th>;

		return <thead><tr>
			{this.props.headers.map(renderCell)}
		</tr></thead>;
	}

	sortByColumn(index: number): () => void
	{
		return action(() => {
			//hard code division sort to allow passing in the season
			const sortFunc: sortF<T> = this.props.headers[index] === "Division" && this.props.season != null
				//@ts-ignore
				? (r1, r2) => r1.division(this.props.season).localeCompare(r2.division(this.props.season))
				: this.props.sortFuncs.get(this.props.headers[index]) ?? (() => 1);
			const currentMode = this.columnModes.get(index);
			switch (currentMode)
			{
				case undefined:
				case 0: //should be 0 if sort is reset or a different column is being sorted by
					this.props.setSortFunc(sortFunc);
					break;
				case 1: //reverse order of primary sort
					this.props.setSortFunc((row1: T, row2: T) => sortFunc(row2, row1));
					break;
				case 2: //third click resets sort
				default:
					this.props.setSortFunc(() => 1);
					break;
			}
			for (let i = 0; i < this.props.headers.length; i++)
			{
				if (i === index)
				{
					this.columnModes.set(i, ((currentMode ?? 0) + 1) % 3);
				} else {
					this.columnModes.set(i, 0);
				}
			}
		});
	}
}